import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
// import "../../../CSS/AccountAccess.css";
import { observer } from "mobx-react";
import { useFormik } from "formik";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import * as Yup from "yup";
import FormikController from "../../../../components/FormikController/FormikController";
import { Autocomplete, DialogActions, TextField } from "@mui/material";
import ClientStore from "../../../../Store/ClientStore";
import CareGiverStore from "../../../../Store/CareGiverStore";
import { CareGiverURL } from "../../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../../Store/AgencyStore";
import { useParams } from "react-router-dom";
import GlobelStore from "../../../../Store/GlobelStore";
// import { LocalizationProvider, TimePicker } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AddClockInOut = ({ onConfirm, clickHTML, type, selectedIds }) => {
  const [open, setOpen] = useState(false);
  const { employeeId } = useParams();

  const [status, setStatus] = useState("");

  const [clientList, setClientList] = useState([]);
  const [clientFullName, setClientFullName] = useState("");

  const today = new Date();
  const twoWeeksBack = new Date();
  twoWeeksBack.setDate(today.getDate() - 14);

  const formatDate = (date) => {
    if (typeof date === "string") {
      date = new Date(date); // Convert string to Date
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTimeHHMMSS = (time) => {
    return `${time}:00`;
  };
  const formatTimeHHMM = (time) => {
    if (!time) return ""; // Return an empty string or a default value if time is not defined

    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setClientFullName("");

    GlobelStore?.setEmployeeData({});
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   return d.toISOString().split("T")[0];
  // };

  // const formatTime = (time) => {
  //   const d = new Date(`1970-01-01T${time}:00`);
  //   return d.toTimeString().split(" ")[0];
  // };

  const formik = useFormik({
    initialValues: {
      clientProfileId: "",
      date: "",
      clockInTime: "",
      clockOutTime: "",
    },

    validationSchema: Yup.object({
      clientProfileId: YupValidation.requiredOnly,
      date: YupValidation.requiredOnly,
      clockInTime: YupValidation.requiredOnly,
      clockOutTime: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (status === "save") {
        addClockInClockOut(values);
      } else {
        updateEmpClockInOutDetail(values);
      }
    },
  });

  const addClockInClockOut = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeProfileId: employeeId,
      clientProfileId: values.clientProfileId,
      date: formatDate(values.date),
      clockInTime: formatTimeHHMMSS(values.clockInTime),
      clockOutTime: formatTimeHHMMSS(values.clockOutTime),
    };

    await axios
      .post(CareGiverURL.ADD_EMP_CLOCKINOUT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpClockInOutDetails(
            employeeId,
            formatDate(twoWeeksBack),
            formatDate(today)
          );
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully added");
          handleClose();
          formik.resetForm();
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to add Clock In/Out");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to add Clock In/Out");
      });
  };

  const updateEmpClockInOutDetail = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: GlobelStore?.employeeData?.id,
      employeeProfileId: employeeId,
      clientProfileId: values.clientProfileId,
      date: formatDate(values.date),
      clockInTime: formatTimeHHMMSS(values?.clockInTime),
      clockOutTime: formatTimeHHMMSS(values?.clockOutTime),
    };

    await axios
      .post(CareGiverURL.UPDATE_EMP_CLOCKINOUT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpClockInOutDetails(
            employeeId,
            formatDate(twoWeeksBack),
            formatDate(today)
          );
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully Updated");
          formik.resetForm();
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Clock In/Out");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to updated Clock In/Out");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = GlobelStore?.employeeData || {};
    const clockInTime = result?.clockInTime
      ? formatTimeHHMM(result.clockInTime)
      : "";
    const clockOutTime = result?.clockOutTime
      ? formatTimeHHMM(result.clockOutTime)
      : "";
    formik.setValues({
      clientProfileId: result?.clientProfileId || "",
      date: result?.date || "",
      clockInTime,
      clockOutTime,
    });
    const clientResponse = ClientStore?.employeeClientRelationAllData;

    const clientData = clientResponse.find(
      (item) => item.id === result?.clientProfileId
    );

    const clientFinalLabel = clientData
      ? `${clientData.lastName} ${clientData.firstName} (${clientData.mra})`
      : "";
    setClientFullName(clientFinalLabel);
  };

  useEffect(() => {
    setProfileDataFormik();
  }, [GlobelStore.employeeData]);

  useEffect(() => {
    const clientResponse = ClientStore?.employeeClientRelationAllData;
    const clientFinalData = clientResponse.map((item) => ({
      value: item.id,
      label: `${item.lastName} ${item.firstName} (${item.mra})`,
    }));
    setClientList(clientFinalData);
  }, [ClientStore?.employeeClientRelationAllData]);

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Clock In/Out
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content">
          <div className="clientDoctorCaseNoteInputContainer">
            <div className="formInputContainer">
              <div className="title">{"Client"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={clientList}
                onChange={(event, value) => {
                  formik.setFieldValue("clientProfileId", value?.value);
                  setClientFullName(value?.label);
                }}
                value={clientFullName}
                disabled={type == "update" ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.clientProfileId && formik.errors.clientProfileId
                  ? formik.errors.clientProfileId
                  : ""}
              </div>
            </div>
            <div className="formInputContainer">
              <FormikController
                name="date"
                error
                label="Date"
                type="date"
                control="textfield"
                values={formik.values.date}
                {...formik.getFieldProps("date")}
                touch={formik.touched.date}
                errorname={formik.errors.date}
                disabled={type == "update" ? true : false}
              />
            </div>
            <div className="formInputContainer">
              <FormikController
                name="clockInTime"
                error
                label="Clock in time"
                type="time"
                control="textfield"
                values={formik.values.clockInTime}
                {...formik.getFieldProps("clockInTime")}
                touch={formik.touched.clockInTime}
                errorname={formik.errors.clockInTime}
              />
            </div>
            <div className="formInputContainer">
              <FormikController
                name="clockOutTime"
                error
                label="clock out time"
                type="time"
                control="textfield"
                values={formik.values.clockOutTime}
                {...formik.getFieldProps("clockOutTime")}
                touch={formik.touched.clockOutTime}
                errorname={formik.errors.clockOutTime}
              />
            </div>
            {/* <div className="formInputContainer">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Select Time"
                  value={formik.values.time}
                  onChange={(newValue) => {
                    formik.setFieldValue("time", newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div> */}
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            {type === "update" ? (
              <FormikController
                name="Update"
                control="buttons"
                onClick={() => {
                  setStatus("update");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            ) : (
              <FormikController
                name="Add Clock In/Out"
                control="buttons"
                onClick={() => {
                  setStatus("save");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            )}
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={() => {
              handleClose();
            }}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(AddClockInOut);
