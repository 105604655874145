import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import CareGiverStore from "../../../Store/CareGiverStore";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import { YupValidation } from "../../../Constants/ValidationConstants";
import ClientStore from "../../../Store/ClientStore";
import {
  clientStatus,
  county,
  immigrationStatus,
  leadsStatus,
  maritalStatusOption,
  nameSuffix,
  nameTitle,
  radioLabel,
} from "../../../Constants/Constants";

import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";
import GlobelStore from "../../../Store/GlobelStore";

const ClientProfile = observer(({ portalType }) => {
  const navigate = useNavigate();

  const [isSaved, setIsSaved] = useState(false);
  const [saveUpadateState, setSaveUpadateState] = useState("");
  const [coCordinatorList, setCoCordinatorList] = useState([]);

  const { clientId } = useParams();

  const location = useLocation();
  const hasClientProfile = location.pathname.includes("Client_profile");
  const hasClientAddProfile = location.pathname.includes("add_client");
  const handlesaveUpadateState = (value) => {
    setSaveUpadateState(value);
  };

  const Admin = useMemo(() => GlobelStore.checkUserRole("Admin"), []);

  const formik = useFormik({
    initialValues: {
      nameTitle: "",
      nameSuffix: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      gender: "Male",
      ethnicity: "",
      immigrationStatus: "Green Card",
      maritalStatus: "Single",
      email: "",
      phoneNo: "",
      lastAuditDate: "",
      status: "Lead",
      county: "Baltimore County",
      street: "",
      city: "",
      zipCode: "",
      state: "",

      supportPlannerName: "",
      supportPlannerAgency: "",
      supportPlannerPhone: "",
      supportPlannerEmail: "",

      CEfirstName: "",
      CEmiddleName: "",
      CElastName: "",
      relationship: "",
      CEphoneNo: "",
      CEemail: "",

      referralSource: "",
      referralPerson: "",
      applicationDate: "",
      cocIncharge: "",
      mra: "",
    },

    validationSchema: Yup.object({
      nameTitle: YupValidation.nameTitle,
      nameSuffix: YupValidation.nameSuffix,
      firstName: YupValidation.firstName,
      middleName: YupValidation.middleName,
      lastName: YupValidation.lastName,
      dateOfBirth: YupValidation.dateOfBirth,
      gender: YupValidation.gender,
      ethnicity: YupValidation.ethnicity,
      immigrationStatus: YupValidation.immigration,
      maritalStatus: YupValidation.maritalStatus,
      email: YupValidation.email,
      phoneNo: YupValidation.phone,
      lastAuditDate: YupValidation.lastAuditDate,
      status: YupValidation.status,
      county: YupValidation.county,

      street: YupValidation.street,
      city: YupValidation.city,
      zipCode: YupValidation.zipCode,
      state: YupValidation.state,

      supportPlannerName: YupValidation.optionalName,
      supportPlannerAgency: YupValidation.optionalName,
      supportPlannerPhone: YupValidation.optionalphone,
      supportPlannerEmail: YupValidation.optionalEmail,

      CEfirstName: YupValidation.ECfirstName,
      CEmiddleName: YupValidation.ECmiddleName,
      CElastName: YupValidation.EClastName,
      relationship: YupValidation.relationship,
      CEphoneNo: YupValidation.phone,
      CEemail: YupValidation.optionalEmail,

      referralSource: YupValidation.referralSource,
      referralPerson: YupValidation.referralPerson,

      applicationDate: Yup.string().test({
        name: "conditional-required",
        test: function (value) {
          // Access the status field via `this.parent`
          const status = this.parent.status;
          // Check if the status is "Pending MDH decision" and the applicationDate is not empty
          if ((status === "ACTIVE" || status === "INACTIVE") && !value) {
            return false; // Fail the validation
          }
          return true; // Pass the validation
        },
        message: "Effective Date is required",
      }),

      cocIncharge: YupValidation.cocIncharge,

      mra: YupValidation.employeeId,
    }),
    onSubmit: (values) => {
      if (saveUpadateState == "save") {
        saveClientProfile(values);
      } else if (saveUpadateState == "update") {
        updateClientProfile(values);
      }
    },
  });

  const updateClientProfile = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: clientId,
      nameTitle: values.nameTitle,
      nameSuffix: values.nameSuffix,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      ethnicity: values.ethnicity,
      immigrationStatus: values.immigrationStatus,
      maritalStatus: values.maritalStatus,
      email: values.email,
      phoneNo: values.phoneNo,
      lastAuditDate: values.lastAuditDate,
      status: values.status,
      county: values.county,
      clientAddress: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },

      supportPlanner: {
        supportPlannerName: values.supportPlannerName,
        supportPlannerAgency: values.supportPlannerAgency,
        supportPlannerPhone: values.supportPlannerPhone,
        supportPlannerEmail: values.supportPlannerEmail,
      },

      clientEmergencyContact: {
        firstName: values.CEfirstName,
        middleName: values.CEmiddleName,
        lastName: values.CElastName,
        relationship: values.relationship,
        phoneNo: values.CEphoneNo,
        email: values.CEemail,
      },

      clientReferralIntake: {
        referralSource: values.referralSource,
        referralPerson: values.referralPerson,
        applicationDate: values.applicationDate,
        cocId: values.cocIncharge,
      },
    };

    axios

      .post(ClientURL.UPDATE_CLIENT_PROFILE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          ClientStore.getClientProfile(clientId);
          AgencyStore.showAlert("success", "successfully Updated");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Client Profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to update Client Profile");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = ClientStore.ClientAllData.client;
    formik.setValues({
      mra: result?.mra || "",
      nameTitle: result?.nameTitle || "",
      nameSuffix: result?.nameSuffix || "",
      firstName: result?.firstName || "",
      middleName: result?.middleName || "",
      lastName: result?.lastName || "",
      dateOfBirth: setDateInFomat(result?.dateOfBirth) || "",
      gender: result?.gender || "Male",
      ethnicity: result?.ethnicity || "",
      immigrationStatus: result?.immigrationStatus || "",
      maritalStatus: result?.maritalStatus || "",
      email: result?.email || "",
      phoneNo: result?.phoneNo || "",
      lastAuditDate: setDateInFomat(result?.lastAuditDate) || "",
      status: result?.status || "",
      county: result?.county || "",

      street: result?.clientAddress?.street || "",
      city: result?.clientAddress?.city || "",
      zipCode: result?.clientAddress?.zipCode || "",
      state: result?.clientAddress?.state || "",

      supportPlannerName: result?.supportPlanner?.supportPlannerName || "",
      supportPlannerAgency: result?.supportPlanner?.supportPlannerAgency || "",
      supportPlannerPhone: result?.supportPlanner?.supportPlannerPhone || "",
      supportPlannerEmail: result?.supportPlanner?.supportPlannerEmail || "",

      CEfirstName: result?.clientEmergencyContact?.firstName || "",
      CEmiddleName: result?.clientEmergencyContact?.middleName || "",
      CElastName: result?.clientEmergencyContact?.lastName || "",
      relationship: result?.clientEmergencyContact?.relationship || "",
      CEphoneNo: result?.clientEmergencyContact?.phoneNo || "",
      CEemail: result?.clientEmergencyContact?.email || "",

      referralSource: result?.clientReferralIntake?.referralSource || "",
      referralPerson: result?.clientReferralIntake?.referralPerson || "",
      applicationDate:
        setDateInFomat(result?.clientReferralIntake?.applicationDate) || "",
      cocIncharge: result?.clientReferralIntake?.cocId || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [clientId, ClientStore.ClientAllData]);

  const saveClientProfile = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      nameTitle: values.nameTitle,
      nameSuffix: values.nameSuffix,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      ethnicity: values.ethnicity,
      immigrationStatus: values.immigrationStatus,
      maritalStatus: values.maritalStatus,
      email: values.email,
      phoneNo: values.phoneNo,
      status: values.status,
      county: values.county,

      phoneNo: values.phoneNo,
      clientAddress: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },

      supportPlanner: {
        supportPlannerName: values.supportPlannerName,
        supportPlannerAgency: values.supportPlannerAgency,
        supportPlannerPhone: values.supportPlannerPhone,
        supportPlannerEmail: values.supportPlannerEmail,
      },
      clientEmergencyContact: {
        firstName: values.CEfirstName,
        middleName: values.CEmiddleName,
        lastName: values.CElastName,
        relationship: values.relationship,
        phoneNo: values.CEphoneNo,
        email: values.CEemail,
      },

      clientReferralIntake: {
        referralSource: values.referralSource,
        referralPerson: values.referralPerson,
        applicationDate: values.applicationDate,
        cocId: values.cocIncharge,
      },
    };
    axios
      .post(ClientURL.SAVE_CLIENT_PROFILE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.showAlert("success", "successfully added");
          setIsSaved(true);
          CareGiverStore.setIsCareGiverSaved(true);
          CareGiverStore.setCareGiverDbId(res?.data?.client?.id);
          AgencyStore.setIsLoading(false);
          navigate("/agency/Client_profile/" + res?.data?.client?.id);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Client");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Client");
      });
  };

  const setDateInFomat = (inputDateString) => {
    var dateObject = new Date(inputDateString);
    var year = dateObject.getFullYear();
    var month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    var day = dateObject.getDate().toString().padStart(2, "0");
    var outputDateString = year + "-" + month + "-" + day;
    return outputDateString;
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const getEmployeeList = async () => {
    AgencyStore.setIsLoading(true);
    const params = { employeeCategory: "CommunityCoordinator" };
    await axios
      .post(CareGiverURL.GET_EMLOYEE_LIST, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        CommunityCoordinatorStore.setCommunityCoordinatorList(
          res?.data?.resultSet
        );
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get care giver list ");
        AgencyStore.setIsLoading(false);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      await getEmployeeList();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const response = CommunityCoordinatorStore.communityCoordinatorList;
    const finalData = response.map((item) => ({
      value: item.id,
      label: `${item.fullName}(${item.employeeId})`,
    }));

    setCoCordinatorList(finalData);
  }, [CommunityCoordinatorStore.communityCoordinatorList]);

  return (
    <div
      className={
        clientId
          ? "clientProfileMainContainerWithoutShadowBox"
          : "clientProfileMainContainer"
      }
    >
      <div className="headingTitle">Profile</div>
      {clientId ? (
        <div className="formMainUniqueContainer">
          <div className="formInputContainer">
            <FormikController
              name="clientId"
              error
              label="MRA"
              type="text"
              control="textfield"
              {...formik.getFieldProps("mra")}
              touch={formik.touched.mra}
              errorname={formik.errors.mra}
              disabled={true}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="formMainContainerForFour marginTop">
        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Title"
            {...formik.getFieldProps("nameTitle")}
            error
            touch={formik.touched.nameTitle}
            errorname={formik.errors.nameTitle}
            selectitem={nameTitle}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="firstName"
            error
            label="First Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("firstName")}
            touch={formik.touched.firstName}
            errorname={formik.errors.firstName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="middleName"
            error
            label="Middle Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("middleName")}
            touch={formik.touched.middleName}
            errorname={formik.errors.middleName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="lastName"
            error
            label="Last Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("lastName")}
            touch={formik.touched.lastName}
            errorname={formik.errors.lastName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Suffix"
            {...formik.getFieldProps("nameSuffix")}
            error
            touch={formik.touched.nameSuffix}
            errorname={formik.errors.nameSuffix}
            selectitem={nameSuffix}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="formMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="dateOfBirth"
              error
              label="Date of Birth"
              type="date"
              control="textfield"
              values={formik.values.dateOfBirth}
              {...formik.getFieldProps("dateOfBirth")}
              touch={formik.touched.dateOfBirth}
              errorname={formik.errors.dateOfBirth}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="phoneNo"
              error
              label="Phone"
              type="text"
              control="textfield"
              {...formik.getFieldProps("phoneNo")}
              touch={formik.touched.phoneNo}
              errorname={formik.errors.phoneNo}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="email"
              error
              label="Email"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Marital Status"
              {...formik.getFieldProps("maritalStatus")}
              error
              touch={formik.touched.maritalStatus}
              errorname={formik.errors.maritalStatus}
              selectitem={maritalStatusOption}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Immigration Status"
              {...formik.getFieldProps("immigrationStatus")}
              error
              touch={formik.touched.immigrationStatus}
              errorname={formik.errors.immigrationStatus}
              selectitem={immigrationStatus}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ethnicity"
              error
              label="Ethnicity"
              type="text"
              control="textfield"
              {...formik.getFieldProps("ethnicity")}
              touch={formik.touched.ethnicity}
              errorname={formik.errors.ethnicity}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="lastAuditDate"
              error
              label="Last Audit Date"
              type="date"
              control="textfield"
              values={formik.values.lastAuditDate}
              {...formik.getFieldProps("lastAuditDate")}
              touch={formik.touched.lastAuditDate}
              errorname={formik.errors.lastAuditDate}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Status"
              {...formik.getFieldProps("status")}
              error
              touch={formik.touched.status}
              errorname={formik.errors.status}
              selectitem={
                hasClientProfile || hasClientAddProfile
                  ? clientStatus
                  : leadsStatus
              }
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="County"
              {...formik.getFieldProps("county")}
              error
              touch={formik.touched.county}
              errorname={formik.errors.county}
              selectitem={county}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
      </div>
      <div className="marginTop">
        <FormikController
          title="Gender"
          control="radio"
          row
          error
          radioLabel={radioLabel}
          {...formik.getFieldProps("gender")}
          touch={formik.touched.gender}
          errorname={formik.errors.gender}
          disabled={isSaved || portalType == "NurseAndCareGiver" ? true : false}
        />
      </div>

      <div className="headingTitle marginTop">Address</div>
      <div className="addressMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="street"
              error
              label="Street"
              type="text"
              control="textfield"
              {...formik.getFieldProps("street")}
              touch={formik.touched.street}
              errorname={formik.errors.street}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="city"
              error
              label="City"
              type="text"
              control="textfield"
              {...formik.getFieldProps("city")}
              touch={formik.touched.city}
              errorname={formik.errors.city}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="zipCode"
              error
              label="Zip code"
              type="text"
              control="textfield"
              {...formik.getFieldProps("zipCode")}
              touch={formik.touched.zipCode}
              errorname={formik.errors.zipCode}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="state"
              error
              label="State"
              type="text"
              control="textfield"
              {...formik.getFieldProps("state")}
              touch={formik.touched.state}
              errorname={formik.errors.state}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        {/* <div className="formInputContainer">
          <FormikController
            name="apartmentNumber"
            error
            label="Apartment Number"
            type="text"
            control="textfield"
            {...formik.getFieldProps("apartmentNumber")}
            touch={formik.touched.apartmentNumber}
            errorname={formik.errors.apartmentNumber}
            disabled={
              isSaved || portalType == "NurseAndCareGiver" ? true : false
            }
          />
        </div> */}
      </div>
      <div className="emergencyContactTitle headingTitle marginTop">
        Support Planner
      </div>
      <div className="addressMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="supportPlannerName"
              error
              label="Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("supportPlannerName")}
              touch={formik.touched.supportPlannerName}
              errorname={formik.errors.supportPlannerName}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="supportPlannerAgency"
              error
              label="Agency"
              type="text"
              control="textfield"
              {...formik.getFieldProps("supportPlannerAgency")}
              touch={formik.touched.supportPlannerAgency}
              errorname={formik.errors.supportPlannerAgency}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="supportPlannerPhone"
              error
              label="Phone"
              type="text"
              control="textfield"
              {...formik.getFieldProps("supportPlannerPhone")}
              touch={formik.touched.supportPlannerPhone}
              errorname={formik.errors.supportPlannerPhone}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="supportPlannerEmail"
              error
              label="Email"
              type="text"
              control="textfield"
              {...formik.getFieldProps("supportPlannerEmail")}
              touch={formik.touched.supportPlannerEmail}
              errorname={formik.errors.supportPlannerEmail}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
      </div>
      <div className="emergencyContactTitle marginTop headingTitle">
        Client Referral Intake
      </div>
      <div className="addressMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="referralSource"
              error
              label="Referral Source"
              type="text"
              control="textfield"
              {...formik.getFieldProps("referralSource")}
              touch={formik.touched.referralSource}
              errorname={formik.errors.referralSource}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="referralPerson"
              error
              label="Referral Person"
              type="text"
              control="textfield"
              {...formik.getFieldProps("referralPerson")}
              touch={formik.touched.referralPerson}
              errorname={formik.errors.referralPerson}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="applicationDate"
              error
              label="Effective Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("applicationDate")}
              values={formik.values.dateOfBirth}
              touch={formik.touched.applicationDate}
              errorname={formik.errors.applicationDate}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="COC in Charge"
              {...formik.getFieldProps("cocIncharge")}
              error
              touch={formik.touched.cocIncharge}
              errorname={formik.errors.cocIncharge}
              selectitem={coCordinatorList}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
      </div>
      <div className="emergencyContactTitle headingTitle">
        Emergency Contact
      </div>
      <div className="formMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="CEfirstName"
              error
              label="First Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("CEfirstName")}
              touch={formik.touched.CEfirstName}
              errorname={formik.errors.CEfirstName}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="CEmiddleName"
              error
              label="Middle Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("CEmiddleName")}
              touch={formik.touched.CEmiddleName}
              errorname={formik.errors.CEmiddleName}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="CElastName"
              error
              label="Last Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("CElastName")}
              touch={formik.touched.CElastName}
              errorname={formik.errors.CElastName}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="relationship"
              error
              label="Relationship"
              type="text"
              control="textfield"
              {...formik.getFieldProps("relationship")}
              touch={formik.touched.relationship}
              errorname={formik.errors.relationship}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="CEphoneNo"
              error
              label="Phone"
              type="text"
              control="textfield"
              {...formik.getFieldProps("CEphoneNo")}
              touch={formik.touched.CEphoneNo}
              errorname={formik.errors.CEphoneNo}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="CEemail"
              error
              label="Email"
              type="email"
              control="textfield"
              {...formik.getFieldProps("CEemail")}
              touch={formik.touched.CEemail}
              errorname={formik.errors.CEemail}
              disabled={
                isSaved || portalType == "NurseAndCareGiver" ? true : false
              }
            />
          </div>
        </div>
      </div>
      {portalType !== "NurseAndCareGiver" && (
        <div className="buttonContainer">
          {clientId ? (
            <>
              <ConfirmPopUp
                buttonLabel="Update "
                title="Update "
                content=" Please confirm your decision below."
                onConfirm={() => {
                  handlesaveUpadateState("update");
                  formik.handleSubmit();
                }}
                clickHTML={
                  <FormikController
                    name="Update "
                    control="buttons"
                    className="buttonSelectedGreen fixedWidth"
                    fullWidth="false"
                  />
                }
              />

              {Admin && (
                <ConfirmPopUp
                  buttonLabel="Delete "
                  title="Delete Profile"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    // handlesaveUpadateState("update");
                    // formik.handleSubmit();
                    // deleteEmployee(employeeId);
                    GlobelStore.deleteClient(
                      clientId,
                      hasClientProfile ? "Client" : "Leads",
                      navigate
                    );
                  }}
                  clickHTML={
                    <FormikController
                      name="Delete "
                      control="buttons"
                      className="buttonSelectedRed fixedWidth"
                      fullWidth="false"
                    />
                  }
                />
              )}
            </>
          ) : isSaved == true ? (
            ""
          ) : (
            <>
              <ConfirmPopUp
                buttonLabel="Save "
                title="Save "
                content=" Please confirm your decision below."
                onConfirm={() => {
                  handlesaveUpadateState("save");
                  formik.handleSubmit();
                }}
                clickHTML={
                  <FormikController
                    name="Save"
                    control="buttons"
                    className="buttonSelectedGreen fixedWidth"
                    fullWidth="false"
                  />
                }
              />

              <FormikController
                name="Reset"
                control="buttons"
                onClick={() => {
                  resetForm();
                }}
                // onClick={formik.handleSubmit}
                className="buttonUnselected fixedWidth"
                fullWidth="false"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
});

export default ClientProfile;
