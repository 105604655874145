import { observer } from "mobx-react";
import React from "react";
import "../../../CSS/CareGiverClientView.css";

import ClientStore from "../../../Store/ClientStore";

import { Link } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const CommunityOutReachCoordinatorClientView = observer(() => {
  const employeeClientRelationData =
    ClientStore.employeeClientRelationAllData || [];

  return (
    <div>
      <div className="ClientListMainContainer">
        <div className="headerContainer">
          <div className="headerItem">#MRA</div>
          <div className="headerItem">Name</div>
          <div className="headerItem">Phone</div>
        </div>

        {employeeClientRelationData.length === 0 ? (
          <div className="noRecordsMessage">No records available</div>
        ) : (
          employeeClientRelationData.map((item, index) => (
            <div className="gridContainer" key={index}>
              <div className="gridItem">
                <Link
                  to={`/agency/Client_profile/${item.id}`}
                  className="gridItemName"
                >
                  {item.mra}
                </Link>
              </div>

              <div className="gridItem">
                <div className="avatarNameContainer">
                  <Avatar
                    {...stringAvatar(`${item.firstName} ${item.lastName}`)}
                  />
                  <div className="gridItemNameEmail">
                    <Link
                      to={`/agency/Client_profile/${item.id}`}
                      className="gridItemName"
                    >
                      {`${item.fullName}`}
                    </Link>
                    <div className="gridItemEmail">{item.email}</div>
                  </div>
                </div>
              </div>
              <div className="gridItem">{item.phoneNo}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
});

export default CommunityOutReachCoordinatorClientView;
