import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";

import { InputAdornment } from "@mui/material";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import axios from "axios";
import GlobelStore from "../../../Store/GlobelStore";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";
import Icon from "../../../components/Icons/Icon";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const CommunityOutReachCoordinatorCreateAccount = observer(() => {
  const { employeeId } = GlobelStore.employeeData.id
    ? { employeeId: GlobelStore.employeeData.id }
    : useParams();

  // const handleCheckboxChange = (item) => {
  //   // Check if the item is already in the array
  //   if (CommunityCoordinatorStore.communityCoordinatorRoles.includes(item)) {
  //     // If yes, remove it
  //     CommunityCoordinatorStore.setCommunityCoordinatorRoles(
  //       CommunityCoordinatorStore.communityCoordinatorRoles.filter(
  //         (role) => role !== item
  //       )
  //     );
  //   } else {
  //     // If no, add it
  //     CommunityCoordinatorStore.setCommunityCoordinatorRoles([
  //       ...CommunityCoordinatorStore.communityCoordinatorRoles,
  //       item,
  //     ]);
  //   }
  //   checkRoles();
  // };

  const handleCheckboxChange = (item) => {
    if (item === "Admin") {
      if (
        CommunityCoordinatorStore.communityCoordinatorRoles.includes("Admin")
      ) {
        // If "Admin" is already selected, remove all roles
        CommunityCoordinatorStore.setCommunityCoordinatorRoles([]);
      } else {
        // If "Admin" is not selected, select all available roles
        CommunityCoordinatorStore.setCommunityCoordinatorRoles(
          GlobelStore.availableRoles
        );
      }
    } else {
      if (CommunityCoordinatorStore.communityCoordinatorRoles.includes(item)) {
        // If the item is already selected, remove it
        // Also, if "Admin" is selected, uncheck it as well
        const newRoles =
          CommunityCoordinatorStore.communityCoordinatorRoles.filter(
            (role) => role !== item
          );
        if (
          CommunityCoordinatorStore.communityCoordinatorRoles.includes("Admin")
        ) {
          newRoles.splice(newRoles.indexOf("Admin"), 1);
        }
        CommunityCoordinatorStore.setCommunityCoordinatorRoles(newRoles);
      } else {
        // If the item is not selected, add it
        CommunityCoordinatorStore.setCommunityCoordinatorRoles([
          ...CommunityCoordinatorStore.communityCoordinatorRoles,
          item,
        ]);
      }
    }
    checkRoles();
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const handleClickConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is invalid"),
      newPassword: Yup.string()
        .required("Password Required")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Enter capital,digit,small and special character at least eight digits"
        ),
      confirmNewPassword: Yup.string()
        .required("Password Required")

        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.newPassword === value;
        }),
    }),
    onSubmit: (values) => {
      createCareGiverAccount(values);
    },
  });

  const setProfileDataFormik = () => {
    const result = CareGiverStore.careGiverProfileData;
    formik.setValues({
      email: result?.email || "",
    });
  };

  const checkRoles = () => {
    if (CommunityCoordinatorStore.communityCoordinatorRoles.length === 0) {
      setRoleErrorMessage("Please select at least one role.");
      AgencyStore.setIsLoading(false);

      return false; // Exit the function early
    } else {
      setRoleErrorMessage("");
      AgencyStore.setIsLoading(false);

      return true;
    }
  };

  const [roleErrorMessage, setRoleErrorMessage] = useState("");
  const createCareGiverAccount = (values) => {
    AgencyStore.setIsLoading(true);

    if (checkRoles()) {
      const params = {
        employeeProfileId: employeeId,
        employeeUniqueId: CareGiverStore?.careGiverProfileData?.employeeId,
        employeePass: values.confirmNewPassword,
        roles: CommunityCoordinatorStore.communityCoordinatorRoles,
      };
      axios
        .post(CareGiverURL.CREATE_CAREGIVER_ACCOUNT, params, {
          headers: { Authorization: localStorage.getItem("loginToken") },
        })
        .then((res) => {
          if (res?.data?.status == "success") {
            CareGiverStore.getEmployeeProfile(employeeId);
            AgencyStore.showAlert("success", "Account successfully created");
          } else {
            AgencyStore.showAlert("error", "Failed to create an account");
          }
          AgencyStore.setIsLoading(false);
        })
        .catch((err) => {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to create an account");
        });
    }
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, CareGiverStore.careGiverProfileData]);
  return (
    <div>
      <div className="securityMainContainer">
        <Accordion
          className="accordionMainContainer"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className="accordionSummaryMainContainer"
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <span className="accordionTitle">Ceate an account</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="formAndRolesContainer">
              <div className="changePasswordFormMainContainer subMainContiner">
                <div className="formMainContainerForPopUp">
                  <div>
                    <FormikController
                      name="email"
                      error
                      label="Email"
                      type="email"
                      control="textfield"
                      {...formik.getFieldProps("email")}
                      touch={formik.touched.email}
                      errorname={formik.errors.email}
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="formMainContainerForPopUp">
                  <div>
                    <FormikController
                      name="newPassword"
                      error
                      label="New Password"
                      control="textfield"
                      type={showNewPassword ? "text" : "password"}
                      touch={formik.touched.newPassword}
                      errorname={formik.errors.newPassword}
                      {...formik.getFieldProps("newPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleClickNewPassword}
                          >
                            {showNewPassword ? (
                              <Icon
                                iconName="visible"
                                className={
                                  formik.touched.newPassword &&
                                  formik.errors.newPassword
                                    ? "errors"
                                    : "icon"
                                }
                              />
                            ) : (
                              <Icon
                                iconName="invisible"
                                className={
                                  formik.touched.newPassword &&
                                  formik.errors.newPassword
                                    ? "errors"
                                    : "icon"
                                }
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    <FormikController
                      name="confirmNewPassword"
                      error
                      label="Confirm New Password"
                      control="textfield"
                      type={showConfirmNewPassword ? "text" : "password"}
                      touch={formik.touched.confirmNewPassword}
                      errorname={formik.errors.confirmNewPassword}
                      {...formik.getFieldProps("confirmNewPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleClickConfirmNewPassword}
                          >
                            {showConfirmNewPassword ? (
                              <Icon
                                icoName="visible"
                                className={
                                  formik.touched.confirmNewPassword &&
                                  formik.errors.confirmNewPassword
                                    ? "errors"
                                    : "icon"
                                }
                              />
                            ) : (
                              <Icon
                                icoName="invisible"
                                className={
                                  formik.touched.confirmNewPassword &&
                                  formik.errors.confirmNewPassword
                                    ? "errors"
                                    : "icon"
                                }
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="rolesContainer backgroundcolorLightDark">
                <div className="roleSpamMsgConatiner">
                  <div className="title">Roles</div>
                  <span className="errorMessage">{roleErrorMessage}</span>
                </div>

                {GlobelStore.availableRoles.map((item, index) => (
                  <div key={index} className="roleItem">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(item)}
                      checked={CommunityCoordinatorStore.communityCoordinatorRoles.includes(
                        item
                      )}
                    />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="changePasswordInfoMainContainer">
              <div className="changePasswordTitle">Password Requirements:</div>
              <div className="changePasswordSubTitle">
                <li>Minimum 8 characters long - the more, the better</li>
                <li>At least one lowercase character</li>
                <li>At least one number, symbol, or whitespace character</li>
              </div>
            </div>
            <div className="changePasswordButtonContainer">
              <FormikController
                name="Create Account"
                control="buttons"
                onClick={formik.handleSubmit}
                // onClick={() => {
                //   handlesaveUpadateState("save");
                //   formik.handleSubmit();
                // }}
                className="buttonSelected fixedWidth"
                fullWidth="false"
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
});

export default CommunityOutReachCoordinatorCreateAccount;
