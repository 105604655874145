import React, { useRef, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import {
  createColumnDef,
  statusCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";
import FormikController from "../../../components/FormikController/FormikController";
import { useNavigate } from "react-router-dom";

const CareGiverListTable = observer(() => {
  const navigate = useNavigate();

  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    CareGiverStore,
    "careGiverAgGrid"
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      navigate("/agency/careGiverProfile/" + employeeId);
    };

    return (
      <div onClick={onFullNameClick} className="ag-inner-cell">
        {params.value}
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      createColumnDef(
        "employeeId",
        "#Id",
        leftStyles,
        fullNameCellRenderer,
        "",
        60
      ),
      createColumnDef("fullName", "FullName", leftStyles, fullNameCellRenderer),
      createColumnDef(
        "status",
        "Status",
        centerStyles,
        statusCellRenderer,
        "center-header"
      ),
    ],
    []
  );

  useEffect(() => {
    GlobelStore.getEmployeeList("CareGiver");
  }, []);

  return (
    <FormikController
      control="agGrid"
      rowData={CareGiverStore.careGiverList}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
});

export default CareGiverListTable;
