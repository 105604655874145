import React, { useEffect, useState } from "react";
import { observer } from "mobx-react"; // Import observer from mobx-react
import ClientStore from "../../../Store/ClientStore";

import "./ClientDocterInfo.css";

const ClientDocterInfo = observer(({ contentElementRef }) => {
  // const contentElement = document.getElementById("content-id");
  // const { doctorInfo } = ClientStore?.individualDocterInfoData;
  const [doctorInfo, setDoctorInfo] = useState({});

  useEffect(() => {
    setDoctorInfo(ClientStore?.individualDocterInfoData);
  }, [ClientStore?.individualDocterInfoData]);
  return (
    <div className="A4Conatiner">
      <div className="ClientDocterInfo" id="content-id" ref={contentElementRef}>
        {" "}
        <div className="CDIMainHeader">Doctor Information</div>
        <div className="CDImainTableContainer">
          <div className="CDIGridConatiner">
            <div className="CDItitleSection">Client Name</div>
            <div className="CDIresultSection">
              {`${doctorInfo?.clientFirstName} ${doctorInfo?.clientMiddleName} ${doctorInfo?.clientLastName}`}
            </div>
          </div>
          <div className="CDIGridConatiner">
            <div className="CDItitleSection">Doctor</div>
            <div className="CDIresultSection">{doctorInfo?.doctor}</div>
          </div>
          <div className="CDIGridConatiner">
            <div className="CDItitleSection">Phone</div>
            <div className="CDIresultSection">{doctorInfo?.phoneNo}</div>
          </div>
          <div className="CDIGridConatiner">
            <div className="CDItitleSection">Address</div>
            <div className="CDIresultSection">{doctorInfo?.address}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ClientDocterInfo;
