import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "../../../CSS/Calendar.css";
import "../../../CSS/FullCalenderEvents.css";
import { observer } from "mobx-react";
import CalenderStore from "../../../Store/CalenderStore";
import ClientStore from "../../../Store/ClientStore";
import AgencyStore from "../../../Store/AgencyStore";

const FullcalendarEvent = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    // alert(info.event.extendedProps.customData.id);
    CalenderStore.setSpecificCalendarAllData(
      info.event.extendedProps.customData
    );
    ClientStore.getEmployeeClientRelation(
      info.event.extendedProps.customData.employeeProfileId
    );
    CalenderStore.setIsSwipeableTemporaryDrawer("update");
    CalenderStore.setOpenDrawer(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    const calenderData = CalenderStore?.employeeAllCalendarData?.resultSet;

    if (calenderData) {
      const calenderDataFinalData = calenderData.map((item) => ({
        title: item.title,
        start: item.dateTime,
        end: item.dateTime,
        customData: item,
      }));

      setEvents(calenderDataFinalData);
    }
  }, [
    CalenderStore?.employeeAllCalendarData?.resultSet,
    AgencyStore.isLeftContainerVisible,
  ]);

  return (
    <FullCalendar
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridDay,listMonth",
      }}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      events={events}
      eventClick={handleEventClick}
      dayMaxEvents={true} // Enable eventLimit for all non-TimeGrid views
      views={{
        timeGrid: {
          dayMaxEvents: 2, // Adjust to 6 only for timeGridWeek/timeGridDay
        },
      }}
      eventContent={(eventInfo) => {
        const customData = eventInfo.event.extendedProps.customData;
        const isExpired = new Date(customData.dateTime) < new Date();
        const isUpcoming = new Date(customData.dateTime) > new Date();

        const eventClasses = ["event-container"];
        if (isExpired || customData.status == "CANCLED") {
          eventClasses.push("expired-event");
        } else if (isUpcoming) {
          eventClasses.push("upcoming-event-caregiver");
        }

        return (
          <div className={eventClasses.join(" ")}>
            <div className="event-title">
              {eventInfo.event.title +
                " (" +
                customData.employeeLastName +
                " " +
                customData.employeeFirstName +
                ")"}
            </div>
            {/* <div className="event-time">
              {new Date(customData.dateTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div> */}
          </div>
        );
      }}
    />
  );
};

export default observer(FullcalendarEvent);
