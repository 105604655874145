import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ClientStore from "../../../../Store/ClientStore";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AgencyStore from "../../../../Store/AgencyStore";
import PosUploadPopUp from "./PosUploadPopUp";
import ConfirmPopUp from "../../../../components/ConfirmPopUp/ConfirmPopUp";
import { ClientURL } from "../../../../Url/Url";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getPosTypeId, getPosTypeName } from "../../../../Utils/Utils";

const PlanOfService = () => {
  const [responseData, setResponseData] = useState({});
  const { clientId } = useParams();

  // const [currentKey2, setCurrentKey2] = useState("");
  const downloadPDF = (data, fileName) => {
    AgencyStore.downloadFile(data, fileName);
  };
  const openPdf = (data) => {
    AgencyStore.openPDF(data);
  };

  const deletePosDoc = async (docId) => {
    AgencyStore.setIsLoading(true);

    const params = { clientProfileId: clientId, docId: docId };
    await axios
      .post(ClientURL.DELETE_POS_DOC, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          ClientStore.getPosDoc(clientId);
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "success",
            "The document has been successfully deleted."
          );
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to delete the document.");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to delete the document.");
      });
  };

  useEffect(() => {
    setResponseData(ClientStore?.posDocAllData?.resultMap || {});
  }, [ClientStore?.posDocAllData]);

  const renderDocument = (document) => (
    <>
      <div key={document.id} className="posDocDetailsContainer">
        <div className="docTitleToViewConatiner">
          <div>
            <div className="docTitle">{document.fileName}</div>

            <div className="docSubTitlemainContainer">
              {document.receivedDate && (
                <div>Received Date: {document.receivedDate}</div>
              )}
              {document.effectiveDate && (
                <div>Effective Date: {document.effectiveDate}</div>
              )}
              {document.endDate && <div>End Date: {document.endDate}</div>}
              <div
                className="docDownloadText"
                onClick={() => {
                  downloadPDF(document.filePath, document.fileName);
                }}
              >
                Download
              </div>
              <ConfirmPopUp
                title="Delete"
                content="You are about to perform a critical action that cannot be undone. Please confirm your decision below."
                onConfirm={() => {
                  deletePosDoc(document.id);
                }}
                clickHTML={<div className="docDeleteText">Delete</div>}
              />
            </div>
          </div>

          <div
            className="docViewText"
            onClick={() => {
              openPdf(document.filePath);
            }}
          >
            View
          </div>
        </div>
        <div className="docCaseNote">Case Note: {document.caseNote}</div>
      </div>
    </>
  );

  return (
    <div>
      <div className="listMainDivContainer">
        {Object.entries(responseData).map(([key1, document1]) => (
          <div key={key1} className="docDetailsMainContainer">
            <div className="docTypeTitle">
              <div>{key1}</div>
              <PosUploadPopUp
                uniqueNameType={key1}
                clickHTML={
                  <div className="docAddContainer">
                    <AiOutlinePlusCircle className="docAddIcon" /> Add New
                  </div>
                }
              />
            </div>
            {key1 === "POS"
              ? Object.entries(document1).map(([key2, document2]) => (
                  <div key={key2} className="docSubTypeTitle">
                    <div className="docTypeTitle">{getPosTypeName(key2)}</div>
                    <div className="docDetailsMainContainer">
                      {document2.map(renderDocument)}
                      {/* {document2.map((doc) => {
                        setCurrentKey2(key2);
                        return renderDocument(doc);
                      })} */}
                    </div>
                  </div>
                ))
              : document1.map(renderDocument)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(PlanOfService);
