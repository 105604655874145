import React from "react";

const PageTitle = (props) => {
  return (
    <div className="pageHeaderIconDetail">
      <div className="pageHeaderIconContainer">{props.icon}</div>
      <div className="pageHeaderTitleCategory">
        <span className="pageHeaderTitle"> {props.headerTitle}</span>
        <span className="pageHeaderSubTitle"> {props.subHeaderTitle}</span>
      </div>
    </div>
  );
};

export default React.memo(PageTitle);
