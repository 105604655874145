import React from "react";
import "../../CSS/Error404.css";
import FormikController from "../../components/FormikController/FormikController";
import errorImage from "../../Images/pageNotFound.svg";
import AgencyStore from "../../Store/AgencyStore";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate("/agency/dashboard");
    AgencyStore.setSelectedMenuItem("dashboard");
  };

  return (
    <div className="pageNotFoundMainContainer">
      <div className="errorContainer">
        <h1>404</h1>
        <h2>You are not authorized</h2>
        <p>
          You do not have permission to view this page using the credentials
          that you have provided while login. Please contact your site
          administrator.
        </p>
        <div className="ButtonDiv">
          <FormikController
            name="Back to Dashboard"
            control="buttons"
            className="custom-button"
            onClick={handleBackHome}
          />
        </div>
      </div>
      <div className="pageNOtFoundImageContainer">
        <img
          className="errorImage"
          src={errorImage}
          alt=""
          style={{ width: "30%" }}
        />
      </div>
    </div>
  );
};

export default Error404;
