import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import CareGiverStore from "../../../Store/CareGiverStore";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import "../../../CSS/CommunityOutReachCoordinator.css";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { setDateInFomat } from "../../../Utils/Utils";
import {
  maritalStatusOption,
  immigrationStatus,
  radioLabel,
  status,
  nameSuffix,
  nameTitle,
} from "../../../Constants/Constants";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import GlobelStore from "../../../Store/GlobelStore";

const CommunityOutReachCoordinatorProfile = observer(() => {
  const navigate = useNavigate();

  const [isSaved, setIsSaved] = useState(false);
  const [saveUpadateState, setSaveUpadateState] = useState("");
  const { employeeId } = useParams();
  const handlesaveUpadateState = (value) => {
    setSaveUpadateState(value);
  };

  const Admin = useMemo(() => GlobelStore.checkUserRole("Admin"), []);

  const formik = useFormik({
    initialValues: {
      nameTitle: "",
      nameSuffix: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      phone: "",
      email: "",
      maritalStatus: "Single",
      immigrationStatus: "Green Card",
      ethnicity: "",
      gender: "Male",
      street: "",
      city: "",
      zipCode: "",
      state: "",
      status: "Active",
      hireDate: "",
      ECfirstName: "",
      ECmiddleName: "",
      EClastName: "",
      relationship: "",
      ECphone: "",
      ECemail: "",
      employeeId: "",
    },
    validationSchema: Yup.object({
      nameTitle: YupValidation.nameTitle,
      nameSuffix: YupValidation.nameSuffix,
      firstName: YupValidation.firstName,
      middleName: YupValidation.middleName,
      lastName: YupValidation.lastName,
      gender: YupValidation.gender,
      dateOfBirth: YupValidation.dateOfBirth,
      phone: YupValidation.phone,
      email: YupValidation.email,
      maritalStatus: YupValidation.maritalStatus,
      immigrationStatus: YupValidation.immigration,
      ethnicity: YupValidation.ethnicity,
      street: YupValidation.street,
      city: YupValidation.city,
      zipCode: YupValidation.zipCode,
      state: YupValidation.state,
      status: YupValidation.status,
      hireDate: YupValidation.joinDate,
      ECfirstName: YupValidation.ECfirstName,
      ECmiddleName: YupValidation.ECmiddleName,
      EClastName: YupValidation.EClastName,
      relationship: YupValidation.relationship,
      ECphone: YupValidation.phone,
      ECemail: YupValidation.optionalEmail,
      employeeId: YupValidation.employeeId,
    }),
    onSubmit: (values) => {
      if (saveUpadateState == "save") {
        saveEmployee(values);
      } else if (saveUpadateState == "update") {
        updateEmployeeProfile(values);
      }
    },
  });

  const updateEmployeeProfile = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: employeeId,
      nameTitle: values.nameTitle,
      nameSuffix: values.nameSuffix,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      ethnicity: values.ethnicity,
      immigrationStatus: values.immigrationStatus,
      maritalStatus: values.maritalStatus,
      phoneNo: values.phone,
      email: values.email,
      employeeAddress: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },
      employeeInfo: {
        status: values.status,
        hireDate: values.hireDate,
      },
      employeeEmergencyContact: {
        firstName: values.ECfirstName,
        middleName: values.ECmiddleName,
        lastName: values.EClastName,
        relationship: values.relationship,
        phoneNo: values.ECphone,
        email: values.ECemail,
      },
    };

    axios
      .post(CareGiverURL.UPDATE_EmployeeProfile, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          CareGiverStore.getEmployeeProfile(employeeId);
          AgencyStore.showAlert("success", "successfully Updated");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to update Community Outreach Coordinator Profile "
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);

        AgencyStore.showAlert(
          "error",
          "Failed to update Care GiverCommunity Outreach Coordinator Profile "
        );
      });
  };

  const setProfileDataFormik = (data) => {
    const result = CareGiverStore.careGiverProfileData;
    formik.setValues({
      nameTitle: result?.nameTitle || "",
      nameSuffix: result?.nameSuffix || "",
      firstName: result?.firstName || "",
      middleName: result?.middleName || "",
      lastName: result?.lastName || "",
      dateOfBirth: setDateInFomat(result?.dateOfBirth) || "",
      phone: result?.phoneNo || "",
      email: result?.email || "",
      maritalStatus: result?.maritalStatus || "",
      immigrationStatus: result?.immigrationStatus || "",
      ethnicity: result?.ethnicity || "",
      gender: result?.gender || "Male",
      street: result?.employeeAddress?.street || "",
      city: result?.employeeAddress?.city || "",
      zipCode: result?.employeeAddress?.zipCode || "",
      state: result?.employeeAddress?.state || "",
      status: result?.employeeInfo?.status || "",
      hireDate: setDateInFomat(result?.employeeInfo?.hireDate) || "",
      ECfirstName: result?.employeeEmergencyContact?.firstName || "",
      ECmiddleName: result?.employeeEmergencyContact?.middleName || "",
      EClastName: result?.employeeEmergencyContact?.lastName || "",
      relationship: result?.employeeEmergencyContact?.relationship || "",
      ECphone: result?.employeeEmergencyContact?.phoneNo || "",
      ECemail: result?.employeeEmergencyContact?.email || "",
      employeeId: result?.employeeId || "",
    });
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, CareGiverStore.careGiverProfileData]);

  const saveEmployee = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeCategory: "CommunityCoordinator",
      nameTitle: values.nameTitle,
      nameSuffix: values.nameSuffix,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      ethnicity: values.ethnicity,
      immigrationStatus: values.immigrationStatus,
      maritalStatus: values.maritalStatus,
      phoneNo: values.phone,
      email: values.email,
      employeeAddress: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },
      employeeInfo: {
        // employeeType: values.employeeType,

        hireDate: values.hireDate,
        status: values.status,
      },
      employeeEmergencyContact: {
        firstName: values.ECfirstName,
        middleName: values.ECmiddleName,
        lastName: values.EClastName,
        relationship: values.relationship,
        phoneNo: values.ECphone,
        email: values.ECemail,
      },
    };

    axios
      .post(CareGiverURL.Add_EMLOYEE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
          setIsSaved(true);
          CareGiverStore.setIsCareGiverSaved(true);
          CareGiverStore.setCareGiverDbId(res?.data?.result?.id);
          navigate(
            "/agency/communityOutReachCoordinatorProfile/" +
              res?.data?.result?.id
          );
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to save Community Outreach Coordinator"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to save Community Outreach Coordinator"
        );
      });
  };

  return (
    <>
      <div className="headingTitle">Profile</div>
      {employeeId ? (
        <div className="formMainUniqueContainer">
          <div className="formInputContainer">
            <FormikController
              name="employeeId"
              error
              label="Qnique Id"
              type="text"
              control="textfield"
              {...formik.getFieldProps("employeeId")}
              touch={formik.touched.employeeId}
              errorname={formik.errors.employeeId}
              disabled={true}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="formMainContainerForFour marginTop">
        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Title"
            {...formik.getFieldProps("nameTitle")}
            error
            touch={formik.touched.nameTitle}
            errorname={formik.errors.nameTitle}
            selectitem={nameTitle}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="firstName"
            error
            label="First Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("firstName")}
            touch={formik.touched.firstName}
            errorname={formik.errors.firstName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="middleName"
            error
            label="Middle Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("middleName")}
            touch={formik.touched.middleName}
            errorname={formik.errors.middleName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="lastName"
            error
            label="Last Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("lastName")}
            touch={formik.touched.lastName}
            errorname={formik.errors.lastName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Suffix"
            {...formik.getFieldProps("nameSuffix")}
            error
            touch={formik.touched.nameSuffix}
            errorname={formik.errors.nameSuffix}
            selectitem={nameSuffix}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="formMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="dateOfBirth"
              error
              label="Date of Birth"
              type="date"
              control="textfield"
              values={formik.values.dateOfBirth}
              {...formik.getFieldProps("dateOfBirth")}
              touch={formik.touched.dateOfBirth}
              errorname={formik.errors.dateOfBirth}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="phone"
              error
              label="Phone"
              type="text"
              control="textfield"
              {...formik.getFieldProps("phone")}
              touch={formik.touched.phone}
              errorname={formik.errors.phone}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="email"
              error
              label="Email"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Marital Status"
              {...formik.getFieldProps("maritalStatus")}
              error
              touch={formik.touched.maritalStatus}
              errorname={formik.errors.maritalStatus}
              selectitem={maritalStatusOption}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Immigration Status"
              {...formik.getFieldProps("immigrationStatus")}
              error
              touch={formik.touched.immigrationStatus}
              errorname={formik.errors.immigrationStatus}
              selectitem={immigrationStatus}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ethnicity"
              error
              label="Ethnicity"
              type="text"
              control="textfield"
              {...formik.getFieldProps("ethnicity")}
              touch={formik.touched.ethnicity}
              errorname={formik.errors.ethnicity}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="marginTop">
        <FormikController
          title="Gender"
          control="radio"
          row
          error
          radioLabel={radioLabel}
          {...formik.getFieldProps("gender")}
          touch={formik.touched.gender}
          errorname={formik.errors.gender}
          disabled={isSaved ? true : false}
        />
      </div>

      <div className="headingTitle marginTop">Address</div>
      <div className="addressMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="street"
              error
              label="Street"
              type="text"
              control="textfield"
              {...formik.getFieldProps("street")}
              touch={formik.touched.street}
              errorname={formik.errors.street}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="city"
              error
              label="City"
              type="text"
              control="textfield"
              {...formik.getFieldProps("city")}
              touch={formik.touched.city}
              errorname={formik.errors.city}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="zipCode"
              error
              label="Zip code"
              type="text"
              control="textfield"
              {...formik.getFieldProps("zipCode")}
              touch={formik.touched.zipCode}
              errorname={formik.errors.zipCode}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="state"
              error
              label="State"
              type="text"
              control="textfield"
              {...formik.getFieldProps("state")}
              touch={formik.touched.state}
              errorname={formik.errors.state}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="marginTop headingTitle">Employee Info</div>
      <div className="twoGridContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              control="selects"
              title="Status"
              {...formik.getFieldProps("status")}
              error
              touch={formik.touched.status}
              errorname={formik.errors.status}
              selectitem={status}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="hireDate"
              error
              label="Hire Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("hireDate")}
              values={formik.values.dateOfBirth}
              touch={formik.touched.hireDate}
              errorname={formik.errors.hireDate}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="emergencyContactTitle headingTitle">
        Emergency Contact
      </div>
      <div className="formMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ECfirstName"
              error
              label="First Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("ECfirstName")}
              touch={formik.touched.ECfirstName}
              errorname={formik.errors.ECfirstName}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ECmiddleName"
              error
              label="Middle Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("ECmiddleName")}
              touch={formik.touched.ECmiddleName}
              errorname={formik.errors.ECmiddleName}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="EClastName"
              error
              label="Last Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("EClastName")}
              touch={formik.touched.EClastName}
              errorname={formik.errors.EClastName}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="relationship"
              error
              label="Relationship"
              type="text"
              control="textfield"
              {...formik.getFieldProps("relationship")}
              touch={formik.touched.relationship}
              errorname={formik.errors.relationship}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ECphone"
              error
              label="Phone"
              type="text"
              control="textfield"
              {...formik.getFieldProps("ECphone")}
              touch={formik.touched.ECphone}
              errorname={formik.errors.ECphone}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="ECemail"
              error
              label="Email"
              type="email"
              control="textfield"
              {...formik.getFieldProps("ECemail")}
              touch={formik.touched.ECemail}
              errorname={formik.errors.ECemail}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        {employeeId ? (
          <>
            <ConfirmPopUp
              buttonLabel="Update "
              title="Update "
              content=" Please confirm your decision below."
              onConfirm={() => {
                handlesaveUpadateState("update");
                formik.handleSubmit();
              }}
              clickHTML={
                <FormikController
                  name="Update "
                  control="buttons"
                  className="buttonSelectedGreen fixedWidth"
                  fullWidth="false"
                />
              }
            />

            {Admin && (
              <ConfirmPopUp
                buttonLabel="Delete "
                title="Delete Profile"
                content=" Please confirm your decision below."
                onConfirm={() => {
                  // handlesaveUpadateState("update");
                  // formik.handleSubmit();
                  // deleteEmployee(employeeId);
                  GlobelStore.deleteEmployee(
                    employeeId,
                    "Coordinator",
                    navigate
                  );
                }}
                clickHTML={
                  <FormikController
                    name="Delete "
                    control="buttons"
                    className="buttonSelectedRed fixedWidth"
                    fullWidth="false"
                  />
                }
              />
            )}
          </>
        ) : isSaved == true ? (
          ""
        ) : (
          <>
            <ConfirmPopUp
              buttonLabel="Save "
              title="Save "
              content=" Please confirm your decision below."
              onConfirm={() => {
                handlesaveUpadateState("save");
                formik.handleSubmit();
              }}
              clickHTML={
                <FormikController
                  name="Save"
                  control="buttons"
                  className="buttonSelectedGreen fixedWidth"
                  fullWidth="false"
                />
              }
            />
            <FormikController
              name="Reset"
              control="buttons"
              onClick={() => {
                formik.resetForm();
              }}
              className="buttonUnselected fixedWidth"
              fullWidth="false"
            />
          </>
        )}
      </div>
    </>
  );
});

export default CommunityOutReachCoordinatorProfile;
