import React, { useEffect, useMemo } from "react";
import "../CSS/Agency.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Error404 from "../Agency/Pages/Error404";
import AgencyStore from "../Store/AgencyStore";
import ProfileMainViewPage from "./ProfileNurseCaregiver/ProfileMainViewPage";
import DocGovernment from "./Document/DocGovernment";
import DocConfidential from "./Document/DocConfidential";
import DocApplicationAndHiring from "./Document/DocApplicationAndHiring";
import ClientList from "./ClientList/ClientList";
import ClientViewPageNAC from "./ClientList/ClientViewPageNAC";
import Notification from "./Notification/Notification";
import Schedule from "./Schedule/Schedule";

const PagesMain = observer(() => {
  const navigate = useNavigate();

  const checkToken = async () => {
    const result = await AgencyStore.validateLoginToken();
    return result;
  };

  useEffect(() => {
    const redirectToLogin = async () => {
      const isValidToken = await checkToken();

      if (!isValidToken) {
        navigate("/");
        localStorage.clear();
      }
    };

    redirectToLogin();
  }, [navigate]);

  const routes = useMemo(() => {
    return (
      <>
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        {/* <Route path="profile" element={<ProfileMainViewPage />} /> */}
        <Route path="profile" element={<ProfileMainViewPage />} />
        <Route path="GovernmentDocument" element={<DocGovernment />} />
        <Route path="ConfidentialDocument" element={<DocConfidential />} />
        <Route
          path="ApplicationAndHiringDocument"
          element={<DocApplicationAndHiring />}
        />
        <Route path="ClientList" element={<ClientList />} />
        <Route
          path="Client_profile/:clientId"
          element={<ClientViewPageNAC />}
        />
        <Route path="Notification" element={<Notification />} />
        <Route path="Schedule" element={<Schedule />} />
        <Route path="*" element={<Error404 />} />
      </>
    );
  }, []);

  return (
    <div className="paagesMainContainer">
      <Routes>{routes}</Routes>
    </div>
  );
});

export default PagesMain;
