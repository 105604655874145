import React from "react";
import CareGiverListTable from "./CareGiverListTable";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import CareGiverStore from "../../../Store/CareGiverStore";
import PageTitle from "../../PageTitle";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import DownloadClockInOutPopUp from "./CareGiverClockInOut/DownloadClockInOutPopUp";
import { useNavigate } from "react-router-dom";
const CareGiverList = observer(() => {
  const navigate = useNavigate();

  const handleAddCareGiver = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/addCareGiver");
  };

  const [handleSearchChange, handleActiveCheckBox] = useSearchData();

  return (
    <div>
      <div className="actionButtonSearchMainContainer">
        <div className="pageHeaderContainer">
          <PageTitle
            icon={<Icon iconName="menu" />}
            headerTitle="Personal Care Aid"
            subHeaderTitle="Employee/Personal Care Aid"
          />
          <div className="flexContainer">
            {/* <div className="actionButonContainer">
              <DownloadClockInOutPopUp
                // type="docterCaseNote"
                clickHTML={
                  <FormikController
                    name="Download Clock In/Out"
                    control="buttons"
                    className="buttonSelected menuItemButton addCareGiverButton addButton"
                    startIcon={<Icon iconName="groupAdd" />}
                    // onClick={() => handleAddCareGiver()}
                  />
                }
              />
            </div> */}
            <div className="actionButonContainer">
              <FormikController
                name="Add Personal Care Aid"
                control="buttons"
                className="buttonSelected menuItemButton addCareGiverButton addButton"
                startIcon={<Icon iconName="groupAdd" />}
                onClick={() => handleAddCareGiver()}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="actionButtonSearchMainContainer">
          <div className="searchMainCotainer">
            <div className="searchContainer">
              <SearchBox
                handleChange={(e) => {
                  handleSearchChange(e, "CareGiver");
                }}
              />
            </div>
            <div className="activeAddButtonContainer">
              <div>
                <input
                  id="careGiver_active"
                  type="checkbox"
                  onChange={(e) => {
                    handleActiveCheckBox(e, "Active", "CareGiver");
                  }}
                />
                <span className="checkBoxTitle">Active</span>
              </div>
            </div>
          </div>
        </div>
        <CareGiverListTable />
      </div>
    </div>
  );
});

export default CareGiverList;
