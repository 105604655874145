import React, { useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../CSS/AgGrid.css";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom"; // Import the useNavigate hook
import CareGiverStore from "../../../Store/CareGiverStore";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import {
  createColumnDef,
  statusCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";

const NursingAssessmentListTable = observer(() => {
  const navigate = useNavigate();
  const [onGridReady] = useOnGridReady(CareGiverStore, "careGiverAgGrid");
  const { clientId } = useParams();

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/adminProfile/" + employeeId);
    };

    return (
      <div onClick={onFullNameClick} style={{ cursor: "pointer" }}>
        {params.value}
      </div>
    );
  };

  const columnDefs = [
    createColumnDef("fullName", "FullName", leftStyles, fullNameCellRenderer),
    createColumnDef("employeeId", "Id", centerStyles, fullNameCellRenderer),
    createColumnDef("status", "Status", centerStyles, statusCellRenderer),
  ];

  // const getNursingAssessmentListByClient = () => {
  //   AgencyStore.setIsLoading(true);
  //   const params = {
  //     clientId: clientId,
  //     page: 1,
  //     pageSize: 10,
  //   };
  //   axios
  //     .post(ClientURL.GET_NURSINGASSESSMENT_LIST_BY_CLIENT, params, {
  //       headers: { Authorization: localStorage.getItem("loginToken") },
  //     })
  //     .then((res) => {
  //       debugger;
  //       CareGiverStore.setCareGiverList(res?.data?.resultSet);
  //       CareGiverStore.setTableHeight();
  //       AgencyStore.setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       AgencyStore.showAlert("error", "Failed to get care giver list ");
  //       AgencyStore.setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getNursingAssessmentListByClient();
  // }, []);
  return (
    <div id="careGiver_table" className="ag-theme-alpine">
      <FormikController
        // rowData={CareGiverStore.careGiverList}
        control="agGrid"
        columnDefs={columnDefs}
        headerHeight="35"
        rowHeight="35"
        onGridReady={onGridReady}
      />
    </div>
  );
});

export default NursingAssessmentListTable;
