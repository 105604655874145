import { useNavigate } from "react-router-dom";

import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import Draggable from "react-draggable";
import { Paper } from "@mui/material";
import { StatusTypeMap, posTypeMap, roleNames } from "../Constants/Constants";

export const useNavigates = () => {
  const navigate = useNavigate();
  return [navigate];
};

export const setDateInFomat = (inputDateString) => {
  var dateObject = new Date(inputDateString);
  var year = dateObject.getFullYear();
  var month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  var day = dateObject.getDate().toString().padStart(2, "0");
  var outputDateString = year + "-" + month + "-" + day;
  return outputDateString;
};

export const splitName = (name) => {
  const maxLength = 15;
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

const styles = {
  overflow: "linebreak",
  columnWidth: "wrap",
  fontSize: 10,
  cellWidth: "wrap",
  cellPadding: 5,
};

export const handleGeneratePdf = async (props) => {
  const {
    contentElement,
    filename,
    headerText,
    headerAlign,
    textAlign,
    imageData,
    columns,
    rows,
    openInNewWindow,
    columnStyles,
  } = props;

  const pdfOptions = {
    margin: [20, 20, 20, 20],
    filename: filename,
    jsPDF: { unit: "mm", format: "a4" },
    pagebreak: { mode: ["css", "avoid-all", "legacy"] },
  };

  let data = await html2pdf()
    .set(pdfOptions)
    .from(contentElement)
    .toPdf()
    .get("pdf")
    .then((pdf) => {
      var totalPages = pdf.internal.getNumberOfPages();
      var pageHeight =
        pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
      var pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      for (var i = 0; i < totalPages; i++) {
        pdf.setPage(i);
        pdf.text(headerText, pageWidth / 2, pageHeight - 288, {
          align: headerAlign,
        });
      }
      if (rows !== null || columns !== null) {
        pdf.autoTable({
          head: [columns],
          body: rows,
          theme: "grid",
          showHead: "everyPage",
          columnStyles: columnStyles,
          styles: styles,
          margin: { top: 15 },
        });
      }
    })

    .output("blob");
  if (openInNewWindow) {
    const pdfBlobUrl = URL.createObjectURL(data);
    window.open(pdfBlobUrl, "_blank");
  } else {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);
    link.download = filename;
    link.click();
  }
};

export const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export const getRolesName = (item) => {
  const roleName = Object.keys(roleNames).find((role) => item.includes(role));
  return roleName ? roleNames[roleName] : item;
};

export const getStatusTypeName = (label) => {
  var matchingFileType;
  StatusTypeMap.forEach((file) => {
    const key = file.uniqueName;
    if (key == label) {
      matchingFileType = file.displayName;
    }
  });

  return matchingFileType;
};

export const getPosTypeId = (label) => {
  var matchingFileType;
  posTypeMap.forEach((file) => {
    const key = file.displayName;
    if (key == label) {
      matchingFileType = file.uniqueName;
    }
  });

  return matchingFileType;
};

export const getPosTypeName = (label) => {
  var matchingFileType;
  posTypeMap.forEach((file) => {
    const key = file.uniqueName;
    if (key == label) {
      matchingFileType = file.displayName;
    }
  });

  return matchingFileType;
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
