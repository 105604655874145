import React from "react";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import SearchBox from "../../../components/Search/SearchBox";
import CareGiverStore from "../../../Store/CareGiverStore";
import ClientListTable from "./ClientListTable";
import PageTitle from "../../PageTitle";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";

const ClientList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox, handleActiveDropDown] =
    useSearchData();
  const handleAddAdminEmployee = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/add_client");
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Client"
          subHeaderTitle=" Customer/Client"
        />
        <div>
          <div className="actionButonContainer">
            <FormikController
              name="Add Client"
              control="buttons"
              className="buttonSelected menuItemButton addCareGiverButton addButton"
              startIcon={<Icon iconName="groupAdd" />}
              onClick={() => handleAddAdminEmployee()}
            />
          </div>
        </div>
      </div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "Client");
              }}
            />
          </div>
          <div className="activeAddButtonContainer">
            <div>
              <input
                id="careGiver_active"
                type="checkbox"
                onChange={(e) => {
                  handleActiveCheckBox(e, "ACTIVE", "Client");
                }}
              />
              <span className="checkBoxTitle">Active</span>
            </div>
          </div>
        </div>
      </div>
      <ClientListTable customerType="client" />
    </div>
  );
});

export default ClientList;
