import React, { useState } from "react";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import SearchBox from "../../../components/Search/SearchBox";
import CareGiverStore from "../../../Store/CareGiverStore";
import PageTitle from "../../PageTitle";
import useSearchData from "../../../components/useSearchData";
import ClientListTable from "../Client/ClientListTable";
import { leadsStatus, leadsStatusDropDown } from "../../../Constants/Constants";
import Icon from "../../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";

const LeadsList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleAtiveCheckBox, handleActiveDropDown] =
    useSearchData();
  const handleAddAdminEmployee = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/add_leads");
  };

  const [status, setStatus] = useState("Select All");

  const handleDropdownChange = (e) => {
    handleActiveDropDown(e.target.value);
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Leads"
          subHeaderTitle=" Customer/Leads"
        />
        <div>
          <div className="actionButonContainer">
            <FormikController
              name="Add Lead"
              control="buttons"
              className="buttonSelected menuItemButton addCareGiverButton addButton"
              startIcon={<Icon iconName="groupAdd" />}
              onClick={() => handleAddAdminEmployee()}
            />
          </div>
        </div>
      </div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "Client");
              }}
            />
          </div>
          <div className="activeAddButtonContainer">
            <FormikController
              title=""
              control="selects"
              onChange={(e) => {
                const value = e?.target?.value;
                if (value == "Select All") {
                  handleActiveDropDown("");
                } else {
                  handleActiveDropDown(value);
                }
                setStatus(value);
              }}
              selectitem={leadsStatusDropDown}
              style={{ width: 250 }}
              value={status}
            />
          </div>
        </div>
      </div>
      <ClientListTable customerType="leads" />
    </div>
  );
});

export default LeadsList;
