import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react";
import CareGiverStore from "../../Store/CareGiverStore";
import FormikController from "../FormikController/FormikController";
import {
  CareGiverURL,
  ClientURL,
  checkExpiryDate,
  getFileTypeId,
} from "../../Url/Url";
import { useFormik } from "formik";
import * as Yup from "yup";
import AgencyStore from "../../Store/AgencyStore";
import axios from "axios";
import { YupValidation } from "../../Constants/ValidationConstants";
import ClientStore from "../../Store/ClientStore";
import GlobelStore from "../../Store/GlobelStore";
import { PaperComponent } from "../../Utils/Utils";
import "../../CSS/ConfirmPopUp.css";
import { AiOutlineClose } from "react-icons/ai";

const FileUploadPopUp = observer(() => {
  const formik = useFormik({
    initialValues: {
      fileData: "",
      expiryDate: "",
    },
    validationSchema: Yup.object({
      fileData: YupValidation.file_type,
      expiryDate: checkExpiryDate.includes(
        getFileTypeId(CareGiverStore.selectedDoc)
      )
        ? YupValidation.expiryDate
        : YupValidation.expiryDate_without_require,
    }),
    onSubmit: (values) => {
      if (GlobelStore.pageType === "client") {
        saveSingleFileClient(values);
      } else {
        saveSingleFile(values);
      }
    },
  });

  const saveSingleFileClient = async (values) => {
    AgencyStore.setIsLoading(true);

    const clientProfileId = ClientStore.ClientAllData.client.id;

    const params = new FormData();
    if (clientProfileId) {
      params.append("clientProfileId", clientProfileId);
      params.append("title", CareGiverStore.selectedDoc);
      params.append("docUniqueName", getFileTypeId(CareGiverStore.selectedDoc));
      params.append("file", values.fileData);

      if (values.expiryDate) {
        params.append("expiryDate", values.expiryDate);
      }
    }

    await axios
      .post(ClientURL.UPLOAD_CLIENT_DOCUMENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          ClientStore.getClientDocument(clientProfileId);
          AgencyStore.showAlert(
            "success",
            CareGiverStore.selectedDoc + " Saved"
          );
          CareGiverStore.setFileUploadPopUp(false);
          formik.resetForm();
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Fail to save " + CareGiverStore.selectedDoc
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Fail to save " + CareGiverStore.selectedDoc
        );
      });
  };

  const saveSingleFile = async (values) => {
    AgencyStore.setIsLoading(true);

    const employeeProfileID = CareGiverStore.selectedEmloyeeId;
    const params = new FormData();
    if (employeeProfileID) {
      params.append("employeeProfileId", employeeProfileID);
      params.append("document", values.fileData);
      params.append("docUniqueName", getFileTypeId(CareGiverStore.selectedDoc));
      if (values.expiryDate) {
        params.append("docExpiryDate", values.expiryDate);
      }
    }

    await axios
      .post(CareGiverURL.SAVE_SINGLEFIle, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          CareGiverStore.getConfidentialDoc(employeeProfileID);
          AgencyStore.showAlert(
            "success",
            CareGiverStore.selectedDoc + " Saved"
          );
          CareGiverStore.setFileUploadPopUp(false);
          formik.resetForm();
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Fail to save " + CareGiverStore.selectedDoc
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Fail to save " + CareGiverStore.selectedDoc
        );
      });
  };

  const handleClose = () => {
    CareGiverStore.setFileUploadPopUp(false);
    formik.resetForm();
  };
  return (
    <div>
      <div>
        <Dialog
          open={CareGiverStore.fileUploadPopUp}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              Upload File
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>
          <div className="UCPUaction-popup-content">
            <div className="formInputContainer">
              <FormikController
                name="fileData"
                label={CareGiverStore.selectedDoc}
                type="file"
                accept=".pdf"
                control="file"
                onChange={(event) => {
                  formik.setFieldValue(
                    "fileData",
                    event.currentTarget.files[0]
                  );
                }}
                touch={formik.touched.fileData}
                errorname={formik.errors.fileData}
              />
            </div>
            {checkExpiryDate.includes(
              getFileTypeId(CareGiverStore.selectedDoc)
            ) ? (
              <div className="formInputContainer">
                <FormikController
                  name="expiryDate"
                  error
                  label="Expiry Date"
                  type="date"
                  control="textfield"
                  {...formik.getFieldProps("expiryDate")}
                  touch={formik.touched.expiryDate}
                  errorname={formik.errors.expiryDate}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <DialogActions className="DialogActionsContainer">
            <FormikController
              name="Save Changes"
              control="buttons"
              onClick={formik.handleSubmit}
              className="buttonSelectedGreen fixedWidth"
              fullWidth="false"
            />
            <FormikController
              name="Cancel"
              control="buttons"
              onClick={() => {
                CareGiverStore.setFileUploadPopUp(false);
                formik.resetForm();
              }}
              className="buttonUnselected fixedWidth"
              fullWidth="false"
            />
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
});

export default FileUploadPopUp;
