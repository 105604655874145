import { action, makeObservable, observable } from "mobx";
import { CareGiverURL, ClientURL, GlobelURL, LogURL } from "../Url/Url";
import AgencyStore from "./AgencyStore";
import axios from "axios";
import CareGiverStore from "./CareGiverStore";
import AdminEmployeeStore from "./AdminEmployeeStore";
import CommunityCoordinatorStore from "./CommunityCoordinatorStore";
import { Navigate } from "react-router-dom";

class GlobelStore {
  availableRoles = {};
  pageType = "";
  employeeData = {};
  accountAccessPopUpSelectedOn = "";
  nurseList = [];
  nurseAgGrid = {};

  constructor() {
    makeObservable(this, {
      availableRoles: observable,
      employeeData: observable,
      accountAccessPopUpSelectedOn: observable,
      pageType: observable,
      nurseList: observable,
      nurseAgGrid: observable,

      setAvailableRoles: action,
      setEmployeeData: action,
      setAccountAccessPopUpSelectedOn: action,
      setPageType: action,
      setNurseList: action,
      setNurseAgGrid: action,

      getEmployeeList: action,
      checkUserRole: action,
      getChangeLogs: action,
      getClientChangeLogs: action,
      deleteEmployee: action,
      deleteClient: action,
    });
  }

  setAvailableRoles(data) {
    this.availableRoles = data;
  }
  setEmployeeData(data) {
    this.employeeData = data;
  }
  setAccountAccessPopUpSelectedOn(data) {
    this.accountAccessPopUpSelectedOn = data;
  }
  setPageType(data) {
    this.pageType = data;
  }
  setNurseList(data) {
    this.nurseList = data;
  }

  setNurseAgGrid(data) {
    this.nurseAgGrid = data;
  }

  getAvailableRoles = async () => {
    await axios
      .get(GlobelURL.get_AvailableRoles, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          this.setAvailableRoles(res?.data?.resultSet);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Available Roles");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get Available Roles");
      });
  };

  checkUserRole = (requiredRoles) => {
    const storedRoles = localStorage.getItem("roles");
    let storedRolesArray = storedRoles.split(",");
    const userRoles = storedRolesArray ? storedRolesArray : [];
    if (userRoles.includes("Admin")) {
      return "Admin";
    }

    if (userRoles.includes(requiredRoles)) {
      return requiredRoles;
    }
  };

  getEmployeeList = async (employeeType) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeCategory: employeeType,
    };
    await axios
      .post(CareGiverURL.GET_EMLOYEE_LIST, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (employeeType == "CareGiver") {
          CareGiverStore.setCareGiverList(res?.data?.resultSet);
        } else if (employeeType == "AdminEmployee") {
          AdminEmployeeStore.setAdminEmployeeList(res?.data?.resultSet);
        } else if (employeeType == "CommunityCoordinator") {
          CommunityCoordinatorStore.setCommunityCoordinatorList(
            res?.data?.resultSet
          );
        } else if (employeeType == "RN") {
          this.setNurseList(res?.data?.resultSet);
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get employee list ");
        AgencyStore.setIsLoading(false);
      });
  };

  getChangeLogs = async (employeeId) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeProfileId: employeeId,
    };
    await axios
      .post(LogURL.Manual_Employee_Change_Log, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          CareGiverStore.setEmployeeManualChangeLog(res?.data?.resultSet);
        } else {
          AgencyStore.showAlert(
            "error",
            "An error  while retrieving the change log."
          );
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "An error occurred while retrieving the change log."
        );
      });
  };

  getClientChangeLogs = async (employeeId) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientId: employeeId,
      page: 1,
      pageSize: 10,
    };
    await axios
      .post(LogURL.Manual_Client_Change_Log, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          CareGiverStore.setEmployeeManualChangeLog(res?.data?.resultSet);
        } else {
          AgencyStore.showAlert(
            "error",
            "An error  while retrieving the change log."
          );
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "An error occurred while retrieving the change log."
        );
      });
  };

  deleteEmployee = async (employeeId, type, navigateCallback) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeProfileId: employeeId,
      deleteConfirmation: "Delete employee " + employeeId,
    };
    await axios
      .post(CareGiverURL.DELETE_EMP, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.showAlert("success", "Profile successfully deleted");
          let navigatePath;
          switch (type) {
            case "CareGiver":
              navigatePath = "/agency/careGiverList";
              break;
            case "Admin":
              navigatePath = "/agency/adminEmployeeList";
              break;
            case "Nurse":
              navigatePath = "/agency/nurse_List";
              break;
            case "Coordinator":
              navigatePath = "/agency/CommunityOutReachCoordinator";
              break;
            default:
              navigatePath = null;
          }
          if (navigatePath) {
            setTimeout(() => {
              navigateCallback(navigatePath);
            }, 2000); // 2000 milliseconds = 2 seconds
          }
        } else {
          AgencyStore.showAlert(
            "error",
            "An error occurred while deleting Profile"
          );
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "An error occurred while deleting profile."
        );
      });
  };

  deleteClient = async (employeeId, type, navigateCallback) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: employeeId,
      deleteConfirmation: "Delete client " + employeeId,
    };
    await axios
      .post(ClientURL.DELETE_CLIENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.showAlert("success", "Profile successfully deleted");
          let navigatePath;
          switch (type) {
            case "Client":
              navigatePath = "/agency/client_list";
              break;
            case "Leads":
              navigatePath = "/agency/leads_list";
              break;

            default:
              navigatePath = null;
          }
          if (navigatePath) {
            setTimeout(() => {
              navigateCallback(navigatePath);
            }, 2000); // 2000 milliseconds = 2 seconds
          }
        } else {
          AgencyStore.showAlert(
            "error",
            "An error occurred while deleting Profile"
          );
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "An error occurred while deleting profile."
        );
      });
  };
}

export default new GlobelStore();
