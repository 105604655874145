import React, { useEffect, useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../Images/login3.svg";
import headLogo from "../Images/GBHLogo.png";
import "../CSS/login.css";
import FormikController from "../components/FormikController/FormikController";
import { Link } from "react-router-dom";
import { ServerURL } from "../Url/Url";
import axios from "axios";
import AgencyStore from "../Store/AgencyStore";
import { YupValidation } from "../Constants/ValidationConstants";
import { observer } from "mobx-react";
import Icon from "../components/Icons/Icon";
const Login = observer(() => {
  const [show, setshow] = useState(false);
  const [branch, setBranch] = useState([]);
  const handleClick = () => {
    setshow(!show);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      branches: "",
    },
    validationSchema: Yup.object({
      password: YupValidation.password,
      email: YupValidation.email,
      branches: YupValidation.branches,
    }),
    onSubmit: (values) => {
      getLogin(values);
    },
  });

  const getLogin = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      userEmail: values.email,
      userPass: values.password,
      branchId: values.branches,
    };

    await axios
      .post(ServerURL.LOGIN, params)
      .then((res) => {
        if (res?.data?.status === "success") {
          localStorage.setItem("loginToken", res?.data?.loginToken);
          localStorage.setItem("firstName", res?.data?.firstName);
          localStorage.setItem("lastName", res?.data?.lastName);
          localStorage.setItem("middleName", res?.data?.middleName);
          localStorage.setItem("userName", res?.data?.userName);
          localStorage.setItem("userType", res?.data?.userType);
          localStorage.setItem("id", res?.data?.id);
          localStorage.setItem("profileId", res?.data?.profileId);
          localStorage.setItem("roles", res?.data?.roles);
          localStorage.setItem("selectedMenuItem", "dashboard");

          window.location.reload();
          window.location.href = "/agency/dashboard";
          AgencyStore.setIsLoading(false);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Invalid email or password!!");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Invalid email or password!!");
      });
  };

  const getBranch = async () => {
    await axios
      .post(ServerURL.GET_BRANCH)
      .then((res) => {
        var response = res?.data?.resultSet;
        const finalData = response.map((item) => ({
          value: item.id,
          label: item.branchName,
        }));

        setBranch(finalData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBranch();
  }, []);

  return (
    <div className="login">
      <img className="loginImage" src={logo} alt="" loading="lazy" />
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img className="healthLogo" src={headLogo} alt="" loading="lazy" />
            <div className="head">
              <div className="headtitle">Welcome to Home Care! 👋🏻</div>
              <span className="signInText">
                {" "}
                Please sign-in to your account and start the adventure
              </span>
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormikController
              name="email"
              error
              label="Email"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikController
              name="password"
              error
              label="Password"
              control="textfield"
              type={show ? "text" : "password"}
              touch={formik.touched.password}
              errorname={formik.errors.password}
              {...formik.getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={handleClick}>
                    {show ? (
                      <Icon
                        iconName="visible"
                        className={
                          formik.touched.password && formik.errors.password
                            ? "errors"
                            : "icon"
                        }
                      />
                    ) : (
                      <Icon
                        iconName="invisible"
                        className={
                          formik.touched.password && formik.errors.password
                            ? "errors"
                            : "icon"
                        }
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikController
              control="selects"
              title="Branches"
              {...formik.getFieldProps("branches")}
              error
              touch={formik.touched.branches}
              errorname={formik.errors.branches}
              selectitem={branch}
            />
          </Grid>

          <Grid item xs={12}>
            <Link to="/forget-password" className="forget">
              <div className="forgetTitle">Forget password ?</div>
            </Link>
          </Grid>

          <Grid item xs={12}>
            <FormikController
              name="Login"
              control="buttons"
              type="submit"
              onClick={formik.handleSubmit}
              className="custom-button"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

export default Login;
