import React from "react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { observer } from "mobx-react";
import AdminProfile from "./AdminProfile";
import AdminEmployeeGovernment from "./AdminEmployeeGovernment";
import EmployeeApplicationResumeHiring from "./EmployeeApplicationResumeHiring";
import Accordions from "../../../components/Accordion/Accordions";
import AdminEmployeeConfidential from "./AdminEmployeeConfidential";
import useHandleState from "../../../components/useHandleState";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";

const AdminEmployee = observer(() => {
  const { employeeId } = useParams();

  const initialPanelsState = {
    panel1: true,
    panel2: false,
    panel3: false,
    panel4: false,
  };

  const { state, handleChange } = useHandleState(initialPanelsState);
  return (
    <div>
      {employeeId ? (
        ""
      ) : (
        <div className="pageHeaderContainer">
          <PageTitle
            icon={<Icon iconName="menu" />}
            headerTitle="Admin Employee"
            subHeaderTitle=" Employee/Admin Employee/Profile"
          />
        </div>
      )}
      <Accordions
        accordianComponent={<AdminProfile />}
        expanded={state.panel1}
        OnhandleChange={handleChange("panel1")}
        title="Admin Employee Profile"
      />

      {/* {CareGiverStore.isCareGiverProfile === true ? (
        ""
      ) : (
        <>
          {CareGiverStore.isCareGiverSaved === false ? (
            ""
          ) : (
            <>
              <Accordions
                accordianComponent={<AdminEmployeeGovernment />}
                expanded={state.panel2}
                OnhandleChange={handleChange("panel2")}
                title="Government Document"
              />

              <Accordions
                accordianComponent={<AdminEmployeeConfidential />}
                expanded={state.panel3}
                OnhandleChange={handleChange("panel3")}
                title="Confidential Document"
              />

              <Accordions
                accordianComponent={<EmployeeApplicationResumeHiring />}
                expanded={state.panel4}
                OnhandleChange={handleChange("panel4")}
                title="Application and  Hiring Document"
              />
            </>
          )}
        </>
      )} */}
    </div>
  );
});

export default AdminEmployee;
