import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { InputAdornment } from "@mui/material";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import axios from "axios";
import { YupValidation } from "../../../Constants/ValidationConstants";
import GlobelStore from "../../../Store/GlobelStore";
import ClientStore from "../../../Store/ClientStore";
import AdminEmployeeStore from "../../../Store/AdminEmployeeStore";
import { getRolesName } from "../../../Utils/Utils";

const PopUpCareGiverCreateAccount = observer(() => {
  const { employeeId } = GlobelStore?.employeeData?.id
    ? { employeeId: GlobelStore?.employeeData?.id }
    : useParams();
  const { clientId } = GlobelStore?.employeeData?.id
    ? { clientId: GlobelStore?.employeeData?.id }
    : useParams();
  const [roleErrorMessage, setRoleErrorMessage] = useState("");

  const handleCheckboxChange = (item) => {
    if (item === "Admin") {
      if (AdminEmployeeStore.adminRoles.includes("Admin")) {
        // If "Admin" is already selected, remove all roles
        AdminEmployeeStore.setAdminRoles([]);
      } else {
        // If "Admin" is not selected, select all available roles
        AdminEmployeeStore.setAdminRoles(GlobelStore.availableRoles);
      }
    } else {
      if (AdminEmployeeStore.adminRoles.includes(item)) {
        // If the item is already selected, remove it
        const newRoles = AdminEmployeeStore.adminRoles.filter(
          (role) => role !== item
        );
        if (AdminEmployeeStore.adminRoles.includes("Admin")) {
          newRoles.splice(newRoles.indexOf("Admin"), 1);
        }
        AdminEmployeeStore.setAdminRoles(newRoles);
      } else {
        // If the item is not selected, add it
        AdminEmployeeStore.setAdminRoles([
          ...AdminEmployeeStore.adminRoles,
          item,
        ]);
      }
    }
    checkRoles();
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const handleClickConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
      newPassword: YupValidation.password,
      confirmNewPassword: YupValidation.confirmPassword,
    }),
    onSubmit: (values) => {
      if (GlobelStore?.accountAccessPopUpSelectedOn == "client") {
        createClientAccount(values);
      } else {
        createCareGiverAccount(values);
      }
    },
  });

  const setProfileDataFormik = () => {
    const result = GlobelStore?.employeeData;

    formik.setValues({
      email: result?.email || "",
    });
  };

  const checkRoles = () => {
    if (
      GlobelStore?.accountAccessPopUpSelectedOn == "adminEmployee" ||
      GlobelStore?.accountAccessPopUpSelectedOn ==
        "communityOutReachCoordinator"
    ) {
      if (AdminEmployeeStore.adminRoles.length === 0) {
        setRoleErrorMessage("Please select at least one role.");

        AgencyStore.setIsLoading(false);

        return false; // Exit the function early
      } else {
        setRoleErrorMessage("");
        AgencyStore.setIsLoading(false);

        return true;
      }
    }

    if (
      GlobelStore?.accountAccessPopUpSelectedOn == "careGiver" ||
      GlobelStore?.accountAccessPopUpSelectedOn == "client" ||
      GlobelStore?.accountAccessPopUpSelectedOn == "nurse"
    ) {
      setRoleErrorMessage("");
      AgencyStore.setIsLoading(false);

      return true;
    }
  };

  const createCareGiverAccount = (values) => {
    AgencyStore.setIsLoading(true);
    if (checkRoles()) {
      const params = {
        employeeProfileId: employeeId,
        employeeUniqueId: GlobelStore?.employeeData?.employeeId,
        employeePass: values.confirmNewPassword,
        roles: AdminEmployeeStore.adminRoles,
      };

      axios
        .post(CareGiverURL.CREATE_CAREGIVER_ACCOUNT, params, {
          headers: { Authorization: localStorage.getItem("loginToken") },
        })
        .then((res) => {
          if (res?.data?.status == "success") {
            if (GlobelStore?.accountAccessPopUpSelectedOn == "careGiver") {
              GlobelStore.getEmployeeList("CareGiver");
            } else if (
              GlobelStore?.accountAccessPopUpSelectedOn == "adminEmployee"
            ) {
              GlobelStore.getEmployeeList("AdminEmployee");
            } else if (GlobelStore?.accountAccessPopUpSelectedOn == "nurse") {
              GlobelStore.getEmployeeList("RN");
            } else {
              GlobelStore.getEmployeeList("CommunityCoordinator");
            }
            AgencyStore.showAlert("success", "Account successfully created");
          } else {
            AgencyStore.showAlert("error", "Failed to create an account");
          }
          AgencyStore.setIsLoading(false);
        })
        .catch((err) => {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to create an account");
        });
    }
  };

  const createClientAccount = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientProfileId: clientId,
      clientMra: GlobelStore?.employeeData?.mra,
      clientPass: values.confirmNewPassword,
    };
    axios
      .post(ClientURL.CREATE_CLIENT_ACCOUNT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientList();

          AgencyStore.showAlert("success", "Account successfully created");
        } else {
          AgencyStore.showAlert("error", "Failed to create an account");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to create an account");
      });
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, clientId]);
  return (
    <div>
      <div className="securityMainContainer">
        <div className="CGSHeader">Create an account</div>
        <div
          className={
            GlobelStore.accountAccessPopUpSelectedOn === "careGiver" ||
            GlobelStore.accountAccessPopUpSelectedOn === "nurse" ||
            GlobelStore.accountAccessPopUpSelectedOn === "client"
              ? ""
              : "formAndRolesContainer"
          }
        >
          <div className="changePasswordFormMainContainer">
            <div className="formMainContainerForPopUp">
              <div>
                <FormikController
                  name="email"
                  error
                  label="Email"
                  type="email"
                  control="textfield"
                  {...formik.getFieldProps("email")}
                  touch={formik.touched.email}
                  errorname={formik.errors.email}
                  disabled="true"
                />
              </div>
            </div>
            <div className="formMainContainerForPopUp">
              <div>
                <FormikController
                  name="newPassword"
                  error
                  label="New Password"
                  control="textfield"
                  type={showNewPassword ? "text" : "password"}
                  touch={formik.touched.newPassword}
                  errorname={formik.errors.newPassword}
                  {...formik.getFieldProps("newPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleClickNewPassword}
                      >
                        {showNewPassword ? (
                          <MdOutlineVisibility
                            className={
                              formik.touched.newPassword &&
                              formik.errors.newPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className={
                              formik.touched.newPassword &&
                              formik.errors.newPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <FormikController
                  name="confirmNewPassword"
                  error
                  label="Confirm New Password"
                  control="textfield"
                  type={showConfirmNewPassword ? "text" : "password"}
                  touch={formik.touched.confirmNewPassword}
                  errorname={formik.errors.confirmNewPassword}
                  {...formik.getFieldProps("confirmNewPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleClickConfirmNewPassword}
                      >
                        {showConfirmNewPassword ? (
                          <MdOutlineVisibility
                            className={
                              formik.touched.confirmNewPassword &&
                              formik.errors.confirmNewPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className={
                              formik.touched.confirmNewPassword &&
                              formik.errors.confirmNewPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          {GlobelStore.accountAccessPopUpSelectedOn === "careGiver" ||
          GlobelStore.accountAccessPopUpSelectedOn === "nurse" ||
          GlobelStore.accountAccessPopUpSelectedOn === "client" ? (
            ""
          ) : (
            <div className="rolesContainer backgroundcolorLightDark">
              <div className="roleSpamMsgConatiner">
                <div className="title">Roles</div>
                <span className="errorMessage">{roleErrorMessage}</span>
              </div>

              {GlobelStore.availableRoles.map((item, index) => (
                <div key={index} className="roleItem">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={AdminEmployeeStore.adminRoles.includes(item)}
                  />
                  <span>{getRolesName(item)}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="changePasswordInfoMainContainer backgroundcolorLightDark">
          <div className="changePasswordTitle">Password Requirements:</div>
          <div className="changePasswordSubTitle">
            <li>Minimum 8 characters long - the more, the better</li>
            <li>At least one lowercase character</li>
            <li>At least one number, symbol, or whitespace character</li>
          </div>
        </div>

        <div className="changePasswordButtonContainer">
          <FormikController
            name="Create Account"
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelected fixedWidth"
            fullWidth="false"
          />
        </div>
      </div>
    </div>
  );
});

export default PopUpCareGiverCreateAccount;
