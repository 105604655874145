import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "../../../CSS/Calendar.css";
import "../../../CSS/FullCalenderEvents.css";
import { observer } from "mobx-react";
import CalenderStore from "../../../Store/CalenderStore";
import ClientStore from "../../../Store/ClientStore";
import AgencyStore from "../../../Store/AgencyStore";

const FullcalendarEvent = () => {
  const [events, setEvents] = useState([]);
  const handleEventClick = (info) => {
    CalenderStore.setSpecificCalendarAllData(
      info.event.extendedProps.customData
    );
    ClientStore.getEmployeeClientRelation(
      info.event.extendedProps.customData.employeeProfileId
    );
    CalenderStore.setIsSwipeableTemporaryDrawer("update");
    CalenderStore.setOpenDrawer(true);
  };

  useEffect(() => {
    const calenderData = CalenderStore?.specificEmployeeCalendar?.resultSet;

    if (calenderData) {
      const calenderDataFinalData = calenderData.map((item) => ({
        title: item.title,
        start: item.dateTime,
        end: item.dateTime,
        customData: item,
      }));
      setEvents(calenderDataFinalData);
    }
  }, [
    CalenderStore?.specificEmployeeCalendar?.resultSet,
    AgencyStore.isLeftContainerVisible,
  ]);

  return (
    <FullCalendar
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridDay,listMonth",
      }}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      events={events}
      eventClick={handleEventClick}
      dayMaxEvents={true}
      views={{
        timeGrid: {
          dayMaxEvents: 2,
        },
      }}
      eventContent={(eventInfo) => {
        console.log(eventInfo);
        const customData = eventInfo.event.extendedProps.customData;
        const isExpired = new Date(customData.dateTime) < new Date();
        const isUpcoming = new Date(customData.dateTime) > new Date();

        const eventClasses = ["event-container"];
        if (isExpired || customData.status == "CANCLED") {
          eventClasses.push("expired-event");
        } else if (isUpcoming) {
          eventClasses.push("upcoming-event-caregiver");
        }

        return (
          <div className={eventClasses.join(" ")}>
            <div className="event-title">
              {eventInfo.event.title +
                " (" +
                customData.clientLastName +
                " " +
                customData.clientFirstName +
                ")"}
            </div>
          </div>
        );
      }}
    />
  );
};

export default observer(FullcalendarEvent);
