import React, { useEffect, useMemo } from "react";
import "../CSS/TopNavBar.css";
import ProfileSvg from "../Images/profile.svg";
import { observer } from "mobx-react";
import AgencyStore from "../Store/AgencyStore";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Divider } from "@mui/material";
import { IoIosLogOut } from "react-icons/io";
import { IoPersonOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerURL } from "../Url/Url";
import ConfirmPopUp from "../components/ConfirmPopUp/ConfirmPopUp";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaCircle } from "react-icons/fa6";
import NotificationStore from "../Store/NotificationStore";
import GlobelStore from "../Store/GlobelStore";
import Icon from "../components/Icons/Icon";
const TopNavBar = observer(() => {
  const navigate = useNavigate();

  const toggleLeftContainer = () => {
    AgencyStore.setIsLeftContainerVisible(!AgencyStore.isLeftContainerVisible);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemsClicked = (data) => {
    AgencyStore.setSelectedMenuItem(data);
  };

  const handleLogout = async () => {
    await axios
      .post(
        ServerURL.LOGOUT,
        {},
        {
          headers: { Authorization: localStorage.getItem("loginToken") },
        }
      )
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          navigate("/");
          AgencyStore.showAlert("success", "Logout successfully");
          localStorage.clear();
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Logout");
      });
  };

  const fullName =
    localStorage.getItem("lastName") + " " + localStorage.getItem("firstName");
  const userType = localStorage.getItem("userType");
  const notifications = useMemo(
    () => GlobelStore.checkUserRole("Notifications"),
    []
  );
  useEffect(() => {
    if (notifications) {
      NotificationStore.getEmpClientUnseenNotificationCount();
    }
  }, []);
  return (
    <div className="topNavMainContainer">
      <div className="topNavLeftDiv" onClick={toggleLeftContainer}>
        <Icon iconName="menu" className="hambugerIcon" />
      </div>

      <div className="topNavRightDiv">
        {/* <NotificationPopUp
          type="careGiver"
          onConfirm={() => {}}
          clickHTML={
            <Badge
              badgeContent={
                NotificationStore?.notificationUnseenCount?.totalCount
              }
              color="primary"
            >
              <NotificationsIcon />
            </Badge>
          }
        /> */}

        <div>
          <div
            className="topNavBarImgNameContainer"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img className="profileImage" src={ProfileSvg} alt="" />
            <span>{fullName}</span>
            <IoMdArrowDropdown className="topNavBarIcon" />
          </div>
          <Menu
            className="profileDropDown"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div className="profilePopupContainer">
              <div className="profilePopupMainContainer">
                <img className="profileImage" src={ProfileSvg} alt="" />
              </div>
              <div className="profilePopupTitleMainContainer">
                <div className="profileNameText">{fullName}</div>
                <div className="profileTypeText">
                  <FaCircle className="onlineIcon" />
                  {userType}
                </div>
              </div>
            </div>
            <Divider />
            <div className="mennItemsPopupMainContainer">
              <Link to="/agency/profile">
                <Button
                  className={
                    AgencyStore.selectedMenuItem === "profile"
                      ? "profilePopupButtonsSelected profilePopupButtons"
                      : "profilePopupButtonsUnSelected profilePopupButtons"
                  }
                  variant="contained"
                  fullWidth
                  startIcon={<IoPersonOutline className="iconSize" />}
                  onClick={() => handleMenuItemsClicked("profile")}
                >
                  <span className="capitalized-text">Profile</span>
                </Button>
              </Link>
            </div>
            <Divider />
            <div className="mennItemsPopupMainContainer">
              <ConfirmPopUp
                content="Are you sure you want to logout ?"
                onConfirm={() => {
                  handleLogout();
                }}
                clickHTML={
                  <Button
                    className="logoutButton profilePopupButtons"
                    variant="contained"
                    fullWidth
                    startIcon={<IoIosLogOut className="iconSize" />}
                  >
                    <div className="capitalized-text">Logout</div>
                  </Button>
                }
              />
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
});

export default TopNavBar;
