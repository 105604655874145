import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";
import GlobelStore from "../../../Store/GlobelStore";
import ActionPopUp from "./ActionPopUp";
import { FaCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import SearchBox from "../../../components/Search/SearchBox";
import FormikController from "../../../components/FormikController/FormikController";
import useSearchData from "../../../components/useSearchData";
import { useOnGridReady } from "../../../Utils/AgGridUtils";
import { useNavigate } from "react-router-dom";
const CommunityOutReachCoordinatorList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    CommunityCoordinatorStore,
    "communityCoordinatorAgGrid"
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      navigate("/agency/communityOutReachCoordinatorProfile/" + employeeId);
    };
    return (
      <div onClick={onFullNameClick} class="ag-inner-cell" title={params.value}>
        {params.value}
      </div>
    );
  };
  const columnDefs = useMemo(
    () => [
      {
        field: "employeeId",
        headerName: "#ID",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "fullName",
        headerName: "FullName",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "status",
        headerName: "Status",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{ color: params.value == "Active" ? "green" : "red" }}
            >
              {params.value}
            </div>
          );
        },
      },
      {
        field: "isAccountCreated",
        headerName: "Account Created",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{
                fontSize: "16px",
                color: params.value == true ? "green" : "red",
              }}
            >
              {params.value ? <FaCheck /> : <MdClose />}
            </div>
          );
        },
      },
      {
        field: "isAccountEnable",
        headerName: "Account Status",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{ color: params.value == true ? "green" : "red" }}
            >
              {params.value ? "Activate" : "Deactivate"}
            </div>
          );
        },
      },
      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            CareGiverStore.setIsCareGiverAccountCreated(params.data.userId);
            GlobelStore.setEmployeeData(params.data);
            GlobelStore.setAccountAccessPopUpSelectedOn(
              "communityOutReachCoordinator"
            );
          };

          return (
            <div
              className="ag-inner-cell"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ActionPopUp
                type="careGiver"
                onConfirm={() => {}}
                clickHTML={
                  <div className="manageClick" onClick={handleButtonClick}>
                    Manage
                  </div>
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "CommunityCoordinator");
              }}
            />
          </div>
          <div>
            <input
              id="careGiver_active"
              type="checkbox"
              onChange={(e) => {
                handleActiveCheckBox(e, "Active", "CommunityCoordinator");
              }}
            />{" "}
            <span className="checkBoxTitle">Active</span>
          </div>
        </div>
      </div>

      <FormikController
        rowData={CommunityCoordinatorStore.communityCoordinatorList}
        control="agGrid"
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default CommunityOutReachCoordinatorList;
