import React, { useRef, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import CareGiverStore from "../../../../Store/CareGiverStore";
import { leftStyles, centerStyles } from "../../../../Constants/Constants";
import {
  createColumnDef,
  statusCellRenderer,
  useOnGridReady,
} from "../../../../Utils/AgGridUtils";
import FormikController from "../../../../components/FormikController/FormikController";
import { useNavigate } from "react-router-dom";
import DownloadClockInOutPopUp from "./DownloadClockInOutPopUp";
import AddClockInOut from "./AddClockInOut";
import GlobelStore from "../../../../Store/GlobelStore";

const CareGiverClockInOutTable = observer(() => {
  const navigate = useNavigate();

  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    CareGiverStore,
    "careGiverAgGrid"
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const id = params.data.clientProfileId;
      CareGiverStore.setIsCareGiverProfile(true);
      navigate("/agency/Client_profile/" + id);
    };

    return (
      <div onClick={onFullNameClick} className="ag-inner-cell">
        {params.value}
      </div>
    );
  };

  // const [columnDefs] = useState([
  //   createColumnDef(
  //     "clientName",
  //     "#Id",
  //     leftStyles,
  //     fullNameCellRenderer,
  //     "",
  //     60
  //   ),
  //   createColumnDef("fullName", "FullName", leftStyles, fullNameCellRenderer),
  //   createColumnDef(
  //     "status",
  //     "Status",
  //     centerStyles,
  //     statusCellRenderer,
  //     "center-header"
  //   ),
  // ]);

  // useEffect(() => {
  //   GlobelStore.getEmployeeList("CareGiver");
  // }, []);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        width: 100,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: (params) => params.node.rowIndex + 1,
      },
      {
        field: "clientName",
        headerName: "Clinet Name",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "date",
        headerName: "Date",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        field: "clockInTime",
        headerName: "Clock In Time",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        field: "clockOutTime",
        headerName: "Clock Out Time",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        field: "workedHrs",
        headerName: "Worked Hours",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            // CareGiverStore.setIsCareGiverAccountCreated(params.data.userId);
            GlobelStore.setEmployeeData(params.data);
            // GlobelStore.setAccountAccessPopUpSelectedOn("careGiver");
          };

          return (
            <div
              className="ag-inner-cell"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddClockInOut
                type="update"
                clickHTML={
                  <div
                    className="manageClick"
                    onClick={() => handleButtonClick()}
                  >
                    Update
                  </div>
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <FormikController
      control="agGrid"
      rowData={CareGiverStore.clockInOutAlldata?.resultSet}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
});

export default CareGiverClockInOutTable;
