import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import { observer } from "mobx-react";
import FormikController from "../../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import AgencyStore from "../../../../Store/AgencyStore";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ClientURL } from "../../../../Url/Url";
import ClientStore from "../../../../Store/ClientStore";
import ClientDocterInfo from "../../../../components/FormsInvoice/ClientDocterInfo/ClientDocterInfo";
import { ImFilePdf } from "react-icons/im";
import { SlPrinter } from "react-icons/sl";
import { RxUpdate } from "react-icons/rx";
import { DialogActions } from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DocterPopUp = ({ onConfirm, clickHTML, type }) => {
  const { clientId } = useParams();

  const [open, setOpen] = useState(false);
  const [saveUpadateState, setSaveUpadateState] = useState("");
  const contentElementRef = useRef();

  const handlesaveUpadateState = (value) => {
    setSaveUpadateState(value);
  };

  const formik = useFormik({
    initialValues: {
      doctor: "",
      phoneNo: "",
      address: "",
    },

    validationSchema: Yup.object({
      doctor: YupValidation.doctor,
      phoneNo: YupValidation.phone,
      address: YupValidation.address,
    }),
    onSubmit: (values) => {
      if (saveUpadateState == "save") {
        saveDoctorInfo(values);
      } else if (saveUpadateState == "update") {
        updateDoctorInfo(values);
      }
    },
  });

  const saveDoctorInfo = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientId: clientId,
      doctor: values.doctor,
      phoneNo: values.phoneNo,
      address: values.address,
    };

    axios
      .post(ClientURL.SAVE_DOCTOR_INFO, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
          ClientStore.getDoctorInfo(clientId);
          handleClose();
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Docter information");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Docter information");
      });
  };

  const updateDoctorInfo = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: ClientStore.individualDocterInfoData.id,
      clientId: clientId,
      doctor: values.doctor,
      phoneNo: values.phoneNo,
      address: values.address,
    };

    axios

      .post(ClientURL.UPDATE_DOCTOR_INFO, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          ClientStore.getDoctorInfo(clientId);
          handleClose();
          AgencyStore.showAlert("success", "successfully Updated");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Care Giver Profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to update Care Giver Profile");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    // ClientStore.setIndividualDocterInfoData({});
  };

  const setProfileDataFormik = (data) => {
    const result = ClientStore.individualDocterInfoData;

    formik.setValues({
      doctor: result?.doctor || "",
      phoneNo: result?.phoneNo || "",
      address: result?.address || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [ClientStore.individualDocterInfoData]);

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="accountAndAccessDialog"
      >
        <div className="borderBottom">
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              Doctor Information
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>
          <div className="UCPUaction-popup-content">
            <div className="ClientDocterMainConatiner">
              <div className="formInputContainer">
                <FormikController
                  name="doctor"
                  error
                  label="Doctor"
                  type="text"
                  control="textfield"
                  {...formik.getFieldProps("doctor")}
                  touch={formik.touched.doctor}
                  errorname={formik.errors.doctor}
                />
              </div>

              <div>
                <div className="formInputContainer">
                  <FormikController
                    name="phoneNo"
                    error
                    label="Phone"
                    type="text"
                    control="textfield"
                    {...formik.getFieldProps("phoneNo")}
                    touch={formik.touched.phoneNo}
                    errorname={formik.errors.phoneNo}
                  />
                </div>
              </div>
              <div>
                <div className="formInputContainer">
                  <FormikController
                    name="address"
                    error
                    label="Address"
                    type="text"
                    control="textfield"
                    {...formik.getFieldProps("address")}
                    touch={formik.touched.address}
                    errorname={formik.errors.address}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActions className="DialogActionsContainer">
          {type == "update" ? (
            <>
              <div>
                <FormikController
                  name="Update Doctor Info"
                  control="buttons"
                  onClick={() => {
                    handlesaveUpadateState("update");
                    formik.handleSubmit();
                  }}
                  className="buttonSelectedGreen fixedWidth"
                  fullWidth="false"
                />
              </div>
            </>
          ) : (
            <div>
              <FormikController
                name="Save Doctor Info"
                control="buttons"
                onClick={() => {
                  handlesaveUpadateState("save");
                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen fixedWidth"
                fullWidth="false"
              />
            </div>
          )}
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(DocterPopUp);
