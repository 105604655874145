import { action, makeObservable, observable } from "mobx";

import { CalenderUrl, ClientURL } from "../Url/Url";
import axios from "axios";
import AgencyStore from "./AgencyStore";
import GlobelStore from "./GlobelStore";
import CareGiverStore from "./CareGiverStore";

class ClientStore {
  ClientAllData = {};
  docterInfoAllData = {};
  individualDocterInfoData = {};

  clientAllDocList = {};
  docterCaseNoteAllData = [];
  clientCaseNoteAllData = [];
  clientCalendarAllData = [];
  employeeClientRelationAllData = [];
  clientEmployeeRelationData = [];
  individualDocterCaseNoteData = {};
  isClient = false;
  careGiverList = [];
  leadList = [];
  clientAgGrid = {};
  posDocAllData = [];
  medicationRecordList = [];

  clientAllNursingAssessmentDocs = {};
  clientAllMedicationRecordsDocs = {};
  clientAllHomeSafetyDocs = {};
  clientAllClientCarePlanDocs = {};
  clientAllCareGiverEvaluationFormDocs = {};
  clientAllCareGiverPlanOfServiceDocs = {};
  clientAllCompetencyChecklistPersonalCareAideDocs = {};
  clientAllParticipantReviewAndCareGiverSupervisoryVisitDocs = {};

  fileUploadPopUp = false;
  caseNotestatus = "";
  constructor() {
    makeObservable(this, {
      isClient: observable,
      ClientAllData: observable,
      docterInfoAllData: observable,
      individualDocterInfoData: observable,
      clientAllDocList: observable,
      docterCaseNoteAllData: observable,
      clientCaseNoteAllData: observable,
      clientCalendarAllData: observable,
      individualDocterCaseNoteData: observable,
      careGiverList: observable,
      posDocAllData: observable,
      medicationRecordList: observable,
      leadList: observable,

      clientAllNursingAssessmentDocs: observable,
      clientAllMedicationRecordsDocs: observable,
      clientAllHomeSafetyDocs: observable,
      clientAllClientCarePlanDocs: observable,
      clientAllCareGiverEvaluationFormDocs: observable,
      clientAllCareGiverPlanOfServiceDocs: observable,
      clientAllCompetencyChecklistPersonalCareAideDocs: observable,
      clientAllParticipantReviewAndCareGiverSupervisoryVisitDocs: observable,

      fileUploadPopUp: observable,
      employeeClientRelationAllData: observable,
      clientEmployeeRelationData: observable,
      caseNotestatus: observable,
      clientAgGrid: observable,

      setIsClient: action,
      setClientAllData: action,
      setDocterInfoAllData: action,
      setIndividualDocterInfoData: action,

      setDocterCaseNoteAllData: action,
      setClientCaseNoteAllData: action,
      setPosDocAllData: action,
      setIndividualDocterCaseNoteData: action,
      setCareGiverList: action,
      setLeadList: action,
      setMedicationRecordList: action,

      setClientAllNursingAssessmentDocs: action,
      setClientAllMedicationRecordsDocs: action,
      setClientAllHomeSafetyDocs: action,
      setClientAllClientCarePlanDocs: action,
      setClientAllCareGiverEvaluationFormDocs: action,
      setClientAllCareGiverPlanOfServiceDocs: action,
      setClientAllCompetencyChecklistPersonalCareAideDocs: action,
      setClientAllParticipantReviewAndCareGiverSupervisoryVisitDocs: action,

      setFileUploadPopUp: action,
      setEmployeeClientRelationAllData: action,
      setClientEmployeeRelationData: action,
      setClientCalendarAllData: action,
      setCaseNotestatus: action,
      setClientAgGrid: action,

      getClientList: action,
      getLeadsList: action,
      getClientProfile: action,
      getDoctorInfo: action,
      getClientDocument: action,
      getDoctorCaseNotes: action,
      getClientProfileCaseNotes: action,
      getEmployeeClientRelation: action,
      getClientCalendar: action,
      getPosDoc: action,
      getClientSignature: action,
    });
  }

  setIsClient(data) {
    this.isClient = data;
  }
  setCaseNotestatus(data) {
    this.caseNotestatus = data;
  }
  setEmployeeClientRelationAllData(data) {
    this.employeeClientRelationAllData = data;
  }

  setPosDocAllData(data) {
    this.posDocAllData = data;
  }
  setClientCalendarAllData(data) {
    this.clientCalendarAllData = data;
  }
  setClientAgGrid(data) {
    this.clientAgGrid = data;
  }

  setClientAllData(data) {
    this.ClientAllData = data;
  }
  setDocterInfoAllData(data) {
    this.docterInfoAllData = data;
  }
  setIndividualDocterInfoData(data) {
    this.individualDocterInfoData = data;
  }
  setDocterCaseNoteAllData(data) {
    this.docterCaseNoteAllData = data;
  }

  setClientCaseNoteAllData(data) {
    this.clientCaseNoteAllData = data;
  }
  setMedicationRecordList(data) {
    this.medicationRecordList = data;
  }

  setClientAllDocList(data) {
    this.clientAllDocList = data;
  }
  setIndividualDocterCaseNoteData(data) {
    this.individualDocterCaseNoteData = data;
  }
  setCareGiverList(data) {
    this.careGiverList = data;
  }
  setLeadList(data) {
    this.leadList = data;
  }

  setClientAllNursingAssessmentDocs(data) {
    this.clientAllNursingAssessmentDocs = data;
  }
  setClientAllMedicationRecordsDocs(data) {
    this.clientAllMedicationRecordsDocs = data;
  }
  setClientAllHomeSafetyDocs(data) {
    this.clientAllHomeSafetyDocs = data;
  }
  setClientAllClientCarePlanDocs(data) {
    this.clientAllClientCarePlanDocs = data;
  }
  setClientAllCareGiverEvaluationFormDocs(data) {
    this.clientAllCareGiverEvaluationFormDocs = data;
  }
  setClientAllCareGiverPlanOfServiceDocs(data) {
    this.clientAllCareGiverPlanOfServiceDocs = data;
  }
  setClientAllCompetencyChecklistPersonalCareAideDocs(data) {
    this.clientAllCompetencyChecklistPersonalCareAideDocs = data;
  }
  setClientAllParticipantReviewAndCareGiverSupervisoryVisitDocs(data) {
    this.clientAllParticipantReviewAndCareGiverSupervisoryVisitDocs = data;
  }
  setClientEmployeeRelationData(data) {
    this.clientEmployeeRelationData = data;
  }

  setFileUploadPopUp(data) {
    this.fileUploadPopUp = data;
  }

  getClientList = async () => {
    AgencyStore.setIsLoading(true);
    await axios
      .get(ClientURL.GET_CLIENT_PROFILE_LIST, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setCareGiverList(res?.data?.resultSet);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get client list ");
        AgencyStore.setIsLoading(false);
      });
  };

  getLeadsList = async () => {
    AgencyStore.setIsLoading(true);
    await axios
      .get(ClientURL.GET_LEADS_PROFILE_LIST, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setLeadList(res?.data?.resultSet);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get client list ");
        AgencyStore.setIsLoading(false);
      });
  };

  getClientProfile = async (ClientId) => {
    const params = { clientId: ClientId };
    await axios
      .post(ClientURL.GET_CLIENT_PROFILE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          this.setClientAllData(res?.data);
          GlobelStore.setPageType("client");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Client profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get Client profile");
      });
  };

  getDoctorInfo = async (ClientId) => {
    const params = { clientId: ClientId };
    await axios
      .post(ClientURL.GET_DOCTOR_INFO, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status === 200) {
          this.setDocterInfoAllData(res?.data);
        } else {
          this.setDocterInfoAllData({});
          AgencyStore.setIsLoading(false);
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Docter information");
      });
  };

  getDoctorCaseNotes = async (ClientId) => {
    const params = { clientId: ClientId };
    await axios
      .post(ClientURL.GET_DOCTOR_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status === "success") {
          this.setDocterCaseNoteAllData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Docter Case note");
      });
  };

  getClientProfileCaseNotes = async (clientProfileId) => {
    await axios
      .get(ClientURL.GET_CLIENT_CASENOTE + "/" + clientProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          this.setClientCaseNoteAllData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Docter Case note");
      });
  };

  getClientDocument = async (employeeId) => {
    const params = {
      clientId: employeeId,
      documentUniqueNames: [
        "idCard",
        "wavier",
        "socialSecurity",
        "mA",
        "clientInfoPackage",
      ],
    };
    await axios
      .post(ClientURL.GET_Client_Document, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status == 200) {
          this.setClientAllDocList(res?.data);
        } else {
          AgencyStore.showAlert("error", "Failed to get document");
        }
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get document");
      });
  };

  getNursingAssessmentDocuments = async (employeeId, assessmentDocType) => {
    const params = {
      clientProfileId: employeeId,
      page: 1,
      pageSize: 1000,
      assessmentDocType: assessmentDocType,
    };
    await axios
      .post(ClientURL.GET_NURSINGASSESSMENT_DOCUMENTS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status == 200) {
          if (assessmentDocType == "NURSING_ASSESSMENT") {
            this.setClientAllNursingAssessmentDocs(res?.data);
          } else if (assessmentDocType == "MEDICATION_RECORDS_LIST") {
            this.setClientAllMedicationRecordsDocs(res?.data);
          } else if (assessmentDocType == "HOME_SAFETY") {
            this.setClientAllHomeSafetyDocs(res?.data);
          } else if (assessmentDocType == "CLIENT_CARE_PLAN") {
            this.setClientAllClientCarePlanDocs(res?.data);
          } else if (assessmentDocType == "CAREGIVER_EVALUATION_FORM") {
            this.setClientAllCareGiverEvaluationFormDocs(res?.data);
          } else if (assessmentDocType == "CAREGIVER_PLAN_OF_SERVICE") {
            this.setClientAllCareGiverPlanOfServiceDocs(res?.data);
          } else if (
            assessmentDocType == "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
          ) {
            this.setClientAllCompetencyChecklistPersonalCareAideDocs(res?.data);
          } else if (
            assessmentDocType ==
            "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
          ) {
            this.setClientAllParticipantReviewAndCareGiverSupervisoryVisitDocs(
              res?.data
            );
          }
        } else {
          AgencyStore.showAlert(
            "error",
            "Failed to Get Nursing Assessment document"
          );
        }
      })
      .catch((err) => {
        AgencyStore.showAlert(
          "error",
          "Failed to Get Nursing Assessment document"
        );
      });
  };

  getEmployeeClientRelation = async (employeeProfileId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(ClientURL.GET_CLIENT_RELATION + "/" + employeeProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary.status === "success") {
          this.setEmployeeClientRelationAllData(res?.data?.resultSet);
          AgencyStore.setIsLoading(false);
        } else if (res?.data?.summary.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fatch data ");
      });
  };

  getClientEmployeeRelation = async (employeeProfileId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(ClientURL.GET_CLIENT_Emp_RELATION + "/" + employeeProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary.status === "success") {
          this.setClientEmployeeRelationData(res?.data?.resultSet);
          AgencyStore.setIsLoading(false);
        } else if (res?.data?.summary.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fetch data ");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fetch data ");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fatch data ");
      });
  };

  getClientCalendar = async (clientProfileId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(CalenderUrl.GET_SPECIFIC_CLIENT_CALENDER + "/" + clientProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary.status === "success") {
          this.setClientCalendarAllData(res?.data);
          AgencyStore.setIsLoading(false);
        } else if (res?.data?.summary.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fatch data ");
      });
  };

  getPosDoc = async (clientProfileId) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: clientProfileId,
      page: 1,
      pageSize: 1000,
      uniqueNames: [
        "pos",
        "serviceNotificationForm",
        "emgHourIsasCommunication",
      ],
    };
    await axios
      .post(ClientURL.GET_POS_DOC, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status === 200) {
          this.setPosDocAllData(res?.data);
          AgencyStore.setIsLoading(false);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fatch data ");
      });
  };

  getClientSignature = async (clientProfileId) => {
    await axios
      .get(ClientURL.GET_CLIENT_SIGNATURE + "/" + clientProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.setEmpSignatureAllData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to fetch employee signature details"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to fetch employee signature details"
        );
      });
  };
}

export default new ClientStore();
