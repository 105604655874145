import React, { useEffect } from "react";
import "../../../CSS/Calendar.css";
import FormikController from "../../../components/FormikController/FormikController";
import FullcalendarEvent from "./FullcalendarEvent";
import "react-calendar/dist/Calendar.css";
import CalenderStore from "../../../Store/CalenderStore";
import Icon from "../../../components/Icons/Icon";
const Calendars = () => {
  useEffect(() => {
    CalenderStore.getEmployeeCalendar("careGiver");
  }, []);
  return (
    <div className="calendarContainer">
      <div className="calendarSection">
        <div className="leftCalendar">
          <FormikController
            name="Add Schedule"
            control="buttons"
            className="custom-buttons"
            startIcon={<Icon iconName="add" />}
          />
        </div>
        <div className="rightCalendar">
          <FullcalendarEvent />
        </div>
      </div>
    </div>
  );
};

export default Calendars;
