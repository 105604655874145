import React from "react";
import { observer } from "mobx-react";
import CommunityOutReachCoordinatorProfile from "./CommunityOutReachCoordinatorProfile";
import Accordions from "../../../components/Accordion/Accordions";
import useHandleState from "../../../components/useHandleState";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";
const addCommunityOutReachCoordinator = observer(() => {
  const { employeeId } = useParams();

  const initialPanelsState = {
    panel1: true,
  };
  const { state, handleChange } = useHandleState(initialPanelsState);

  return (
    <div>
      {employeeId ? (
        ""
      ) : (
        <div className="pageHeaderContainer">
          <PageTitle
            icon={<Icon iconName="menu" />}
            headerTitle=" Community Outreach Coordinator"
            subHeaderTitle="  Employee/Community Outreach Coordinator/Profile"
          />
        </div>
      )}
      <Accordions
        accordianComponent={<CommunityOutReachCoordinatorProfile />}
        expanded={state.panel1}
        OnhandleChange={handleChange("panel1")}
        title="Community Outreach Coordinator Profile"
      />
    </div>
  );
});

export default addCommunityOutReachCoordinator;
