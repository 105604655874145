import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

const Checkboxes = (props) => {
  const { handleCheckboxChange, ...otherProps } = props;
  return (
    <FormGroup {...otherProps}>
      <FormControlLabel
        control={<Checkbox onChange={handleCheckboxChange} />}
        label={otherProps.label}
      />
    </FormGroup>
  );
};

export default Checkboxes;
