import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "../../../CSS/Schedule.css";
import FormikController from "../../../components/FormikController/FormikController";
import CloseIcon from "@mui/icons-material/Close";
import ClientStore from "../../../Store/ClientStore";
import * as Yup from "yup";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { useFormik } from "formik";
import CareGiverStore from "../../../Store/CareGiverStore";
import { CalenderUrl } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import { format } from "date-fns";
import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react";
import CalenderStore from "../../../Store/CalenderStore";
import { AiOutlineClose } from "react-icons/ai";

const SwipeableTemporaryDrawer = () => {
  const [clientList, setClientList] = useState([]);
  const [employeeFullName, setEmployeeFullName] = useState("");
  const [clientFullName, setClientFullName] = useState("");
  const [careGiverList, setCareGiverList] = useState([]);
  const [buttonState, setButtonState] = useState("");

  const [state, setState] = useState({
    open: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!open) {
      CalenderStore.setOpenDrawer(false);
    }
    setState({ open });
    if (state.open == false) {
      clearStates();
    }
  };

  const openCloseToggleDrawer = (params) => {
    setState({ open: params });
    if (state.open == false) {
      clearStates();
    }
  };

  useEffect(() => {
    setState({ open: CalenderStore.openDrawer });
  }, [CalenderStore.openDrawer]);

  const initialDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const formik = useFormik({
    initialValues: {
      clientList: "",
      careGiverList: "",
      title: "",
      descriptionForEmployee: "",
      descriptionForClient: "",
      dateTime: initialDateTime,
    },
    validationSchema: Yup.object({
      clientList: YupValidation.requiredOnly,
      careGiverList: YupValidation.requiredOnly,
      title: YupValidation.requiredOnly,
      descriptionForEmployee: YupValidation.requiredOnly,
      descriptionForClient: YupValidation.requiredOnly,
      dateTime: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (buttonState == "cancel") {
        cancelEmployeeCalendar(values);
      }
    },
  });

  const setProfileDataFormik = (data) => {
    const result = CalenderStore?.specificCalendarAllData;

    formik.setValues({
      clientList: result?.clientProfileId || "",
      careGiverList: result?.employeeProfileId || "",
      title: result?.title || "",
      descriptionForEmployee: result?.descriptionForEmployee || "",
      descriptionForClient: result?.descriptionForClient || "",
      dateTime: result?.dateTime || "",
    });
  };

  useEffect(() => {
    setProfileDataFormik();
    const specificCalendarAllData = CalenderStore?.specificCalendarAllData;
    const employeeInfo = CalenderStore?.specificEmployeeCalendar?.summary;
    if (specificCalendarAllData) {
      const fullNameEmployee = `${employeeInfo.employeeLastName} ${employeeInfo.employeeMiddleName} ${employeeInfo.employeeFirstName}(${employeeInfo.employeeUniqueId})`;
      const fullNameClient = `${specificCalendarAllData.clientLastName} ${specificCalendarAllData.clientMiddleName} ${specificCalendarAllData.clientFirstName}(${specificCalendarAllData.clientUniqueId})`;
      setEmployeeFullName(fullNameEmployee);
      setClientFullName(fullNameClient);
    }
  }, [
    CalenderStore?.specificCalendarAllData,
    CalenderStore?.specificEmployeeCalendar,
  ]);

  const cancelEmployeeCalendar = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      id: CalenderStore?.specificCalendarAllData?.id,
      employeeProfileId: values.careGiverList,
      clientProfileId: values.clientList,
    };
    axios
      .post(CalenderUrl.CANCEL_EMPLOYEE_CALENDER, params, {
        headers: { Authorization: sessionStorage.getItem("loginToken") },
      })
      .then((res) => {
        CalenderStore.getEmployeeCalendar("careGiver");

        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("success", "Schedule has been cancel");
        CalenderStore.setOpenDrawer(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to cancel Schedule");
        AgencyStore.setIsLoading(false);
      });
  };

  useEffect(() => {
    const clientResponse = ClientStore?.employeeClientRelationAllData;
    const clientFinalData = clientResponse.map((item) => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName} (${item.mra})`,
    }));
    setClientList(clientFinalData);
  }, [ClientStore?.employeeClientRelationAllData]);

  useEffect(() => {
    const caregiverResponse = CareGiverStore.careGiverList;
    const caregiverFinalData = caregiverResponse.map((item) => ({
      value: `${item.id}`,
      label: `${item.fullName}(${item.employeeId})`,
    }));
    setCareGiverList(caregiverFinalData);
  }, [CareGiverStore.careGiverList]);

  const resetForm = () => {
    formik.resetForm();
  };

  const clearStates = () => {
    resetForm();
    setEmployeeFullName("");
    setClientFullName("");
    setClientList([]);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ width: 450 }} role="presentation">
          <div className="STDmainContainer">
            <div className="STDheaderAndCloseicon">
              <div className="draweHeader">Add Schedule</div>
              <AiOutlineClose
                className="close-icon"
                onClick={toggleDrawer(false)}
              />{" "}
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="title"
                error
                label="Title"
                type="text"
                control="textfield"
                disabled="true"
                {...formik.getFieldProps("title")}
                touch={formik.touched.title}
                errorname={formik.errors.title}
              />
            </div>
            <div className="STDformInputContainer">
              <div className="title">{"Nurse"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={careGiverList}
                onChange={(event, value) => {
                  formik.setFieldValue("careGiverList", value.value);
                  formik.setFieldValue("clientList", "");
                  setClientFullName("");
                  ClientStore.getEmployeeClientRelation(value.value);
                  setEmployeeFullName(value.label);
                }}
                value={employeeFullName}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.careGiverList && formik.errors.careGiverList
                  ? formik.errors.careGiverList
                  : ""}
              </div>
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="description"
                error
                label="Description For Care Giver"
                type="text"
                multiline
                minRows={2}
                maxRows={4}
                control="textfield"
                disabled="true"
                {...formik.getFieldProps("descriptionForEmployee")}
                touch={formik.touched.descriptionForEmployee}
                errorname={formik.errors.descriptionForEmployee}
              />
            </div>
            <div className="STDformInputContainer">
              <div className="title">{"Client"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={clientList}
                onChange={(event, value) => {
                  formik.setFieldValue("clientList", value.value);
                  setClientFullName(value.label);
                }}
                value={clientFullName}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.clientList && formik.errors.clientList
                  ? formik.errors.clientList
                  : ""}
              </div>
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="description"
                error
                label="Description For Client"
                type="text"
                multiline
                minRows={2}
                maxRows={4}
                control="textfield"
                disabled="true"
                {...formik.getFieldProps("descriptionForClient")}
                touch={formik.touched.descriptionForClient}
                errorname={formik.errors.descriptionForClient}
              />
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="dateTime"
                error
                label="Date Time"
                type="datetime-local"
                control="textfield"
                disabled="true"
                values={formik.values.dateTime}
                {...formik.getFieldProps("dateTime")}
                touch={formik.touched.dateTime}
                errorname={formik.errors.dateTime}
              />
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default observer(SwipeableTemporaryDrawer);
