import { action, makeObservable, observable } from "mobx";
import { CareGiverURL, GlobelURL, NurseAndCareGiverURL } from "../Url/Url";
import AgencyStore from "./AgencyStore";
import axios from "axios";
import CareGiverStore from "./CareGiverStore";
import AdminEmployeeStore from "./AdminEmployeeStore";
import CommunityCoordinatorStore from "./CommunityCoordinatorStore";
import ClientStore from "./ClientStore";
import GlobelStore from "./GlobelStore";
import NotificationStore from "./NotificationStore";
import CalenderStore from "./CalenderStore";

class NurseAndCareGiverEmployeeStore {
  profileAllData = {};

  constructor() {
    makeObservable(this, {
      profileAllData: observable,

      setProfileAllData: action,

      getEmployeeClientRelation: action,
      getClientProfile: action,
      getNursingAssessmentDocuments: action,
      getEmployeeNotificationEmployeeUser: action,
      getCalendar: action,
      getEmpClientUnseenNotificationCount: action,
    });
  }

  setProfileAllData(data) {
    this.profileAllData = data;
  }
  getEmployeeClientRelation = async (employeeProfileId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NurseAndCareGiverURL.EMP_CLIENT_RELATION, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary.status === "success") {
          ClientStore.setEmployeeClientRelationAllData(res?.data?.resultSet);
          AgencyStore.setIsLoading(false);
        } else if (res?.data?.summary.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fatch data ");
      });
  };

  getClientProfile = async (ClientId) => {
    const params = { clientId: ClientId };
    await axios
      .post(NurseAndCareGiverURL.GET_CLIENT_PROFILE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          ClientStore.setClientAllData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Client profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get Client profile");
      });
  };

  getNursingAssessmentDocuments = async (employeeId, assessmentDocType) => {
    const params = {
      clientProfileId: employeeId,
      page: 1,
      pageSize: 1000,
      assessmentDocType: assessmentDocType,
    };
    await axios
      .post(NurseAndCareGiverURL.GET_NURSINGASSESSMENT_DOCUMENTS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status == 200) {
          if (assessmentDocType == "NURSING_ASSESSMENT") {
            ClientStore.setClientAllNursingAssessmentDocs(res?.data);
          } else if (assessmentDocType == "MEDICATION_RECORDS_LIST") {
            ClientStore.setClientAllMedicationRecordsDocs(res?.data);
          } else if (assessmentDocType == "HOME_SAFETY") {
            ClientStore.setClientAllHomeSafetyDocs(res?.data);
          } else if (assessmentDocType == "CLIENT_CARE_PLAN") {
            ClientStore.setClientAllClientCarePlanDocs(res?.data);
          } else if (assessmentDocType == "CAREGIVER_EVALUATION_FORM") {
            ClientStore.setClientAllCareGiverEvaluationFormDocs(res?.data);
          } else if (assessmentDocType == "CAREGIVER_PLAN_OF_SERVICE") {
            ClientStore.setClientAllCareGiverPlanOfServiceDocs(res?.data);
          } else if (
            assessmentDocType == "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
          ) {
            ClientStore.setClientAllCompetencyChecklistPersonalCareAideDocs(
              res?.data
            );
          } else if (
            assessmentDocType ==
            "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
          ) {
            ClientStore.setClientAllParticipantReviewAndCareGiverSupervisoryVisitDocs(
              res?.data
            );
          }
        } else {
          AgencyStore.showAlert(
            "error",
            "Failed to Get Nursing Assessment document"
          );
        }
      })
      .catch((err) => {
        AgencyStore.showAlert(
          "error",
          "Failed to Get Nursing Assessment document"
        );
      });
  };

  getEmployeeNotificationEmployeeUser = async (empCategory) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NurseAndCareGiverURL.GET_EMPLOYEE_NOTIFICATION_EMPLOYEE_USER, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        NotificationStore.setNurseNotificationAllData(res?.data);

        CareGiverStore.setTableHeight();
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to Employee Notification ");
        AgencyStore.setIsLoading(false);
      });
  };

  getCalendar = async (employeeProfileId) => {
    await axios
      .get(NurseAndCareGiverURL.GET_CALENDER, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          CalenderStore.setSpecificEmployeeCalendar(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Schedule Data");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Schedule Data");
      });
  };

  getEmpClientUnseenNotificationCount = async () => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NurseAndCareGiverURL.GET_UNSEEN_NOTIFICATION, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        NotificationStore.setNotificationUnseenCount(res?.data);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get Unseen Notification");
      });
  };
}

export default new NurseAndCareGiverEmployeeStore();
