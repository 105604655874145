import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../../CSS/Profile.css";
import AddCareGiver from "./AddCareGiver";
import CareGiverStore from "../../../Store/CareGiverStore";
import CareGiverClientView from "./CareGiverClientView";
import { useParams } from "react-router-dom";
import DocViewerList from "../DocViewerList/DocViewerList";
import CareGiverSecurity from "./CareGiverSecurity";
import ChangeLog from "../LogList/ChangeLog";
import GlobelStore from "../../../Store/GlobelStore";
import ClientStore from "../../../Store/ClientStore";
import CareGiverSchedule from "./CareGiverSchedule";
import CalenderStore from "../../../Store/CalenderStore";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";
import CareGiverClockInOut from "./CareGiverClockInOut/CareGiverClockInOut";
import EmpSignature from "../CommonPage/EmpSignature";

const CareGiverViewPage = observer(() => {
  const [value, setValue] = React.useState("1");
  const { employeeId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (employeeId) {
      CareGiverStore.getEmployeeProfile(employeeId);
      CareGiverStore.getConfidentialDoc(employeeId);
      CareGiverStore.getClientList();
      ClientStore.getEmployeeClientRelation(employeeId);
      CalenderStore.getSpecificEmployeeCalendar(employeeId);
      GlobelStore.getChangeLogs(employeeId);

      const today = new Date();
      const twoWeeksBack = new Date();
      twoWeeksBack.setDate(today.getDate() - 14);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const fromDate = formatDate(twoWeeksBack);
      const toDate = formatDate(today);
      CareGiverStore.getEmpClockInOutDetails(employeeId, fromDate, toDate);
      CareGiverStore.getEmpSignature(employeeId);
    }
  }, [employeeId]);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Personal Care Aid"
          subHeaderTitle=" Employee/Personal Care Aid/Profile"
        />
      </div>
      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                className="profileTabsMenu"
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="profile" className="profileEeachMenuIcon" />
                  }
                  label="Profile"
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="signature"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Signature"
                  value="10"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Government Document"
                  value="7"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Confidential Document"
                  value="2"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Application & Hiring Document"
                  value="3"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="log" className="profileEeachMenuIcon" />
                  }
                  label="Logs"
                  value="8"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="client" className="profileEeachMenuIcon" />
                  }
                  label="Client"
                  value="4"
                />
                {/* <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="client" className="profileEeachMenuIcon" />
                  }
                  label="Clock In/Out"
                  value="9"
                /> */}
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="schedule"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Schedule"
                  value="5"
                />{" "}
                {GlobelStore.checkUserRole("AccountAndAccess") && (
                  <Tab
                    className="profileEachMenu"
                    icon={
                      <Icon
                        iconName="account"
                        className="profileEeachMenuIcon"
                      />
                    }
                    label="Account"
                    value="6"
                  />
                )}
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <AddCareGiver />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="10">
              <EmpSignature />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <DocViewerList confidentialResultSet="confidentialResultSet" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="7">
              <DocViewerList
                govResultSet="govResultSet"
                immigrationStatus={
                  CareGiverStore?.careGiverProfileData?.immigrationStatus
                }
              />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <DocViewerList applicationResumeAndHireResultSet="applicationResumeAndHireResultSet" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="8">
              <ChangeLog employeeCategory="CareGiver" />
            </TabPanel>

            <TabPanel className="profileEachTabPanel" value="4">
              <CareGiverClientView />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="9">
              <CareGiverClockInOut />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <CareGiverSchedule />
            </TabPanel>
            {GlobelStore.checkUserRole("AccountAndAccess") && (
              <TabPanel className="profileEachTabPanel" value="6">
                <CareGiverSecurity />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </div>
    </div>
  );
});

export default CareGiverViewPage;
