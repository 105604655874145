import { action, makeObservable, observable } from "mobx";
import AgencyStore from "./AgencyStore";
import { ManualEmailSmsURL, NotificationURL } from "../Url/Url";
import CareGiverStore from "./CareGiverStore";
import axios from "axios";

class NotificationStore {
  careGiverNotificationAllData = [];
  adminEmployeeNotificationAllData = [];
  communityCoordinatorNotificationAllData = [];
  nurseNotificationAllData = [];
  clientNotificationAllData = [];

  notificationUnseenCount = {};
  careGiverManualNotiAllData = [];
  clientManualNotiAllData = [];
  empSingleNotiDetailsAllData = [];
  pageStatus = "manualNotification";
  clientNotificationAgGrid = {};
  adminNotificationAgGrid = {};
  careGiverNotificationAgGrid = {};
  nurseNotificationAgGrid = {};
  communityNotificationAgGrid = {};
  constructor() {
    makeObservable(this, {
      careGiverNotificationAllData: observable,
      adminEmployeeNotificationAllData: observable,
      communityCoordinatorNotificationAllData: observable,
      nurseNotificationAllData: observable,
      clientNotificationAllData: observable,
      notificationUnseenCount: observable,
      careGiverManualNotiAllData: observable,
      empSingleNotiDetailsAllData: observable,
      clientManualNotiAllData: observable,
      clientNotificationAgGrid: observable,
      adminNotificationAgGrid: observable,
      careGiverNotificationAgGrid: observable,
      nurseNotificationAgGrid: observable,
      communityNotificationAgGrid: observable,

      setCareGiverNotificationAllData: action,
      setAdminEmployeeNotificationAllData: action,
      setCommunityCoordinatorNotificationAllData: action,
      setNurseNotificationAllData: action,
      setClientNotificationAllData: action,
      setNotificationUnseenCount: action,
      setCareGiverManualNotiAllData: action,
      setEmpSingleNotiDetailsAllData: action,
      setClientManualNotiAllData: action,

      getClientNotification: action,
      getEmployeeNotification: action,
      getEmpClientUnseenNotificationCount: action,
      getEmpSingleNotiDetails: action,
      getClientSingleNotiDetails: action,
    });
  }
  setCareGiverNotificationAllData(data) {
    this.careGiverNotificationAllData = data;
  }
  setAdminEmployeeNotificationAllData(data) {
    this.adminEmployeeNotificationAllData = data;
  }
  setCommunityCoordinatorNotificationAllData(data) {
    this.communityCoordinatorNotificationAllData = data;
  }
  setNurseNotificationAllData(data) {
    this.nurseNotificationAllData = data;
  }
  setClientNotificationAllData(data) {
    this.clientNotificationAllData = data;
  }
  setNotificationUnseenCount(data) {
    this.notificationUnseenCount = data;
  }
  setCareGiverManualNotiAllData(data) {
    this.careGiverManualNotiAllData = data;
  }
  setClientManualNotiAllData(data) {
    this.clientManualNotiAllData = data;
  }

  setEmpSingleNotiDetailsAllData(data) {
    this.empSingleNotiDetailsAllData = data;
  }

  setClientNotificationAgGrid(data) {
    this.clientNotificationAgGrid = data;
  }
  setAdminNotificationAgGrid(data) {
    this.adminNotificationAgGrid = data;
  }

  setCareGiverNotificationAgGrid(data) {
    this.careGiverNotificationAgGrid = data;
  }
  setNurseNotificationAgGrid(data) {
    this.nurseNotificationAgGrid = data;
  }
  setCommunityNotificationAgGrid(data) {
    this.communityNotificationAgGrid = data;
  }

  getEmpClientUnseenNotificationCount = async (values) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NotificationURL.GET_UNSEEN_NOTIFICATION_ADMIN, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setNotificationUnseenCount(res?.data);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get Unseen Notification");
      });
  };

  getEmployeeNotification = async (empCategory) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NotificationURL.GET_EMPLOYEE_NOTIFICATION + "/" + empCategory, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (empCategory == "CareGiver") {
          this.setCareGiverNotificationAllData(res?.data);
        } else if (empCategory == "AdminEmployee") {
          this.setAdminEmployeeNotificationAllData(res?.data);
        } else if (empCategory == "CommunityCoordinator") {
          this.setCommunityCoordinatorNotificationAllData(res?.data);
        } else if (empCategory == "RN") {
          this.setNurseNotificationAllData(res?.data);
        }
        CareGiverStore.setTableHeight();
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to Employee Notification ");
        AgencyStore.setIsLoading(false);
      });
  };

  getClientNotification = async () => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(NotificationURL.GET_CLIENT_NOTIFICATION, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setClientNotificationAllData(res?.data);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get Client Notification  ");
        AgencyStore.setIsLoading(false);
      });
  };

  getEmpSingleNotiDetails = async (notificationId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(ManualEmailSmsURL.GET_EMP_SINGLE_NOTI_DETAILS + notificationId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setEmpSingleNotiDetailsAllData(res?.data);

        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert(
          "error",
          "Failed to get manual notification employee details"
        );
        AgencyStore.setIsLoading(false);
      });
  };

  getClientSingleNotiDetails = async (notificationId) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(ManualEmailSmsURL.GET_CLIENT_SINGLE_NOTI_DETAILS + notificationId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setEmpSingleNotiDetailsAllData(res?.data);

        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert(
          "error",
          "Failed to get manual notification employee details"
        );
        AgencyStore.setIsLoading(false);
      });
  };

  getEmpManualNoti = async (empCategory) => {
    AgencyStore.setIsLoading(true);

    await axios
      .get(ManualEmailSmsURL.GET_EMAIL_SMS_EMPLOYEE + "/" + empCategory, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        this.setCareGiverManualNotiAllData(res?.data);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get Manual Notification ");
        AgencyStore.setIsLoading(false);
      });
  };
}

export default new NotificationStore();
