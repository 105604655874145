import React from "react";
import "../../CSS/File.css";
const File = (props) => {
  const { name, label, type, touch, error, errorname, ...otherProps } = props;
  const textFielConfig = {
    size: "small",
    fullWidth: true,
    ...otherProps,
  };

  return (
    <>
      <div className="title">{label}</div>
      <div
        className={
          touch && errorname ? "fileInputDiv errorDiv" : "fileInputDiv"
        }
      >
        <input
          className="fileInput"
          name={name}
          type={type}
          {...textFielConfig}
        />
      </div>
      <div className="error">{touch && errorname ? errorname : ""}</div>
    </>
  );
};

export default File;
