import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import AgencyStore from "../Store/AgencyStore";
import "../CSS/LeftNavBar.css";
import LogoSvg from "../Images/GBHLogo.png";

import { Link } from "react-router-dom";

import Footer from "./Footer";
import GlobelStore from "../Store/GlobelStore";

import { Badge, Divider } from "@mui/material";
import { Margin } from "@mui/icons-material";
import NotificationStore from "../Store/NotificationStore";

import { employeeName } from "../Constants/Constants";
import Icon from "../components/Icons/Icon";

const LeftNavBar = observer(() => {
  const handleMenuItemsClicked = useCallback((data) => {
    AgencyStore.setSelectedMenuItem(data);
  }, []);

  const nurseRole = useMemo(() => GlobelStore.checkUserRole("Nurse"), []);
  const caregiverRole = useMemo(
    () => GlobelStore.checkUserRole("CareGiver"),
    []
  );
  const adminEmployeeRole = useMemo(
    () => GlobelStore.checkUserRole("AdminEmployee"),
    []
  );
  const communityCoordinatorRole = useMemo(
    () => GlobelStore.checkUserRole("CommunityCoordinator"),
    []
  );

  const notifications = useMemo(
    () => GlobelStore.checkUserRole("Notifications"),
    []
  );

  const changeLog = useMemo(() => GlobelStore.checkUserRole("ChangeLog"), []);

  const accountAccess = useMemo(
    () => GlobelStore.checkUserRole("AccountAndAccess"),
    []
  );

  const clientRole = useMemo(() => GlobelStore.checkUserRole("Client"), []);

  return (
    <div>
      <div className="logoMainContainer">
        <div className="logoContainer">
          <img className="logoImage" src={LogoSvg} alt="" loading="lazy" />
        </div>
      </div>
      <div className="menuButtoncontainer">
        <div>
          <div className="menuItemsSubContainer">
            <div className="menuItemsSubTilte">Navigation</div>
            <div className="menuItemsSub">
              <div className="menuItemsContainer">
                <Link to="/agency/dashboard" className="navleftBarLink">
                  <div
                    className={
                      AgencyStore.selectedMenuItem === "dashboard"
                        ? "leftNavBarButtonSelected "
                        : "leftNavBarButtonUnselected "
                    }
                    onClick={() => handleMenuItemsClicked("dashboard")}
                  >
                    <Icon iconName="dashboard" className="leftNavBarIcon" />
                    <span>{employeeName.dashBoard}</span>
                  </div>
                </Link>
              </div>
              {notifications && (
                <div className="menuItemsContainer">
                  <Link to="/agency/notification" className="navleftBarLink">
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Notification"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Notification")}
                    >
                      <Icon
                        iconName="notifications"
                        className="leftNavBarIcon"
                      />
                      <span>{employeeName.notification}</span>

                      <Badge
                        badgeContent={
                          NotificationStore?.notificationUnseenCount?.totalCount
                        }
                        color="error"
                      >
                        <div style={{ marginLeft: "20px" }}></div>
                      </Badge>
                    </div>
                  </Link>
                </div>
              )}

              {notifications && (
                <div className="menuItemsContainer">
                  <Link
                    to="/agency/manualNotification"
                    className="navleftBarLink"
                  >
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "manualNotification"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() =>
                        handleMenuItemsClicked("manualNotification")
                      }
                    >
                      <Icon iconName="mail" className="leftNavBarIcon" />
                      <span>{employeeName.emailSms}</span>
                      <div style={{ marginLeft: "20px" }}></div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider className="dividerNavbar" />
        <div className="othersMenuContiner">
          <div className="menuItemsMainContainer">
            {clientRole ? (
              <div className="menuItemsSubContainer">
                <div className="menuItemsSubTilte">Customer</div>
                <div className="menuItemsSub">
                  <div className="menuItemsContainer">
                    <Link to="/agency/leads_list" className="navleftBarLink">
                      <div
                        className={
                          AgencyStore.selectedMenuItem === "Leads"
                            ? "leftNavBarButtonSelected "
                            : "leftNavBarButtonUnselected "
                        }
                        onClick={() => handleMenuItemsClicked("Leads")}
                      >
                        <Icon iconName="client" className="leftNavBarIcon" />
                        <span>{employeeName.leads}</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="menuItemsSub">
                  <div className="menuItemsContainer">
                    <Link to="/agency/client_list" className="navleftBarLink">
                      <div
                        className={
                          AgencyStore.selectedMenuItem === "Client"
                            ? "leftNavBarButtonSelected "
                            : "leftNavBarButtonUnselected "
                        }
                        onClick={() => handleMenuItemsClicked("Client")}
                      >
                        <Icon iconName="client" className="leftNavBarIcon" />
                        <span>{employeeName.client}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}

            {nurseRole ||
            caregiverRole ||
            adminEmployeeRole ||
            communityCoordinatorRole ? (
              <div className="menuItemsSubContainer">
                <div className="menuItemsSubTilte">Employee</div>
                <div className="menuItemsSub">
                  {nurseRole && (
                    <div className="menuItemsContainer">
                      <Link to="/agency/nurse_List" className="navleftBarLink">
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "Nurse"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() => handleMenuItemsClicked("Nurse")}
                        >
                          <Icon iconName="nurse" className="leftNavBarIcon" />
                          <span>{employeeName.nurse}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                  {caregiverRole && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/careGiverList"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "careGiver"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() => handleMenuItemsClicked("careGiver")}
                        >
                          <Icon
                            iconName="careGiver"
                            className="leftNavBarIcon"
                          />
                          <span>{employeeName.careGiver}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                  {adminEmployeeRole && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/adminEmployeeList"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "adminEmployee"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() =>
                            handleMenuItemsClicked("adminEmployee")
                          }
                        >
                          <Icon iconName="admin" className="leftNavBarIcon" />
                          <span>{employeeName.adminEmployee}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                  {communityCoordinatorRole && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/CommunityOutReachCoordinator"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem ===
                            "CommunityOutReachCoordinator"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() =>
                            handleMenuItemsClicked(
                              "CommunityOutReachCoordinator"
                            )
                          }
                        >
                          <Icon
                            iconName="coordinator"
                            className="leftNavBarIcon"
                          />
                          <span>{employeeName.communityCordinatorLeftNav}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            {nurseRole || caregiverRole ? (
              <div className="menuItemsSubContainer">
                <div className="menuItemsSubTilte">Schedule</div>
                <div className="menuItemsSub">
                  {nurseRole && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/NurseSchedule"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "nurseSchedule"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() =>
                            handleMenuItemsClicked("nurseSchedule")
                          }
                        >
                          <Icon
                            iconName="schedule"
                            className="leftNavBarIcon"
                          />
                          <span>{employeeName.nurseSchedule}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                  {caregiverRole && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/careGiverSchedule"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "careGiverSchedule"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() =>
                            handleMenuItemsClicked("careGiverSchedule")
                          }
                        >
                          <Icon
                            iconName="schedule"
                            className="leftNavBarIcon"
                          />
                          <span>{employeeName.careGiverSchedule}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {notifications || accountAccess || changeLog ? (
              <div className="menuItemsSubContainer">
                <div className="menuItemsSubTilte">Other</div>
                <div className="menuItemsSub">
                  {/* <div className="menuItemsContainer">
                    <Link to="/agency/payRoll" className="navleftBarLink">
                      <div
                        className={
                          AgencyStore.selectedMenuItem === "PayRoll"
                            ? "leftNavBarButtonSelected "
                            : "leftNavBarButtonUnselected "
                        }
                        onClick={() => handleMenuItemsClicked("PayRoll")}
                      >
                        <BsFileEarmarkPerson className="leftNavBarIcon" />
                        <span>PayRoll</span>
                      </div>
                    </Link>
                  </div> */}
                  {/* <div className="menuItemsContainer">
                    <Link to="/agency/report" className="navleftBarLink">
                      <div
                        className={
                          AgencyStore.selectedMenuItem === "Report"
                            ? "leftNavBarButtonSelected "
                            : "leftNavBarButtonUnselected "
                        }
                        onClick={() => handleMenuItemsClicked("Report")}
                      >
                        <TbReportSearch className="leftNavBarIcon" />
                        <span>Report</span>
                      </div>
                    </Link>
                  </div> */}
                  {accountAccess && (
                    <div className="menuItemsContainer">
                      <Link
                        to="/agency/account_access"
                        className="navleftBarLink"
                      >
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "Accounts/Access"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() =>
                            handleMenuItemsClicked("Accounts/Access")
                          }
                        >
                          <Icon iconName="access" className="leftNavBarIcon" />
                          <span>{employeeName.accountAccess}</span>
                        </div>
                      </Link>
                    </div>
                  )}

                  {changeLog && (
                    <div className="menuItemsContainer">
                      <Link to="/agency/logList" className="navleftBarLink">
                        <div
                          className={
                            AgencyStore.selectedMenuItem === "logList"
                              ? "leftNavBarButtonSelected "
                              : "leftNavBarButtonUnselected "
                          }
                          onClick={() => handleMenuItemsClicked("logList")}
                        >
                          <Icon iconName="log" className="leftNavBarIcon" />
                          <span>{employeeName.changeLog}</span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
});

export default LeftNavBar;
