import React from "react";
import AgencyStore from "../Store/AgencyStore";
import { observer } from "mobx-react";
import "../CSS/Agency.css";
import LeftNavBar from "./LeftNavBar";
import TopNavBar from "./TopNavBar";
import PagesMain from "./PagesMain";

const NurseAndCareGiverMain = observer(() => {
  return (
    <div className="agencyMainContainer">
      {AgencyStore.isLeftContainerVisible && (
        <div className="agencgyLeftContainer">
          <LeftNavBar />
        </div>
      )}
      <div className="agencyRightcontainer">
        <div className="agencyTopNavContainer">
          <TopNavBar />
        </div>
        <div className="agencyBodyContainer">
          <PagesMain />
        </div>
      </div>
    </div>
  );
});

export default NurseAndCareGiverMain;
