import React from "react";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import * as Yup from "yup";
import CareGiverStore from "../../../Store/CareGiverStore";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import { LogURL } from "../../../Url/Url";
import { Dialog, DialogActions, DialogTitle, Grid } from "@mui/material";
import { PaperComponent } from "../../../Utils/Utils";
import ClientStore from "../../../Store/ClientStore";
import GlobelStore from "../../../Store/GlobelStore";
import "../../../CSS/AccountAccess.css";
import { AiOutlineClose } from "react-icons/ai";

const LogForm = ({ open, handleClose, employeeCategory }) => {
  const formik = useFormik({
    initialValues: {
      whoChange: "",
      whatChange: "",
      changeDate: "",
      caseNote: "",
      employeeProfileId: "",
    },

    validationSchema: Yup.object({
      whoChange: YupValidation.logChanges,
      whatChange: YupValidation.logChanges,
      changeDate: YupValidation.expiryDate,
      caseNote: YupValidation.logChanges,
      employeeProfileId: YupValidation.employeeId,
    }),
    onSubmit: (values) => {
      saveChangeLog(values);
      handleClose();
    },
  });

  const saveChangeLog = (values) => {
    AgencyStore.setIsLoading(true);
    let employeeId;
    if (employeeCategory.employeeCategory === "CareGiver") {
      employeeId = CareGiverStore.careGiverProfileData.id;
    } else if (employeeCategory.employeeCategory === "Client") {
      employeeId = ClientStore.ClientAllData.client.id;
    } else {
      employeeId = CareGiverStore.careGiverDbId;
    }

    const params = {
      whatChanged: values.whatChange,
      whoChanged: values.whoChange,
      caseNote: values.caseNote,
      changeDate: values.changeDate,
      [employeeCategory.employeeCategory === "Client"
        ? "clientProfileId"
        : "employeeProfileId"]: employeeId,
    };

    axios
      .post(
        employeeCategory.employeeCategory === "Client"
          ? LogURL.Client_Change_Log
          : LogURL.Employee_Change_Log,
        params,
        {
          headers: { Authorization: localStorage.getItem("loginToken") },
        }
      )
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully saved changed log");
          CareGiverStore.setIsCareGiverSaved(true);

          if (employeeCategory.employeeCategory === "Client") {
            GlobelStore.getClientChangeLogs(employeeId);
          } else {
            GlobelStore.getChangeLogs(employeeId);
          }
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save change log");
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        maxWidth="xs"
      >
        <div className="borderBottom">
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              Add Change Log
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>
          <div className="UCPUaction-popup-content">
            {" "}
            <Grid item xs={6}>
              <FormikController
                name="changeDate"
                error
                label="Log date Changes"
                type="date"
                control="textfield"
                {...formik.getFieldProps("changeDate")}
                touch={formik.touched.changeDate}
                errorname={formik.errors.changeDate}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikController
                name="whoChange"
                error
                label="Who Changes"
                type="text"
                control="textfield"
                {...formik.getFieldProps("whoChange")}
                touch={formik.touched.whoChange}
                errorname={formik.errors.whoChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikController
                name="whatChange"
                error
                label="What Changes"
                type="text"
                control="textfield"
                {...formik.getFieldProps("whatChange")}
                touch={formik.touched.whatChange}
                errorname={formik.errors.whatChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikController
                name="caseNote"
                error
                label="Case Note"
                multiline
                rows={3}
                control="textfield"
                {...formik.getFieldProps("caseNote")}
                touch={formik.touched.caseNote}
                errorname={formik.errors.caseNote}
              />
            </Grid>
          </div>
        </div>
        <DialogActions className="DialogActionsContainer">
          <FormikController
            name="Save "
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelectedGreen fixedWidth"
            fullWidth="false"
          />
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogForm;
