import React from "react";
import ReactApexChart from "react-apexcharts";

const leadsStatus = [
  {
    label: "Lead",
    value: 10,
  },
  {
    label: "Initial Referral made to the county",
    value: 5,
  },
  {
    label: "Nurse Assessment Schedule",
    value: 8,
  },
  {
    label: "Nurse Assessment done",
    value: 7,
  },
  {
    label: "Support Planner Referral made",
    value: 6,
  },
  {
    label: "Support Planner Visit",
    value: 4,
  },
  {
    label: "POS received",
    value: 3,
  },
  {
    label: "Pending MDH decision",
    value: 2,
  },
];

const LeadsChart = () => {
  const colors = [
    "#008FFB",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    "#3F51B5",
    "#546E7A",
    "#D4526E",
    "#8D5B4C",
  ];

  const series = [
    {
      data: leadsStatus.map((status) => status.value),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: leadsStatus.map((status) => status.label),
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          return leadsStatus[dataPointIndex].label + ": " + val;
        },
      },
    },
  };

  return (
    <div>
      <div className="EmployeeChartMainContainerMini">
        <div className="titleContainer">
          <div className="title">Leads</div>
          <div className="total">Total: 100</div>
        </div>
        <div className="detailContainerLeadsChart">
          {/* Uncomment if you want to display leadsStatus as a list */}
          {/* {leadsStatus.map((status) => (
            <div key={status.label} className="subDetailContainerLeadsChart">
              <div className="title">{status.label}</div>
              <div className="value">{status.value}</div>
            </div>
          ))} */}
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsChart;
