import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "../../../CSS/Schedule.css";
import FormikController from "../../../components/FormikController/FormikController";
import CloseIcon from "@mui/icons-material/Close";
import ClientStore from "../../../Store/ClientStore";
import * as Yup from "yup";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { useFormik } from "formik";
import { CalenderUrl } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import { format } from "date-fns";
import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react";
import CalenderStore from "../../../Store/CalenderStore";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import GlobelStore from "../../../Store/GlobelStore";
import Icon from "../../../components/Icons/Icon";
import { AiOutlineClose } from "react-icons/ai";

const SwipeableTemporaryDrawer = () => {
  const [clientList, setClientList] = useState([]);
  const [employeeFullName, setEmployeeFullName] = useState("");
  const [clientFullName, setClientFullName] = useState("");
  const [nurseList, setNurseList] = useState([]);
  const [buttonState, setButtonState] = useState("");

  const handlebuttonState = (value) => {
    setButtonState(value);
  };
  const [state, setState] = useState({
    open: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!open) {
      CalenderStore.setOpenDrawer(false);
    }
    setState({ open });
    if (state.open == false) {
      clearStates();
    }
  };

  const openCloseToggleDrawer = (params) => {
    setState({ open: params });
    if (state.open == false) {
      clearStates();
    }
  };

  useEffect(() => {
    setState({ open: CalenderStore.openDrawer });
  }, [CalenderStore.openDrawer]);

  const initialDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const formik = useFormik({
    initialValues: {
      clientList: "",
      careGiverList: "",
      title: "",
      visitType: "",
      descriptionForEmployee: "",
      descriptionForClient: "",
      dateTime: initialDateTime,
    },
    validationSchema: Yup.object({
      clientList: YupValidation.requiredOnly,
      careGiverList: YupValidation.requiredOnly,
      title: YupValidation.requiredOnly,
      visitType: YupValidation.requiredOnly,
      descriptionForEmployee: YupValidation.requiredOnly,
      descriptionForClient: YupValidation.requiredOnly,
      dateTime: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (buttonState == "update") {
        updateEmployeeCalendar(values);
      } else if (buttonState == "save") {
        setEmployeeCalendar(values);
      } else if (buttonState == "delete") {
        deleteEmployeeCalendar(values);
      } else if (buttonState == "cancel") {
        cancelEmployeeCalendar(values);
      }
    },
  });

  const setProfileDataFormik = (data) => {
    const result = CalenderStore?.specificCalendarAllData;

    formik.setValues({
      clientList: result?.clientProfileId || "",
      careGiverList: result?.employeeProfileId || "",
      title: result?.title || "",
      visitType: result?.visitType || "",
      descriptionForEmployee: result?.descriptionForEmployee || "",
      descriptionForClient: result?.descriptionForClient || "",
      dateTime: result?.dateTime || "",
    });
  };

  useEffect(() => {
    setProfileDataFormik();
    const specificCalendarAllData = CalenderStore?.specificCalendarAllData;
    if (specificCalendarAllData) {
      const fullNameEmployee = `${specificCalendarAllData.employeeLastName} ${specificCalendarAllData.employeeMiddleName} ${specificCalendarAllData.employeeFirstName}(${specificCalendarAllData.employeeUniqueId})`;
      const fullNameClient = `${specificCalendarAllData.clientLastName} ${specificCalendarAllData.clientMiddleName} ${specificCalendarAllData.clientFirstName}(${specificCalendarAllData.clientUniqueId})`;
      setEmployeeFullName(fullNameEmployee);
      setClientFullName(fullNameClient);
    }
  }, [CalenderStore?.specificCalendarAllData]);

  const setEmployeeCalendar = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      employeeProfileId: values.careGiverList,
      clientProfileId: values.clientList,
      title: values.title,
      visitType: values.visitType,
      descriptionForEmployee: values.descriptionForEmployee,
      descriptionForClient: values.descriptionForClient,
      dateTime: format(new Date(values.dateTime), "yyyy-MM-dd HH:mm:ss"),
    };
    axios
      .post(CalenderUrl.SET_EMPLOYEE_CALENDER, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        CalenderStore.getEmployeeCalendar("RN");

        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("success", "successfully set Schedule");
        CalenderStore.setOpenDrawer(false);
        openCloseToggleDrawer(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get Schedule");
        AgencyStore.setIsLoading(false);
      });
  };

  const updateEmployeeCalendar = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      id: CalenderStore?.specificCalendarAllData?.id,
      employeeProfileId: values.careGiverList,
      clientProfileId: values.clientList,
      title: values.title,
      visitType: values.visitType,
      descriptionForEmployee: values.descriptionForEmployee,
      descriptionForClient: values.descriptionForClient,
      dateTime: format(new Date(values.dateTime), "yyyy-MM-dd HH:mm:ss"),
    };
    axios
      .post(CalenderUrl.UPDATE_EMPLOYEE_CALENDER, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        CalenderStore.getEmployeeCalendar("RN");

        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("success", "successfully Update Schedule");
        CalenderStore.setOpenDrawer(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to Update Schedule");
        AgencyStore.setIsLoading(false);
      });
  };

  const deleteEmployeeCalendar = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      id: CalenderStore?.specificCalendarAllData?.id,
      employeeProfileId: values.careGiverList,
      clientProfileId: values.clientList,
    };
    axios
      .post(CalenderUrl.DELETE_EMPLOYEE_CALENDER, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        CalenderStore.getEmployeeCalendar("RN");

        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("success", "successfully Deleted Schedule");
        CalenderStore.setOpenDrawer(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to delete Schedule");
        AgencyStore.setIsLoading(false);
      });
  };

  const cancelEmployeeCalendar = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      id: CalenderStore?.specificCalendarAllData?.id,
      employeeProfileId: values.careGiverList,
      clientProfileId: values.clientList,
    };
    axios
      .post(CalenderUrl.CANCEL_EMPLOYEE_CALENDER, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        CalenderStore.getEmployeeCalendar("RN");

        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("success", "Schedule has been cancel");
        CalenderStore.setOpenDrawer(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to cancel Schedule");
        AgencyStore.setIsLoading(false);
      });
  };

  useEffect(() => {
    const clientResponse = ClientStore?.employeeClientRelationAllData;
    const clientFinalData = clientResponse.map((item) => ({
      value: item.id,
      label: `${item.lastName} ${item.middleName} ${item.firstName} (${item.mra})`,
    }));
    setClientList(clientFinalData);
  }, [ClientStore?.employeeClientRelationAllData]);

  useEffect(() => {
    const nurseResponse = GlobelStore.nurseList;
    const nurseFinalData = nurseResponse.map((item) => ({
      value: `${item.id}`,
      label: `${item.fullName}(${item.employeeId})`,
    }));
    setNurseList(nurseFinalData);
  }, [GlobelStore.nurseList]);

  const resetForm = () => {
    formik.resetForm();
  };

  const clearStates = () => {
    resetForm();
    setEmployeeFullName("");
    setClientFullName("");
    setClientList([]);
  };
  const handleAddScheduleClick = () => {
    toggleDrawer(true)();
    CalenderStore.setIsSwipeableTemporaryDrawer("add");
  };

  const nurseVisitType = [
    { label: "Initial Visit", value: "INITIAL_ASSESSMENT" },
    { label: "Quaterly Visit", value: "THREE_MONTHS_VISIT" },
    { label: "Anually Visit", value: "ANNUAL" },
    { label: "Client Moves", value: "WHEN_THEY_MOVE" },
    { label: "Care giver Changed", value: "CARE_GIVER_CHANGE" },
  ];

  return (
    <div>
      <FormikController
        name="Add Schedule"
        control="buttons"
        className="custom-buttons"
        onClick={() => handleAddScheduleClick()}
        startIcon={<Icon iconName="add" />}
      />

      <SwipeableDrawer
        anchor="right"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ width: 450 }} role="presentation">
          <div className="STDmainContainer">
            <div className="STDheaderAndCloseicon">
              <div className="draweHeader">Add Schedule</div>
              <AiOutlineClose
                className="close-icon"
                onClick={toggleDrawer(false)}
              />{" "}
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="title"
                error
                label="Title"
                type="text"
                control="textfield"
                {...formik.getFieldProps("title")}
                touch={formik.touched.title}
                errorname={formik.errors.title}
              />
            </div>
            <div className="STDformInputContainer">
              <FormikController
                control="selects"
                title="Visit Type"
                {...formik.getFieldProps("visitType")}
                error
                touch={formik.touched.visitType}
                errorname={formik.errors.visitType}
                selectitem={nurseVisitType}
                // disabled={isSaved ? true : false}
              />
            </div>
            <div className="STDformInputContainer">
              <div className="title">{"Nurse"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={nurseList}
                onChange={(event, value) => {
                  if (value !== null) {
                    formik.setFieldValue("careGiverList", value.value);
                    formik.setFieldValue("clientList", "");
                    setClientFullName("");
                    ClientStore.getEmployeeClientRelation(value.value);
                    setEmployeeFullName(value.label);
                  } else {
                    // Handle the case where value is null (e.g., clear any related state)
                    formik.setFieldValue("careGiverList", "");
                    formik.setFieldValue("clientList", "");
                    setClientFullName("");
                    setEmployeeFullName("");
                  }
                }}
                value={employeeFullName}
                disabled={CalenderStore.isSwipeableTemporaryDrawer === "update"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.careGiverList && formik.errors.careGiverList
                  ? formik.errors.careGiverList
                  : ""}
              </div>
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="description"
                error
                label="Description For Nurse"
                type="text"
                multiline
                minRows={2}
                maxRows={4}
                control="textfield"
                {...formik.getFieldProps("descriptionForEmployee")}
                touch={formik.touched.descriptionForEmployee}
                errorname={formik.errors.descriptionForEmployee}
              />
            </div>
            <div className="STDformInputContainer">
              <div className="title">{"Client"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={clientList}
                onChange={(event, value) => {
                  if (value !== null) {
                    formik.setFieldValue("clientList", value.value);
                    setClientFullName(value.label);
                  }
                }}
                value={clientFullName}
                disabled={CalenderStore.isSwipeableTemporaryDrawer === "update"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.clientList && formik.errors.clientList
                  ? formik.errors.clientList
                  : ""}
              </div>
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="description"
                error
                label="Description For Client"
                type="text"
                multiline
                minRows={2}
                maxRows={4}
                control="textfield"
                {...formik.getFieldProps("descriptionForClient")}
                touch={formik.touched.descriptionForClient}
                errorname={formik.errors.descriptionForClient}
              />
            </div>
            <div className="STDformInputContainer">
              <FormikController
                name="dateTime"
                error
                label="Date Time"
                type="datetime-local"
                control="textfield"
                values={formik.values.dateTime}
                {...formik.getFieldProps("dateTime")}
                touch={formik.touched.dateTime}
                errorname={formik.errors.dateTime}
              />
            </div>
            <div className="STDBtnContainer">
              {CalenderStore.isSwipeableTemporaryDrawer == "update" ? (
                <div className="STDupdateCancelContainer">
                  <ConfirmPopUp
                    buttonLabel="Update"
                    title="Update Schedule"
                    content=" Please confirm your decision below."
                    onConfirm={() => {
                      handlebuttonState("update");
                      formik.handleSubmit();
                    }}
                    clickHTML={
                      <FormikController
                        name="Update Schedule"
                        control="buttons"
                        className="buttonSelectedGreen fixedWidth"
                        fullWidth="false"
                      />
                    }
                  />
                  <ConfirmPopUp
                    buttonLabel="Delete"
                    title="Delete Schedule"
                    content=" Please confirm your decision below."
                    onConfirm={() => {
                      handlebuttonState("delete");
                      formik.handleSubmit();
                    }}
                    clickHTML={
                      <FormikController
                        name="Delete"
                        control="buttons"
                        className="buttonSelectedRed fixedWidth"
                        fullWidth="false"
                      />
                    }
                  />
                  <ConfirmPopUp
                    buttonLabel="Cancel"
                    title="Cancel Schedule"
                    content=" Please confirm your decision below."
                    onConfirm={() => {
                      handlebuttonState("cancel");
                      formik.handleSubmit();
                    }}
                    clickHTML={
                      <FormikController
                        name="Cancel"
                        control="buttons"
                        className="buttonUnselected fixedWidth"
                        fullWidth="false"
                      />
                    }
                  />
                </div>
              ) : (
                <ConfirmPopUp
                  buttonLabel="Save Schedule"
                  title="Save Schedule"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    handlebuttonState("save");
                    formik.handleSubmit();
                  }}
                  clickHTML={
                    <FormikController
                      name="Save Schedule"
                      control="buttons"
                      className="buttonSelectedGreen fixedWidth"
                      fullWidth="false"
                    />
                  }
                />
              )}
            </div>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default observer(SwipeableTemporaryDrawer);
