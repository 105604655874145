import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import "../../../CSS/AccountAccess.css";
import { observer } from "mobx-react";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import * as Yup from "yup";
import AgencyStore from "../../../Store/AgencyStore";
import ClientStore from "../../../Store/ClientStore";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import { useParams } from "react-router-dom";
import CareGiverStore from "../../../Store/CareGiverStore";
import { DialogActions } from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const UpdateCaseNotePopUp = ({ onConfirm, clickHTML, type }) => {
  const { clientId } = useParams();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [docterList, setDocterList] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      caseNote: "",
      docter: "",
    },

    validationSchema: Yup.object({
      caseNote: YupValidation.caseNote,
      docter: type === "docterCaseNote" ? YupValidation.requiredOnly : null,
    }),
    onSubmit: (values) => {
      if (type == "ClientCaseNote") {
        if (status === "save") {
          saveClientProfileCaseNote(values);
        } else {
          updateClientProfileCaseNote(values);
        }
      } else if (type == "docterCaseNote") {
        if (status === "save") {
          saveDoctorCaseNote(values);
        } else {
          updateDoctorCaseNote(values);
        }
      }
    },
  });

  const saveDoctorCaseNote = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      doctorId: values.docter,
      caseNote: values.caseNote,
    };
    axios
      .post(ClientURL.SAVE_DOCTOR_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getDoctorCaseNotes(clientId);
          formik.resetForm();
          CareGiverStore.setTableHeight();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully Added");
          setOpen(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Docter Case Note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Docter Information");
      });
  };

  const saveClientProfileCaseNote = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientProfileId: clientId,
      caseNote: values.caseNote,
    };
    axios
      .post(ClientURL.SAVE_CLIENT_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfileCaseNotes(clientId);
          formik.resetForm();
          CareGiverStore.setTableHeight();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
          setOpen(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Client Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Client Case note");
      });
  };

  const updateDoctorCaseNote = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: ClientStore.individualDocterCaseNoteData.id,
      doctorId: values.docter,
      caseNote: values.caseNote,
    };

    axios
      .post(ClientURL.UPDATE_DOCTOR_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getDoctorCaseNotes(clientId);

          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to update Docter information");
      });
  };

  const updateClientProfileCaseNote = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: ClientStore.individualDocterCaseNoteData.id,
      clientProfileId: clientId,
      caseNote: values.caseNote,
    };

    axios
      .post(ClientURL.UPDATE_CLIENT_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfileCaseNotes(clientId);

          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to update Docter information");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = ClientStore.individualDocterCaseNoteData;
    formik.setValues({
      caseNote: result?.caseNote || "",
      docter: result?.doctorId || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [clientId, ClientStore.individualDocterCaseNoteData]);

  useEffect(() => {
    const Response = ClientStore?.docterInfoAllData?.resultSet;
    const FinalData = Response?.map((item) => ({
      value: item.id,
      label: `${item.doctor}`,
    }));
    setDocterList(FinalData);
  }, [ClientStore?.docterInfoAllData]);

  const immigrationStatus = [
    { label: "Green Card", value: "Green Card" },
    { label: "US Citizen", value: "US Citizen" },
    { label: "Employment Authorization", value: "Employment Authorization" },
  ];
  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        // maxWidth={"md"}
      >
        <div className="borderBottom">
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              {ClientStore?.caseNotestatus === "update"
                ? "Update Case Note"
                : "Add Case Note"}
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>

          <div className="UCPUaction-popup-content">
            <div className="clientDoctorCaseNoteInputContainer">
              {/* UCNPUformInputContainer */}

              {type == "docterCaseNote" && (
                <div className="formInputContainer">
                  <FormikController
                    control="selects"
                    title="Docter"
                    {...formik.getFieldProps("docter")}
                    error
                    touch={formik.touched.docter}
                    errorname={formik.errors.docter}
                    selectitem={docterList}
                    // disabled={isSaved ? true : false}
                  />
                </div>
              )}
              <div className="formInputContainer">
                <FormikController
                  name="caseNote"
                  error
                  multiline
                  label="Case Note"
                  type="text"
                  minRows={2}
                  maxRows={4}
                  control="textfield"
                  {...formik.getFieldProps("caseNote")}
                  touch={formik.touched.caseNote}
                  errorname={formik.errors.caseNote}
                  fullWidth="true"
                />
              </div>
            </div>
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            {ClientStore?.caseNotestatus === "update" ? (
              <FormikController
                name="Update Case Note"
                control="buttons"
                onClick={() => {
                  setStatus("update");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            ) : (
              <FormikController
                name="Save Case Note"
                control="buttons"
                onClick={() => {
                  setStatus("save");
                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            )}
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(UpdateCaseNotePopUp);
