import React, { useState } from "react";
import CareGiverStore from "../Store/CareGiverStore";
import AdminEmployeeStore from "../Store/AdminEmployeeStore";
import CommunityCoordinatorStore from "../Store/CommunityCoordinatorStore";
import GlobelStore from "../Store/GlobelStore";
import ClientStore from "../Store/ClientStore";
import NotificationStore from "../Store/NotificationStore";

const useSearchData = () => {
  const [searchData, setSearchData] = useState({
    searchQuery: "",
    activeCheckBox: "",
  });

  const handleActiveDropDown = (dropDownValue) => {
    let finalData = searchData.searchQuery + " " + dropDownValue;

    ClientStore.clientAgGrid?.setQuickFilter(finalData);
    CareGiverStore.setTableHeight(
      ClientStore.clientAgGrid.getDisplayedRowCount()
    );
  };
  const handleSearchChange = (e, employeeSearch) => {
    const newValue = e.target.value;
    setSearchData((prevData) => ({
      ...prevData,
      searchQuery: newValue,
    }));
    getQuickFilter(newValue, searchData.activeCheckBox, employeeSearch);
  };

  const handleActiveCheckBox = (e, value, employeeValue) => {
    const newValue = e.target.checked ? value : "";
    setSearchData((prevData) => ({
      ...prevData,
      activeCheckBox: newValue,
    }));
    getQuickFilter(searchData.searchQuery, newValue, employeeValue);
  };
  const getQuickFilter = (searchValue, checkBoxValue, employeeSearch) => {
    let finalData = searchValue + " " + checkBoxValue;
    if (employeeSearch === "CareGiver" || employeeSearch === "RN") {
      CareGiverStore.careGiverAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        CareGiverStore.careGiverAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "AdminEmployee") {
      AdminEmployeeStore.adminEmployeeAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        AdminEmployeeStore.adminEmployeeAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "CommunityCoordinator") {
      CommunityCoordinatorStore.communityCoordinatorAgGrid?.setQuickFilter(
        finalData
      );
      CareGiverStore.setTableHeight(
        CommunityCoordinatorStore.communityCoordinatorAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "Nurse") {
      GlobelStore.nurseAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        GlobelStore.nurseAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "Client") {
      ClientStore.clientAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        ClientStore.clientAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "NotificationClient") {
      NotificationStore.clientNotificationAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        NotificationStore.clientNotificationAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "AdminNotification") {
      NotificationStore.adminNotificationAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        NotificationStore.adminNotificationAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "CareGiverNotification") {
      NotificationStore.careGiverNotificationAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        NotificationStore.careGiverNotificationAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "CoordinatorNotification") {
      NotificationStore.communityNotificationAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        NotificationStore.communityNotificationAgGrid.getDisplayedRowCount()
      );
    } else if (employeeSearch == "NurseNotification") {
      NotificationStore.nurseNotificationAgGrid?.setQuickFilter(finalData);
      CareGiverStore.setTableHeight(
        NotificationStore.nurseNotificationAgGrid.getDisplayedRowCount()
      );
    }
  };

  return [handleSearchChange, handleActiveCheckBox, handleActiveDropDown];
};

export default useSearchData;
