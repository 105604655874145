import React from "react";
import LogForm from "./LogForm";
import SearchBox from "../../../components/Search/SearchBox";
import ManualChangeLogTable from "./ManualChangeLogTable";
import CareGiverStore from "../../../Store/CareGiverStore";
import { observer } from "mobx-react";
import "jspdf-autotable";
import { handleGeneratePdf } from "../../../Utils/Utils";
import { ImFilePdf } from "react-icons/im";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";

const ChangeLog = observer((employeeCategory) => {
  const columns = [
    "Id",
    "Log Date Change",
    "Who Change",
    "What Change",
    "Case Notes",
  ];
  const columnStyles = {
    4: {
      cellWidth: 50,
    },
  };

  let id = 1;

  const rows = CareGiverStore.employeeChangeLog.map((item) => [
    id++,
    new Date(item.changeDate).toLocaleDateString(),
    item.whoChanged,
    item.whatChanged,
    item.caseNote,
  ]);

  const handlePdfGeneration = () => {
    handleGeneratePdf({
      contentElement: document.createElement("div"),
      filename: "changeLog.pdf",
      headerText: "Manual Change Log",
      headerAlign: "center",
      textAlign: "left",
      imageData: "Image Data",
      columns,
      rows,
      openInNewWindow: true,
      columnStyles,
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [handleSearchChange] = useSearchData();
  return (
    <div className="logMainContainer">
      <div className="searchButtonContainer">
        <div className="searchBox">
          <SearchBox
            handleChange={(e) => {
              handleSearchChange(e, employeeCategory.employeeCategory);
            }}
          />
        </div>
        <div className="changeLogBtnContainer">
          <div className="changeLogBtn underlineClick">
            {open && (
              <LogForm
                handleClose={handleClose}
                open={open}
                employeeCategory={employeeCategory}
              />
            )}
            <Icon iconName="circleAdd" />

            <span className="underlineClickTitle" onClick={handleOpen}>
              Add Change Log
            </span>
          </div>
          <div
            className="changeLogBtn underlineClick"
            onClick={() => {
              handlePdfGeneration(false);
            }}
          >
            <ImFilePdf />
            <spam className="underlineClickTitle"> Download</spam>
          </div>
        </div>
      </div>
      <ManualChangeLogTable
        employeeCategory={employeeCategory.employeeCategory}
      />
    </div>
  );
});

export default ChangeLog;
