import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ExpandLess, ExpandMore } from "@mui/icons-material"; // Arrow up icon
import "../../CSS/Input.css";

const SelectMenu = (props) => {
  const { title, ...otherProps } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track if the dropdown is open

  const selectConfig = {
    size: "small",
    ...otherProps,
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <FormControl fullWidth>
        <div className="title">{title}</div>
        <Select
          {...selectConfig}
          error={
            otherProps.touch && otherProps.errorname ? otherProps.error : false
          }
          className="select-options"
          IconComponent={() =>
            isDropdownOpen ? (
              <ExpandLess className="iconMenu" />
            ) : (
              <ExpandMore className="iconMenu" />
            )
          }
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
        >
          {otherProps.selectitem.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="error">
        {otherProps.touch && otherProps.errorname ? otherProps.errorname : ""}
      </div>
    </>
  );
};

export default SelectMenu;
