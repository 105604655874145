import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormikController from "../../../../components/FormikController/FormikController";
import { PaperComponent, getPosTypeId } from "../../../../Utils/Utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import { ClientURL } from "../../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../../Store/AgencyStore";
import ClientStore from "../../../../Store/ClientStore";
import { posType } from "../../../../Constants/Constants";
import { AiOutlineClose } from "react-icons/ai";

const PosUploadPopUp = ({ uniqueNameType, onConfirm, onCancel, clickHTML }) => {
  const [open, setOpen] = React.useState(false);
  const isSpecialType =
    uniqueNameType === "Service Notification Form" ||
    uniqueNameType === "Emergency Hour Isas Communication";
  const formik = useFormik({
    initialValues: {
      fileData: "",
      caseNote: "",
      receivedDate: "",
      posType: "",
      effectiveDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      fileData: YupValidation.file_type,
      caseNote: YupValidation.caseNote,
      ...(uniqueNameType === "POS" && {
        receivedDate: YupValidation.requiredOnly,
        posType: YupValidation.requiredOnly,
      }),

      ...(isSpecialType && {
        effectiveDate: YupValidation.requiredOnly,
        endDate: YupValidation.requiredOnly,
      }),
    }),
    onSubmit: (values) => {
      uploadPOSDoc(values);
      // if (GlobelStore.pageType === "client") {
      //   saveSingleFileClient(values);
      // } else {
      //   saveSingleFile(values);
      // }
    },
  });

  const uploadPOSDoc = (values) => {
    AgencyStore.setIsLoading(true);

    const clientProfileId = ClientStore.ClientAllData.client.id;

    const params = new FormData();
    params.append("clientProfileId", clientProfileId);
    if (uniqueNameType === "POS") {
      params.append("receivedDate", values.receivedDate);
      params.append("posType", values.posType);
    }
    if (isSpecialType) {
      params.append("effectiveDate", values.effectiveDate);
      params.append("endDate", values.endDate);
    }
    params.append("file", values.fileData);
    params.append("caseNote", values.caseNote);

    params.append("uniqueName", getPosTypeId(uniqueNameType));

    axios
      .post(ClientURL.UPLOAD_POS_DOC, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          formik.resetForm();
          ClientStore.getPosDoc(clientProfileId);
          handleClose();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Docter information");
      });
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Upload File
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content">
          <div className="title">{uniqueNameType}</div>
          <div className="fileUploadMainContainer">
            <div className="formInputContainer">
              <FormikController
                name="fileData"
                type="file"
                accept=".pdf"
                control="file"
                onChange={(event) => {
                  formik.setFieldValue(
                    "fileData",
                    event.currentTarget.files[0]
                  );
                }}
                touch={formik.touched.fileData}
                errorname={formik.errors.fileData}
              />
            </div>
            <div className="formInputContainer">
              <FormikController
                name="caseNote"
                error
                multiline
                label="Case Note"
                type="text"
                minRows={2}
                maxRows={4}
                control="textfield"
                {...formik.getFieldProps("caseNote")}
                touch={formik.touched.caseNote}
                errorname={formik.errors.caseNote}
                fullWidth="true"
              />
            </div>
            {uniqueNameType === "POS" && (
              <div className="formInputContainer">
                <FormikController
                  name="receivedDate"
                  error
                  label="Received Date"
                  type="date"
                  control="textfield"
                  {...formik.getFieldProps("receivedDate")}
                  touch={formik.touched.receivedDate}
                  errorname={formik.errors.receivedDate}
                />
              </div>
            )}

            {isSpecialType && (
              <>
                <div className="formInputContainer">
                  <FormikController
                    name="effectiveDate"
                    error
                    label="Effective Date"
                    type="date"
                    control="textfield"
                    {...formik.getFieldProps("effectiveDate")}
                    touch={formik.touched.effectiveDate}
                    errorname={formik.errors.effectiveDate}
                  />
                </div>
                <div className="formInputContainer">
                  <FormikController
                    name="endDate"
                    error
                    label="End Date"
                    type="date"
                    control="textfield"
                    {...formik.getFieldProps("endDate")}
                    touch={formik.touched.endDate}
                    errorname={formik.errors.endDate}
                  />
                </div>
              </>
            )}
            {uniqueNameType === "POS" && (
              <FormikController
                control="selects"
                title="POS Type"
                {...formik.getFieldProps("posType")}
                error
                touch={formik.touched.posType}
                errorname={formik.errors.posType}
                selectitem={posType}
              />
            )}
          </div>
        </div>
        <DialogActions className="DialogActionsContainer">
          <FormikController
            name="Upload"
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelectedGreen fixedWidth"
            fullWidth="false"
          />
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              handleClose();
            }}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(PosUploadPopUp);
