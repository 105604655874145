import { observer } from "mobx-react";
import React, { useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { useFormik } from "formik";
import CareGiverStore from "../../../Store/CareGiverStore";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import axios from "axios";
import { CareGiverURL } from "../../../Url/Url";
import AgencyStore from "../../../Store/AgencyStore";
import { YupValidation } from "../../../Constants/ValidationConstants";
import useHandleState from "../../../components/useHandleState";

const AdminEmployeeGovernment = observer(() => {
  const { state, handleTrue } = useHandleState(false);
  const [isGreenCardCheck, setIsGreenCardCheck] = useState(false);
  const formik = useFormik({
    initialValues: {
      idCard: "",
      idExpiryDate: "",
      employmentAuthCard: "",
      employmentAuthCardExpiryDate: "",
      greenCard: "",
      greenCardExpiryDate: "",
      socialSecurity: "",
      i9: "",
      w4: "",
      privacyActStatement: "",
      nonCriminalJustice: "",
      greenCardChecked: false,
      backgrounCheck: "",
    },
    validationSchema: Yup.object({
      idCard: YupValidation.file_type,
      idExpiryDate: YupValidation.expiryDate,
      employmentAuthCard: YupValidation.file_type,
      employmentAuthCardExpiryDate: YupValidation.expiryDate,
      greenCard: isGreenCardCheck
        ? YupValidation.file_type
        : YupValidation.file_type_without_require,
      greenCardExpiryDate: isGreenCardCheck
        ? YupValidation.expiryDate
        : YupValidation.expiryDate_without_require,
      socialSecurity: YupValidation.file_type,
      i9: YupValidation.file_type,
      w4: YupValidation.file_type,
      privacyActStatement: YupValidation.file_type,
      backgrounCheck: YupValidation.file_type,
      nonCriminalJustice: YupValidation.file_type,
    }),
    onSubmit: (values) => {
      saveGovernmentDoc(values);
    },
  });

  const saveGovernmentDoc = (values) => {
    AgencyStore.setIsLoading(true);
    const employeeProfileID = CareGiverStore.careGiverDbId;
    const params = new FormData();
    if (employeeProfileID) {
      params.append("employeeProfileId", employeeProfileID);
      params.append("idCardFile", values.idCard);
      params.append("idCardExpiryDate", values.idExpiryDate);
      params.append("employmentAuthCardFile", values.employmentAuthCard);
      params.append(
        "employmentAuthCardExpiryDate",
        values.employmentAuthCardExpiryDate
      );

      params.append("socialSecurityFile", values.socialSecurity);
      params.append("i9File", values.i9);
      params.append("w4File", values.w4);
      params.append("privacyActFile", values.privacyActStatement);
      params.append("nonCriminalFile", values.nonCriminalJustice);
      params.append("backgroundCheckFile", values.backgrounCheck);
      if (isGreenCardCheck) {
        params.append("greenCardAvailable", true);
        params.append("greenCardFile", values.greenCard);
        params.append("greenCardExpiryDate", values.greenCardExpiryDate);
      } else {
        params.append("greenCardAvailable", false);
      }
    }
    axios
      .post(CareGiverURL.SAVE_GOVERNMENTDOC, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          handleTrue();
          AgencyStore.showAlert("success", "Government document Saved");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Fail to save Government document!!");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Fail to save Government document!!");
      });
  };

  const handleCheckboxChange = (formik) => (event) => {
    const { checked } = event.target;
    setIsGreenCardCheck(checked);
  };

  const resetForm = () => {
    formik.resetForm();
  };
  return (
    <div>
      <div className="confidentailMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="idCard"
              label="ID"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue("idCard", event.currentTarget.files[0]);
              }}
              touch={formik.touched.idCard}
              errorname={formik.errors.idCard}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="idExpiryDate"
              error
              label="ID Expiry Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("idExpiryDate")}
              touch={formik.touched.idExpiryDate}
              errorname={formik.errors.idExpiryDate}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange(formik)} />}
                label="Green Card"
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <div className="formInputContainer"></div>
        </div>

        <div>
          <div className="formInputContainer">
            <FormikController
              name="greenCard"
              label="Greencard (If not citizen)"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue("greenCard", event.currentTarget.files[0]);
              }}
              touch={formik.touched.greenCard}
              errorname={formik.errors.greenCard}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="greenCardExpiryDate"
              error
              label="Green Card Expiry Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("greenCardExpiryDate")}
              touch={formik.touched.greenCardExpiryDate}
              errorname={formik.errors.greenCardExpiryDate}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="employmentAuthCard"
              label="Employment Authorization Card"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "employmentAuthCard",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.employmentAuthCard}
              errorname={formik.errors.employmentAuthCard}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="employmentAuthCardExpiryDate"
              error
              label="Employment Authorization Card Expiry Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("employmentAuthCardExpiryDate")}
              touch={formik.touched.employmentAuthCardExpiryDate}
              errorname={formik.errors.employmentAuthCardExpiryDate}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="socialSecurity"
              label="Social Security"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "socialSecurity",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.socialSecurity}
              errorname={formik.errors.socialSecurity}
              disabled={state ? true : false}
            />
          </div>
        </div>

        <div>
          <div className="formInputContainer">
            <FormikController
              name="i9"
              label="I9"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue("i9", event.currentTarget.files[0]);
              }}
              touch={formik.touched.i9}
              errorname={formik.errors.i9}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="w4"
              label="W4"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue("w4", event.currentTarget.files[0]);
              }}
              touch={formik.touched.w4}
              errorname={formik.errors.w4}
              disabled={state ? true : false}
            />
          </div>
        </div>

        <div>
          <div className="formInputContainer">
            <FormikController
              name="privacyActStatement"
              label="Privacy Act Statement"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "privacyActStatement",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.privacyActStatement}
              errorname={formik.errors.privacyActStatement}
              disabled={state ? true : false}
            />
          </div>
        </div>
        <div className="formInputContainer">
          <FormikController
            name="backgrounCheck"
            label="Background Check"
            type="file"
            accept=".pdf"
            control="file"
            onChange={(event) => {
              formik.setFieldValue(
                "backgrounCheck",
                event.currentTarget.files[0]
              );
            }}
            touch={formik.touched.backgrounCheck}
            errorname={formik.errors.backgrounCheck}
            disabled={state ? true : false}
          />
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="nonCriminalJustice"
              label="Noncriminal justice applicants’ privacy rights"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "nonCriminalJustice",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.nonCriminalJustice}
              errorname={formik.errors.nonCriminalJustice}
              disabled={state ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        {state ? (
          ""
        ) : (
          <>
            <FormikController
              name="Save Changes"
              control="buttons"
              onClick={formik.handleSubmit}
              className="buttonSelected fixedWidth"
              fullWidth="false"
            />
            <FormikController
              name="Reset"
              control="buttons"
              onClick={() => {
                resetForm();
              }}
              className="buttonUnselected fixedWidth"
              fullWidth="false"
            />
          </>
        )}
      </div>
    </div>
  );
});

export default AdminEmployeeGovernment;
