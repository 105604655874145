import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai";
import { observer } from "mobx-react";
import { useFormik } from "formik";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import * as Yup from "yup";
import FormikController from "../../../../components/FormikController/FormikController";
import { DialogActions, TextField } from "@mui/material";

import { CareGiverURL } from "../../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../../Store/AgencyStore";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DownloadClockInOutPopUp = ({
  onConfirm,
  clickHTML,
  type,
  selectedIds,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const formik = useFormik({
    initialValues: {
      fromDate: "",
    },

    validationSchema: Yup.object({
      fromDate: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      downloadWorkHrsDetails(values);
    },
  });

  const downloadExcel = () => {
    axios({
      url: "http://localhost:5000/download-excel", // Backend endpoint
      method: "GET",
      responseType: "blob", // Important for handling binary data
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "example.xlsx"); // or any other file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading the file", error);
      });
  };

  const downloadWorkHrsDetails = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      fromDate: formatDate(values.fromDate),
    };

    await axios
      .post(CareGiverURL.DOWNLOAD_WORKHRS_DTL, params, {
        responseType: "blob",

        headers: {
          Authorization: localStorage.getItem("loginToken"),
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          const fileName = res.headers["fileName"];
          // const fileName = res.headers["Content-Disposition"];

          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); // or any other file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          formik.resetForm();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "success",
            "Successfully Download Clock In/Out DetailsdownloadWorkHrsDetails"
          );
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to Download Clock In/Out Details"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to Download Clock In/Out Details"
        );
      });
  };

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        // maxWidth={"md"}
      >
        <DialogTitle id="draggable-dialog-title">
          <div className="dialog-title-container">
            Download Clock In/Out
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content ">
          {/* borderBottom */}
          <div className="marginTop clientDoctorCaseNoteInputContainer">
            <div className="formInputContainer">
              <FormikController
                name="date"
                error
                label="Date"
                type="date"
                control="textfield"
                values={formik.values.fromDate}
                {...formik.getFieldProps("fromDate")}
                touch={formik.touched.fromDate}
                errorname={formik.errors.fromDate}
              />
            </div>
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            <FormikController
              name="Download"
              control="buttons"
              onClick={() => {
                formik.handleSubmit();
              }}
              className="buttonSelectedGreen menuItemButton "
              fullWidth={false}
            />
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(DownloadClockInOutPopUp);
