import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import {
  createColumnDef,
  dateCellRenderer,
  fullNameCellRenderer,
  useGridReady,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import { ClientURL } from "../../../Url/Url";
import { useFormik } from "formik";
import AgencyStore from "../../../Store/AgencyStore";
import { YupValidation } from "../../../Constants/ValidationConstants";
import axios from "axios";
import * as Yup from "yup";
import ClientStore from "../../../Store/ClientStore";
import { useParams } from "react-router-dom";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import UpdateCaseNotePopUp from "./UpdateCaseNotePopUp";
import CareGiverStore from "../../../Store/CareGiverStore";
import Icon from "../../../components/Icons/Icon";

const ClientDoctorCaseNote = observer(() => {
  const { clientId } = useParams();
  // const [onGridReady] = useGridReady();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    ClientStore,
    "clientAgGrid"
  );

  const formik = useFormik({
    initialValues: {
      caseNote: "",
    },

    validationSchema: Yup.object({
      caseNote: YupValidation.caseNote,
    }),
    onSubmit: (values) => {
      saveDoctorCaseNote(values);
      // console.log("asdad");
      // if (saveUpadateState == "save") {
      //   saveDoctorInfo(values);
      // } else if (saveUpadateState == "update") {
      //
      //   updateDoctorInfo(values);
      // }
    },
  });

  const handleSaveButtonClick = () => {
    ClientStore.setIndividualDocterCaseNoteData({});
  };

  const saveDoctorCaseNote = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      doctorId: ClientStore.docterInfoAllData.doctorInfo.id,
      caseNote: values.caseNote,
    };
    axios
      .post(ClientURL.SAVE_DOCTOR_CASENOTE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getDoctorCaseNotes(clientId);
          formik.resetForm();
          CareGiverStore.setTableHeight();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Docter Case note");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Docter information");
      });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        width: 100,
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: (params) => params.node.rowIndex + 1,
      },
      {
        field: "doctorName",
        headerName: "Doctor",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "caseNote",
        headerName: "Case Note",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "insertDate",
        headerName: "Created Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },

      {
        field: "lastUpdatedDate",
        headerName: "Last Updated Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },
      {
        field: "update",
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            ClientStore.setIndividualDocterCaseNoteData(params.data);
          };
          return (
            <UpdateCaseNotePopUp
              type="docterCaseNote"
              clickHTML={
                <div
                  className="manageClick"
                  onClick={() => {
                    ClientStore.setCaseNotestatus("update");
                    handleButtonClick();
                  }}
                >
                  Update
                </div>
              }
            />
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      {/* <div className="marginTop clientDoctorCaseNoteInputContainer">
        <div className="formInputContainer">
          <FormikController
            name="caseNote"
            error
            multiline
            label="Case Note"
            type="text"
            rows={2}
            control="textfield"
            {...formik.getFieldProps("caseNote")}
            touch={formik.touched.caseNote}
            errorname={formik.errors.caseNote}
          />
        </div>
        <div className="caseNoteButtonContainer">
          <FormikController
            name="Save Case Note"
            control="buttons"
            onClick={() => {
              formik.handleSubmit();
            }}
            className="buttonSelectedGreen menuItemButton "
            fullWidth={false}
          />
        </div>
      </div> */}
      <div className="CDCNunderlineClickBtnContainer">
        <UpdateCaseNotePopUp
          type="docterCaseNote"
          clickHTML={
            <div
              className="underlineClick"
              onClick={() => {
                ClientStore.setCaseNotestatus("save");
                handleSaveButtonClick();
              }}
            >
              <Icon iconName="circleAdd" />
              Add Case Note
            </div>
          }
        />
      </div>
      <FormikController
        rowData={ClientStore?.docterCaseNoteAllData?.resultSet}
        control="agGrid"
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default ClientDoctorCaseNote;
