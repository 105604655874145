import { observer } from "mobx-react";
import React, { useEffect } from "react";
import NurseAndCareGiverEmployeeStore from "../../Store/NurseAndCareGiverEmployeeStore";
import ClientStore from "../../Store/ClientStore";
import { Link } from "react-router-dom";
import { stringAvatar } from "../../Utils/Utils";
import { Avatar } from "@mui/material";

const ClientList = ({ employeeCategory }) => {
  useEffect(() => {
    NurseAndCareGiverEmployeeStore.getEmployeeClientRelation();
  }, []);

  return (
    <div>
      <div className="ClientListMainContainer">
        <div className="headerContainer">
          <div className="headerItem">
            Id
            {/* {employeeCategory === "Client" ? "#MRA" : "Id"} */}
          </div>
          <div className="headerItem">Name</div>
          <div className="headerItem">Phone</div>
          <div className="headerItem flexLeft">Action</div>
        </div>
        {ClientStore.employeeClientRelationAllData.length === 0 ? (
          <div className="noRecordsMessage">No records available</div>
        ) : (
          ClientStore.employeeClientRelationAllData.map((item, index) => (
            <div className="gridContainer" key={index}>
              <div className="gridItem">
                <Link
                  to={`/agency/Client_profile/${item.id}`}
                  className="gridItemName"
                >
                  {item.mra}
                </Link>
                {/* {employeeCategory === "CareGiver" ? (
                  <Link
                    to={`/agency/careGiverProfile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.employeeId}
                  </Link>
                ) : employeeCategory === "Nurse" ? (
                  <Link
                    to={`/agency/Nurse_Profile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.employeeId}
                  </Link>
                ) : (
                  <Link
                    to={`/agency/Client_profile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.mra}
                  </Link>
                )} */}
              </div>

              <div className="gridItem">
                <div className="avatarNameContainer">
                  <Avatar
                    {...stringAvatar(`${item.firstName} ${item.lastName}`)}
                  />
                  <div className="gridItemNameEmail">
                    <Link
                      to={`/agency/Client_profile/${item.id}`}
                      className="gridItemName"
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </Link>
                    {/* {employeeCategory === "CareGiver" ? (
                      <Link
                        to={`/agency/careGiverProfile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.firstName} ${item.lastName}`}
                      </Link>
                    ) : employeeCategory === "Nurse" ? (
                      <Link
                        to={`/agency/Nurse_Profile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.firstName} ${item.lastName}`}
                      </Link>
                    ) : (
                      <Link
                        to={`/agency/Client_profile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.firstName} ${item.lastName}`}
                      </Link>
                    )} */}

                    <div className="gridItemEmail">{item.email}</div>
                  </div>
                </div>
              </div>
              <div className="gridItem">{item.phoneNo}</div>

              <div className="gridItem flexLeft">
                {/* <ConfirmPopUp
                  buttonLabel="Delete"
                  title="Delete"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    deleteEmployeeClientRelation(item.id);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <Tooltip title="Delete">
                      <div className="iconBox actionMarginLeft deleteIcon">
                        <RiDeleteBin6Line />
                      </div>
                    </Tooltip>
                  }
                /> */}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default observer(ClientList);
