import React from "react";
import "../../CSS/Loading.css";
import Dialog from "@mui/material/Dialog";
import { observer } from "mobx-react";
import AgencyStore from "../../Store/AgencyStore";
import { grid } from "ldrs";

grid.register();
const Loading = observer(() => {
  return (
    <div>
      <Dialog
        open={AgencyStore.isLoading}
        className="loadingDilogeMainConatiner"
      >
        <l-grid size="80" speed="1.5" color="white"></l-grid>
      </Dialog>
    </div>
  );
});

export default Loading;
